/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Form, Input, Button, Select, Spin } from "antd";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { UserInterface } from "../intefaces/UserInterface";
import { LocalizationInterface } from "../intefaces/LocalizationInterface";
import { useHookFormLocalizatiion } from "../hooksForm/useHookFormLocalizatiion";
import { useHookCities } from "../hooks/useHookCities";

const { Option } = Select;

interface FormLocalizationProps {
  updateTable: () => void;
  editMode: boolean;
  initialValues?: LocalizationInterface;
  modalVisible: boolean;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  setEditMode: (visible: boolean) => void;
  companies?: CompanyInterface[];
  user?: UserInterface;
  orientation?: string;
}

const FormLocalization: React.FC<FormLocalizationProps> = ({
  updateTable,
  editMode,
  initialValues,
  shouldResetForm,
  setShouldResetForm,
  companies,
  user,
  orientation,
}) => {
  const { cities } = useHookCities();
  const { form, onFinish, handleSearchPostalCode, spinning, spinningText } =
    useHookFormLocalizatiion(
      updateTable,
      editMode,
      shouldResetForm,
      setShouldResetForm,
      companies,
      initialValues,
      user,
      cities
    );

  return (
    <div
      className={
        orientation === "vertical"
          ? "flex flex-col justify-between w-full h-full mb-5"
          : "flex flex-row justify-between w-full h-1/2 mb-5"
      }
    >
      <Spin spinning={spinning} tip={spinningText} fullscreen />
      <div
        className={
          orientation === "vertical"
            ? "justify-start w-80 mb-10"
            : "justify-start w-80"
        }
      >
        <h1 className="text-2xl flex flex-col">
          Detalhes da Localização
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="code"
            label="ID"
            className={orientation === "vertical" ? "w-40 mr-3" : "w-1/1 mr-3"}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="name"
            label="Descrição"
            className={orientation === "vertical" ? "w-full" : "w-1/2 mr-3"}
            rules={[{ required: true, message: "Insira uma descrição!" }]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="company_id"
            label="Empresa"
            className={orientation === "vertical" ? "w-full" : "w-1/4"}
            rules={[{ required: true, message: "Selecione uma empresa!" }]}
          >
            <Select style={{ textTransform: "uppercase" }}>
              {companies &&
                companies.map((company) => (
                  <Option
                    key={company.company_id}
                    value={company.company_id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {company.company_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="cep"
            label="CEP"
            className={orientation === "vertical" ? "w-48" : "w-1/6 mr-3"}
          >
            <Input onBlur={(e) => handleSearchPostalCode(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="address_number"
            label="Nº Endereço"
            className={orientation === "vertical" ? "w-48" : "w-28 mr-3"}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="city"
            label="Cidade"
            className={orientation === "vertical" ? "w-full" : "w-1/3 mr-3"}
            rules={[{ required: true, message: "Selecione uma cidade!" }]}
          >
            <Select style={{ textTransform: "uppercase" }}>
              {cities &&
                cities.map((city) => (
                  <Option
                    value={city.city_id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {city.city_description}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="neighborhood"
            label="Bairro"
            className={orientation === "vertical" ? "w-full" : "w-2/5 mr-3"}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input />
          </Form.Item>
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="address"
            label="Endereço"
            className={orientation === "vertical" ? "w-full" : "w-3/4 mr-3"}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item name="description" label="Observações" className="w-full">
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="latitude"
            label="Latitude"
            className={orientation === "vertical" ? "w-full" : "w-1/3 mr-3"}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="longitude"
            label="Longitude"
            className={orientation === "vertical" ? "w-full" : "w-1/3 mr-3"}
          >
            <Input />
          </Form.Item>
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-row justify-start"
              : "flex flex-row justify-end"
          }
        >
          <Button
            type="default"
            className="w-1/5"
            htmlType="submit"
            style={{ backgroundColor: "green", color: "white" }}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormLocalization;
