import axios, { AxiosError } from 'axios';
import { api } from '../api/api';
import { ResponsibleInterface } from '../intefaces/ResponsibleInterface';

export const createResponsible = async (responsible: ResponsibleInterface, tId?: number): Promise<ResponsibleInterface> => {
    try {
        const response = await api.post(`/new/responsible/${tId}`, responsible);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getResponsible = async (param: string, value: string, tId?: number): Promise<ResponsibleInterface[]> => {
    try {
        const response = await api.get(`/find/one/responsible/${param}/${value}/${tId}`);
        if(response.status === 200) {
            return response.data;
          } else {
            throw new Error('Erro ao buscar o responsavél');
          }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar o responsavél '+ (error as AxiosError).message);
          } else {
            throw error;
          }
    }
};

export const getAllResponsibles = async (tId?: number, companyId?: number[], role?: string) => {
    if(tId === undefined) return null; 
    try {
        const response = await api.get(`/find/all/responsibles/${tId}/${companyId!.length > 0 ? companyId : [0]}/${role}`);
        if(response.status === 200) {
            return response.data;
          } else {
            throw new Error('Erro ao buscar os responsáveis');
          }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar os responsáveis '+ (error as AxiosError).message);
          } else {
            throw error;
          }
    }
};

export const updateResponsible = async (responsible_id: number, responsible: ResponsibleInterface, tId?: number,) => {
    try {
        const response = await api.put(`/update/responsible/${tId}/${responsible_id}`, responsible);
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteResponsible = async (responsible_id: number, tId?: number) => {
    try {
        const response = await api.delete(`/delete/responsible/${tId}/${responsible_id}`);
        if(response.status === 200) {
            return response.data;
          } else {
            throw new Error('Erro ao deletar o responsável');
          }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao deletar o responsável '+ (error as AxiosError).message);
          } else {
            throw error;
          }
    }
};