import axios, { AxiosError } from 'axios';
import { api } from '../api/api';
import { PersonInterface } from '../intefaces/PersonInterface';

export const createPerson = async(person: PersonInterface, tId?: number): Promise<PersonInterface> =>{
    try {
      const response = await api.post(`/new/person/${tId}`, person);
      return response.data;
    } catch (error) {
      throw error;
    }
};
  
export const getPerson = async(param: string, value: string, tId?: number): Promise<PersonInterface[]> => {
    try {
      const response = await api.get(`/find/one/person/${param}/${value}/${tId}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao buscar a pessoa');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao buscar a pessoa'+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};
  
export const getAllPersons = async(tId?: number, companyId?: number[], role?: string) => {
    if(tId === undefined) return null;
    try {
      const response = await api.get(`/find/all/person/${tId}/${companyId!.length > 0 ? companyId : [0]}/${role}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao buscar clientes!');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao buscar clientes '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};
  
export const updatePerson = async(person_id: number, person: PersonInterface, tId?: number): Promise<PersonInterface> => {
    try {
      const response = await api.put(`/update/person/${tId}/${person_id}`, person);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao atualizar a pessoa');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao atualizar a pessoa'+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};
  
export const deletePerson = async(person_id: number, tId?: number) => {
    try {
      const response = await api.delete(`/delete/person/${tId}/${person_id}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao deletar a pessoa');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao deletar a pessoa'+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};