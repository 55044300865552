/* eslint-disable @typescript-eslint/no-unused-vars */
import { Menu } from "antd";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { useHookGeneratePDF } from "../hooks/useHookGeneratePDF";
import { Asset } from "../intefaces/Asset";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { TypeInterface } from "../intefaces/TypeInterface";

export interface MenuReportButtonProps {
  username?: string;
  assets: Asset[];
}

export const MenuReport = (Props: MenuReportButtonProps) => {
  const { AssetReport, AssetDepreciationReport, generatePDF, AssetAcumulateDepreciationReport } =
    useHookGeneratePDF();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<CompanyInterface | undefined>(undefined);
  const [selectedType, setSelectedType] = useState<TypeInterface | undefined>(undefined);
  const [open, setOpen] = useState(0);

  const handleDepreciation = (index: number) => {
    setOpen(index);
  };

  const handlePrintDepreciation = (asset: Asset) => {
    AssetDepreciationReport(asset, Props.username);
  };

  const handleDateChange = (
    dates: [Dayjs | null, Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handlePrintAcumulateDepreciation = (
    assets: Asset[],
    startDate: Dayjs | null,
    endDate: Dayjs | null
  ) => {
    if (!startDate || !endDate) {
      console.log("Por favor, informe um período válido.");
      return;
    }
    
    AssetAcumulateDepreciationReport(assets, startDate, endDate, Props.username)
    
  };

  const handlePrintAssetReport = (company?: CompanyInterface, group?: TypeInterface) => {
    AssetReport(Props.assets, Props.username, company, group);
  }

  const menuAsset = () => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => handleDepreciation(1)}
      >
        Listagem de Controle
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleDepreciation(2)}>
        Depreciação
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleDepreciation(3)}>
        Depre.: Acumulada
      </Menu.Item>
    </Menu>
  );

  return {
    menuAsset,
    open,
    setOpen,
    handlePrintDepreciation,
    generatePDF,
    handleDateChange,
    startDate,
    endDate,
    handlePrintAcumulateDepreciation,
    handlePrintAssetReport,
    setSelectedCompany,
    setSelectedType,
    selectedCompany,
    selectedType
  };
};
