/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message } from "antd";
import { useEffect, useState } from "react";
import { UserInterface } from "../intefaces/UserInterface";
import { createUser, updateUser } from "../service/userService";

export const useHookFormUser = (
    updateTable: () => void,
    editMode: boolean,
    shouldResetForm: boolean,
    setShouldResetForm: (visible: boolean) => void,
    initialValues?: UserInterface,
    user?: UserInterface
) => {
    const [form] = Form.useForm<UserInterface>();
    const [searchValue, setSearchValue] = useState('');
    const [spinning, setSpinning] = useState(false);
    const [spinningText, setSpinningText] = useState("");

    const onFinish = async (values: UserInterface) => {
        try {
            if (editMode && initialValues) {
                setSpinning(true);
                setSpinningText("Atualizando...");
                values.role = initialValues.role;
                await updateUser(values, initialValues.id, user?.tenant.id);
                message.success('Usuário atualizado com sucesso!');
                form.resetFields();
                updateTable();
                setSpinning(false);
                setSpinningText("");
            } else {        
                setSpinning(true);    
                setSpinningText("Criando...");    
                await createUser(values, user?.tenant.id);
                message.success('Usuário criado com sucesso!');
                form.resetFields();
                updateTable();
                setSpinning(false);
                setSpinningText("");
            }
        } catch (error) {
            console.error('Erro ao criar usuário:', error);
            message.error('Erro ao criar usuário');
            setSpinningText("");
        }
    };    

    useEffect(() => {
        if(editMode && initialValues) {
            form.setFieldsValue(initialValues)
        } else {
            form.resetFields();
        }

    }, [editMode, initialValues]);

    useEffect(() => {
        if(shouldResetForm) {
            form.resetFields();
            setShouldResetForm(false);
            setSearchValue('');
        }
    }, [shouldResetForm]);

    return {
        form,
        onFinish,
        spinning,
        spinningText
    }
}
