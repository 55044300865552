/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { CityInterface } from "../intefaces/CityInterface";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { LocalizationInterface } from "../intefaces/LocalizationInterface";
import { UserInterface } from "../intefaces/UserInterface";
import {
  createLocalization,
  updateLocalization,
} from "../service/localizationService";
import { searchPostalCode } from "../utils/UseCases";

export const useHookFormLocalizatiion = (
  updateTable: () => void,
  editMode: boolean,
  shouldResetForm: boolean,
  setShouldResetForm: (visible: boolean) => void,
  companies?: CompanyInterface[],
  initialValues?: LocalizationInterface,
  user?: UserInterface,
  cities?: CityInterface[] | null
) => {
  const [form] = Form.useForm<LocalizationInterface>();
  const [searchValue, setSearchValue] = useState("");
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [spinningText, setSpinningText] = React.useState<string>("");

  const onFinish = async (values: LocalizationInterface) => {
    try {
      setSpinning(true);
      setSpinningText("Atualizando...");
      if (editMode && initialValues) {
        if (typeof values.company_id === "string" && companies) {
          let company = companies.filter(
            (company) =>
              company.company_name.toUpperCase() ===
              form.getFieldValue("company_id").toUpperCase()
          );
          if (company && company.length > 0) {
            values.company_id = company[0];
          }
        } else if (typeof values.company_id === "number" && companies) {
          let company = companies.filter(
            (company) => company.company_id === form.getFieldValue("company_id")
          );
          if (company && company.length > 0) {
            values.company_id = company[0];
          }
        }

        if (values.city && cities) {
          if (typeof values.city === "string" && cities) {
            let city = cities.filter(
              (city) =>
                city.city_description.toUpperCase() ===
                values.city.toString().toUpperCase()
            );
            if (city && city.length > 0) {
              values.city = city[0];
            }
          } else {
            let city = cities.filter(
              (city) => city.city_id === form.getFieldValue("city_id")
            );
            if (city && city.length > 0) {
              values.city = city[0];
            }
          }
        }

        await updateLocalization(initialValues.id, values, user?.tenant.id);
        message.success("Localização atualizada com sucesso!");
        form.resetFields();
        updateTable();
        setSpinning(false);
        setSpinningText("");
      } else {
        setSpinning(true);
        setSpinningText("Criando...");
        if (values.company_id && companies) {
          let company = companies.filter(
            (company) => company.company_id === form.getFieldValue("company_id")
          );
          if (company && company.length > 0) {
            values.company_id = company[0];
          }
        }

        if (values.city && cities) {
          if (typeof values.city === "string" && cities) {
            let city = cities.filter(
              (city) =>
                city.city_description.toLowerCase() ===
                values.city.toString().toLowerCase()
            );
            if (city && city.length > 0) {
              values.city = city[0];
            }
          }
        }

        values.code = 0;

        await createLocalization(values, user?.tenant.id);
        message.success("Localização criada com sucesso!");
        form.resetFields();
        updateTable();
        setSpinning(false);
        setSpinningText("");
      }
    } catch (error) {
      console.error("Erro ao criar localização:", error);
      message.error("Erro ao criar localização");
      setSpinning(false);
      setSpinningText("");
    }
  };

  useEffect(() => {
    if (editMode && initialValues) {
      form.setFieldsValue(initialValues);
      form.setFieldValue("company_id", initialValues.company_id.company_name);
      form.setFieldValue("city", initialValues.city.city_description);
    } else {
      form.resetFields();
    }
  }, [editMode, initialValues]);

  useEffect(() => {
    if (shouldResetForm) {
      form.resetFields();
      setShouldResetForm(false);
      setSearchValue("");
    }
  }, [shouldResetForm]);

  const handleSearchPostalCode = async (postalCode: string) => {
    try {
      if (postalCode.trim() === "") {
        form.setFieldValue("cep", "");
        form.setFieldValue("city", "");
        form.setFieldValue("neighborhood", "");
        form.setFieldValue("address", "");
        return;
      }

      const addressData = await searchPostalCode(postalCode);

      form.setFieldValue("cep", addressData.cep);
      form.setFieldValue("city", addressData.localidade.toUpperCase());
      form.setFieldValue("neighborhood", addressData.bairro.toUpperCase());
      form.setFieldValue("address", addressData.logradouro.toUpperCase());
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    onFinish,
    handleSearchPostalCode,
    spinning,
    spinningText,
  };
};
