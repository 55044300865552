/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError }  from 'axios';
import { api } from '../api/api';
import { TenantInterface } from '../intefaces/TenantInterface';

export const createTenant = async (tenant: TenantInterface): Promise<number> => {
    try {
        const response = await api.post(`/new/tenant`, tenant);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const getTenant = async (uid?: number): Promise<TenantInterface> => {
  try {
      const response = await api.get(`/find/one/tenant/${uid}`);
      if (response.status === 200) {
          return response.data;
      } else {
          throw new Error('Tenant não encontrado');
      }
  } catch (error) {
      if (axios.isAxiosError(error)) {
          throw new Error('Erro ao buscar tenant: ' + (error as AxiosError).message);
      } else {
          throw error;
      }
  }
};

export const getAllTenants = async () => {
  try {
      const response = await api.get(`/find/all/tenants`);
      if (response.status === 200) {
          return response.data;
      } else {
          throw new Error('Tenants não encontrados');
      }
  } catch (error) {
      if (axios.isAxiosError(error)) {
          throw new Error('Erro ao buscar tenants: ' + (error as AxiosError).message);
      } else {
          throw error;
      }
  }
};

export const updateTenant = async (tenant: TenantInterface, id: number) => {
    try {
        const response = await api.put(`/update/tenant/${id}`, tenant);
        if(response.status === 200) {
            return response.data;
        } else throw new Error('Erro ao atualizar tenant');
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao atualizar tenant: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
}

export const deleteTenant = async (uid?: number) => {
  try {
      const response = await api.delete(`/delete/tenant/${uid}`);
      if (response.status === 200) {
          return response.data;
      } else {
          throw new Error('Tenant não encontrado');
      }
  } catch (error) {
      if (axios.isAxiosError(error)) {
          throw new Error('Erro ao deletar tenant: ' + (error as AxiosError).message);
      } else {
          throw error;
      }
  }
};