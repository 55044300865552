/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { PersonInterface } from "../intefaces/PersonInterface";
import { deletePerson, getAllPersons, getPerson } from "../service/personService";

export const useHookPersons = (uid?: number, companyId?: number[], role?: string) => {
    const [persons, setPersons] = useState<PersonInterface[]>([]);
    const [selectedPerson, setSelectedPerson] = useState<PersonInterface>();
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchParam, setSearchParam] = useState<string>('person_code');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);
    
    useEffect(() => {
        const fetchPersons = async () => {
            try {
                const personsData = await getAllPersons(uid, companyId, role);
                setPersons(personsData);
            } catch (error) {
                console.error('Erro ao buscar clientes:', error);
            }
        };

        fetchPersons();
    }, []);


    const updatePersons = async () => {
        try {
            const personsData = await getAllPersons(uid, companyId, role);
            setPersons(personsData);
        } catch (error) {
            console.error('Erro ao buscar clientes:', error);
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, param: string, value: string) => {
        if (event.key === 'Enter') {
            updateDataTable(param, value);
        } else if (event.key === 'Backspace') {
            handleClearSearch();
        }
    }

    const updateDataTable = async (param: string, value: string) => {
        try {
            if (value === "") {
                message.error("Campo de busca não pode ser vázio!")
                return;
            }

            let findPerson: React.SetStateAction<PersonInterface[]>;

            if(param === 'person_code') {
                findPerson = await getPerson(param, value, uid);
            } else if (param === 'person_cpfOrCnpj') {
                value = value.replace("/", "");
                findPerson = await getPerson(param, value, uid);
            } else {
                value = '*' + value;
                findPerson = await getPerson(param, value, uid);
            }

            setSpinning(true);

            setTimeout(() => {
                if (findPerson) {
                    setPersons(findPerson);
                    //setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                } 

                message.error('Nenhum cliente encontrado com esse ID!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar cliente:', error);
            message.error('Erro ao buscar cliente');
        }
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleEdit = (record: PersonInterface) => {
        setSelectedPerson(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
    };

    const handleDelete = async (record: number) => {
        try {
            await deletePerson(record, uid);
            updatePersons();
            message.success('Cliente deletado com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar cliente:', error);
            message.error('Erro ao deletar cliente!');
        }
    };

    const handleClearSearch = () => {
        updatePersons();
        setSearchValue('');
        setIsSearching(false);
    };

    return {
        persons,
        updatePersons,
        selectedPerson,
        setSelectedPerson,
        showModal,
        modalVisible,
        setModalVisible,
        handleEdit,
        editMode,
        handleCancel,
        handleDelete,
        shouldResetForm,
        setShouldResetForm,
        setEditMode,
        searchValue,
        setSearchValue,
        isSearching, 
        setIsSearching,
        handleClearSearch,
        updateDataTable,
        spinning,
        searchParam,
        setSearchParam,
        handleKeyPress
    };
}