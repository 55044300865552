/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { TenantInterface } from "../intefaces/TenantInterface";
import { deleteTenant, getAllTenants, getTenant } from "../service/tenantService";

/* eslint-disable @typescript-eslint/no-unused-vars */
export const useHookTenant = () => {
    const [tenants, setTenants] = useState<TenantInterface[]>([]);
    const [selectedTenant, setSelectedTenant] = useState<TenantInterface>();
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);
    
    useEffect(() => {
        const fetchTenant = async () => {
            try {
                const tenantData = await getAllTenants();
                setTenants(tenantData);
            } catch (error) {
                console.error('Erro ao buscar tenants:', error);
            }
        };

        fetchTenant();
    }, []);

    const updateTenants = async () => {
        try {
            const tenantData = await getAllTenants();
            setTenants(tenantData);
        } catch (error) {
            console.error('Erro ao buscar tenants:', error);
        }
    };   

    const handleDelete = async (record: number) => {
        try {
            await deleteTenant(record);
            updateTenants();
            message.success('Tenant excluido com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar tenant:', error);
        }
    };

    const updateDataTable = async (record: number) => {
        try {
            if (!record) {
                return message.error('Digite um ID para buscar!');
            }

            const findTenant = await getTenant(record);
            setSpinning(true);

            setTimeout(() => {
                if (findTenant) {
                    setTenants([findTenant as TenantInterface]);
                    setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                } 

                message.error('Nenhum tenant encontrado com esse ID!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar tenant:', error);
            message.error('Erro ao buscar tenant');
        }
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleEdit = (record: TenantInterface) => {
        setSelectedTenant(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
    };

    const handleClearSearch = () => {
        updateTenants();
        setIsSearching(false);
    };

    return {
        tenants, 
        updateTenants, 
        handleDelete, 
        updateDataTable, 
        searchValue, 
        isSearching, 
        setSearchValue,
        setIsSearching,
        spinning,
        showModal,
        modalVisible,
        handleEdit,
        editMode,
        selectedTenant,
        handleCancel,
        handleClearSearch,
        shouldResetForm,
        setEditMode,
        setShouldResetForm,
        setModalVisible
    }  as const;
}