/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Form, Input, Button, Select, Spin } from "antd";
import { PaymentMethodInterface } from "../intefaces/PaymentMethodInterface";
import { useHookFormPaymentMethods } from "../hooksForm/useHookFormPaymentMethods";
import { UserInterface } from "../intefaces/UserInterface";

const { Option } = Select;

interface FormPaymentMethodProps {
  updateTable: () => void;
  editMode: boolean;
  initialValues?: PaymentMethodInterface;
  modalVisible: boolean;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  setEditMode: (visible: boolean) => void;
  companies?: any[];
  user?: UserInterface;
}

const FormPaymentMethod: React.FC<FormPaymentMethodProps> = ({
  updateTable,
  editMode,
  initialValues,
  shouldResetForm,
  setShouldResetForm,
  companies,
  user,
}) => {
  const { form, onFinish, spinning, spinningText } = useHookFormPaymentMethods(
    updateTable,
    editMode,
    shouldResetForm,
    setShouldResetForm,
    companies,
    initialValues,
    user
  );

  return (
    <div className="flex flex-row justify-between w-full h-1/2 mb-5">
      <Spin spinning={spinning} tip={spinningText} fullscreen />
      <div className="justify-start w-80">
        <h1 className="text-2xl flex flex-col">
          Detalhes Metódos de Pagamento
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <div className="flex flex-row w-full">
          <Form.Item
            name="payment_method_code"
            label="ID"
            className="w-1/1 mr-3"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="payment_method_description"
            label="Descrição"
            className="w-1/2 mr-3"
            rules={[
              { required: true, message: "Por favor insira uma descrição!" },
            ]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="payment_method_company"
            label="Empresa"
            className="w-1/4"
          >
            <Select style={{ textTransform: "uppercase" }}>
              {companies &&
                companies.map((company) => (
                  <Option
                    key={company.company_id}
                    value={company.company_id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {company.company_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row justify-end">
          <Button
            type="default"
            className="w-1/5"
            htmlType="submit"
            style={{ backgroundColor: "green", color: "white" }}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormPaymentMethod;
