import axios, { AxiosError } from 'axios';
import { api } from '../api/api';
import { ContractInterface } from '../intefaces/ContractInterface';

export const createContract = async (contract: ContractInterface, tId?: number): Promise<ContractInterface> => {
    try {
        const response = await api.post(`/new/contract/${tId}`, contract);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getContract = async (param: string, value: string, tId?: number): Promise<ContractInterface[]> => {
    try {
        const response = await api.get(`/find/one/contract/${param}/${value}/${tId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Contrato não encontrado');
        }
    } catch (error) {
         if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar contrato:'+ (error as AxiosError).message);
        } else {
            throw error;
        }
    }
}

export const getAllContracts = async (tId?: number, companyId?: number[], role?: string): Promise<ContractInterface[]> => {
    if(tId === undefined) return [];
    try {
        const response = await api.get(`/find/all/contracts/${tId}/${companyId!.length > 0 ? companyId : [0]}/${role}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Contratos não encontrados');
        }
    } catch (error) {
         if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar contratos: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
};

export const updateContract = async (contract_id: number, contract: ContractInterface, tId?: number): Promise<ContractInterface> => {
    try {
        const response = await api.put(`/update/contract/${tId}/${contract_id}`, contract);
        if(response.status === 200) {
          return response.data;
        } else {
          throw new Error('Erro ao atualizar o contrato!');
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          throw new Error('Erro ao atualizar o contrato: '+ (error as AxiosError).message);
        } else {
          throw error;
        }
      }
}

export const deleteContract = async (contract_id: number, tId?: number) => {
    try {
        const response = await api.delete(`/delete/contract/${tId}/${contract_id}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao deletar o contrato!');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao deletar o contrato: '+ (error as AxiosError).message);
        } else {
            throw error;
        }
    }
}

