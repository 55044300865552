/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Divider, Layout, Card, Row, Col, Statistic } from "antd";
import { useTheme } from "../contexts/ThemeContext";
import { useAuth } from "../contexts/AuthContext";
import { useHookMovement } from "../hooks/useHookMovement";
import { useHookAssets } from "../hooks/useHookAssets";
import Tabled from "../components/Table";
import { columnsMovementsMain } from "../utils/ColumnsUtils";
import {
  DollarOutlined,
  UpCircleOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { useHookMain } from "../hooks/useHookMain";

const { Content } = Layout;

const Dashboard: React.FC = () => {
  const { theme, palette } = useTheme();
  const { user } = useAuth();
  const { movements } = useHookMovement(user?.tenant.id, user?.userCompanies.map(companyId => companyId.company!.company_id), user?.role);
  const { assets } = useHookAssets(user?.tenant.id, user?.userCompanies.map(companyId => companyId.company!.company_id), user?.role);
  const { formattedTotalSumAberto, formattedTotalSumPago, assetCount } = useHookMain(movements, assets);

  const CustomStatisticTitle = styled.div`
    color: ${palette.fontColor};
  `;

  const tableColumns = columnsMovementsMain();

  return (
    <div className="flex flex-col w-full h-full">
      <Divider orientation="left" style={{ borderColor: palette.fontColor }}>
        <h1 className="text-xl" style={{ color: palette.fontColor }}>
          Dashboard
        </h1>
      </Divider>

      <div className="flex justify-between w-full h-1/4 mb-4 overflow-hidden">
        <Row className="flex items-center justify-around w-full h-full">
          <Col span={5} className="shadow-md">
            <Card
              bordered={false}
              style={{ background: palette.secondary }}
              className="shadow-md transition-all ease-in-out duration-300 hover:-translate-y-1"
            >
              <Statistic
                title={
                  <CustomStatisticTitle>Total em aberto</CustomStatisticTitle>
                }
                value={formattedTotalSumAberto()}
                //precision={3}
                valueStyle={{ color: "#0ea5e9" }}
                //prefix="R$ "
                suffix={<DollarOutlined />}
              />
            </Card>
          </Col>
          <Col span={5} className="shadow-md">
            <Card
              bordered={false}
              style={{ background: palette.secondary }}
              className="shadow-md transition-all ease-in-out duration-300 hover:-translate-y-1"
            >
              <Statistic
                title={
                  <CustomStatisticTitle>Total Faturado</CustomStatisticTitle>
                }
                value={formattedTotalSumPago()}
                //precision={2}
                valueStyle={{ color: "green" }}
                //prefix="R$ "
                suffix={<UpCircleOutlined />}
              />
            </Card>
          </Col>
          <Col span={5} className="shadow-md">
            <Card
              bordered={false}
              style={{ background: palette.secondary }}
              className="shadow-md transition-all ease-in-out duration-300 hover:-translate-y-1"
            >
              <Statistic
                title={
                  <CustomStatisticTitle>Total de ativos</CustomStatisticTitle>
                }
                value={assetCount}
                precision={0}
                valueStyle={{ color: "#14b8a6" }}
                suffix={<InboxOutlined />}
              />
            </Card>
          </Col>
        </Row>
      </div>

      <Layout
        style={{
          background: palette.secondary,
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
        }}
      >
        <Content className="w-full">
          <Tabled columns={tableColumns} data={movements} />
        </Content>
      </Layout>
    </div>
  );
};

export default Dashboard;
