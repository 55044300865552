/* eslint-disable @typescript-eslint/no-unused-vars */
import { SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Layout, Modal, Select, Spin } from "antd";
import React from "react";
import FormCompany from "../components/FormCompany";
import Tabled from "../components/Table";
import { useAuth } from "../contexts/AuthContext";
import { useTheme } from "../contexts/ThemeContext";
import { useHookCities } from "../hooks/useHookCities";
import { useHooksCompanies } from "../hooks/useHooksCompanies";
import { columnsCompany } from "../utils/ColumnsUtils";

const { Content } = Layout;
const { Option } = Select;

const Company: React.FC = () => {
  const { user } = useAuth();

  const {
    companies,
    updateCompanies,
    handleDelete,
    updateDataTable,
    searchValue,
    isSearching,
    setSearchValue,
    spinning,
    showModal,
    modalVisible,
    handleEdit,
    editMode,
    selectedCompanies,
    handleCancel,
    handleClearSearch,
    shouldResetForm,
    setEditMode,
    setShouldResetForm,
    setModalVisible,
    searchParam,
    setSearchParam,
    handleKeyPress
  } = useHooksCompanies(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );
  const { cities } = useHookCities();
  const { theme, palette } = useTheme();

  const tableColumns = columnsCompany(handleEdit, handleDelete);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      <Spin spinning={spinning} tip="Buscando..." fullscreen />
      <Divider orientation="left" style={{ borderColor: palette.fontColor }}>
        <h1 className="text-3xl mb-1" style={{ color: palette.fontColor }}>
          Empresas
        </h1>
      </Divider>
      <div className="flex justify-end w-full h-20">
        <Button type="primary" className="mb-4" onClick={showModal}>
          Adicionar Empresa
        </Button>
      </div>
      <div className="flex flex-row justify-start mb-5">
        <Select
          defaultValue="company_code"
          className="mr-2 w-1/5 font-bold"
          style={{ background: palette.secondary }}
          onChange={(value) => setSearchParam(value)}
        >
          <Option value="company_code">ID</Option>
          <Option value="company_name">Nome</Option>
          <Option value="company_cpfOrCnpj">CPF/CNPJ</Option>
          <Option value="company_email">Email</Option>
        </Select>
        <Input
          placeholder="Pesquisar"
          className={
            theme === "light"
              ? `w-full mr-2 font-semibold placeholder:text-gray-500`
              : "mr-2 font-semibold placeholder:text-gray-300 border-transparent"
          }
          value={searchValue}
          style={{ background: palette.secondary, color: palette.fontColor, textTransform: 'uppercase' }}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e, searchParam, searchValue.toUpperCase())}
        />
        <Button
          type="primary"
          className=" mr-2"
          icon={<SearchOutlined />}
          onClick={() => updateDataTable(searchParam, searchValue.toUpperCase())}
        >
          Pesquisar
        </Button>
        {isSearching ? (
          <Button
            type="primary"
            className="mr-2"
            danger
            onClick={() => {
              handleClearSearch();
            }}
          >
            Limpar
          </Button>
        ) : null}
      </div>
      <Modal
        open={modalVisible}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        width={1200}
        maskClosable={false}
        className="overflow-hidden"
      >
        <Divider orientation="left">
          <h1 className="text-2xl">
            {editMode ? "Editar Empresa" : "Cadastrar Empresa"}
          </h1>
        </Divider>
        <div className="p-4 overflow-hidden">
          <FormCompany
            updateTable={updateCompanies}
            editMode={editMode}
            initialValues={selectedCompanies}
            modalVisible={modalVisible}
            shouldResetForm={shouldResetForm}
            setShouldResetForm={setShouldResetForm}
            setEditMode={setEditMode}
            cities={cities}
            user={user || undefined}
            setModalVisible={setModalVisible}
          />
        </div>
      </Modal>
      <Layout style={{ background: palette.secondary }}>
        <Content className="overflow-auto">
          <Tabled columns={tableColumns} data={companies} />
        </Content>
      </Layout>
    </div>
  );
};

export default Company;
