import axios, { AxiosError } from 'axios';
import { api } from '../api/api';
import { MovementInterface } from '../intefaces/MovementInterface';

export const createMovement = async (movement: MovementInterface, tId?: number): Promise<MovementInterface> => {
    try {
        const response = await api.post(`/new/movement/${tId}`, movement);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getMovement = async (param: string, value: string, tId?: number): Promise<MovementInterface[]> => {
    try {
        const response = await api.get(`/find/one/movement/${param}/${value}/${tId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Movimentação não encontrado');
        }
    } catch (error) {
         if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar movimentação:'+ (error as AxiosError).message);
        } else {
            throw error;
        }
    }
}

export const getMovementByCode = async (movement_code: number, tId?: number): Promise<MovementInterface> => {
    try {
        const response = await api.get(`/find/one/movement/code/${movement_code}/${tId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Movimentação não encontrado');
        }
    } catch (error) {
         if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar movimentação:'+ (error as AxiosError).message);
        } else {
            throw error;
        }
    }
}

export const getAllMovements = async (tId?: number, companyId?: number[], role?: string) => {
    if(tId === undefined) return [];
    try {
        const response = await api.get(`/find/all/movements/${tId}/${companyId!.length > 0 ? companyId : [0]}/${role}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Movimentações não encontrados');
        }
    } catch (error) {
         if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar movimentações: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
};

export const updateMovement = async (id: number, movement: MovementInterface, tId?: number): Promise<MovementInterface> => {
    try {
        const response = await api.put(`/update/movement/${tId}/${id}`, movement);
        if(response.status === 200) {
          return response.data;
        } else {
          throw new Error('Erro ao atualizar a movimentação!');
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          throw new Error('Erro ao atualizar a movimentação: '+ (error as AxiosError).message);
        } else {
          throw error;
        }
      }
}

export const deleteMovement = async (id?: number, tId?: number) => {
    try {
        const response = await api.delete(`/delete/movement/${tId}/${id}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao deletar o movimentação!');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao deletar o movimentação: '+ (error as AxiosError).message);
        } else {
            throw error;
        }
    }
}

export const deleteMovemenData = async (movement_data_id: number) => {
    try {
        const response = await api.delete(`/delete/movementdata/${movement_data_id}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao deletar o item da movimentação!');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao deletar o item da movimentação: '+ (error as AxiosError).message);
        } else {
            throw error;
        }
    }
}


