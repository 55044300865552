/* eslint-disable @typescript-eslint/no-unused-vars */
import { SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Layout, Modal, Select, Spin } from "antd";
import React from "react";
import FormUser from "../components/FormUser";
import { FormUserCompany } from "../components/FormUserCompany";
import Tabled from "../components/Table";
import { useAuth } from "../contexts/AuthContext";
import { useTheme } from "../contexts/ThemeContext";
import { useHookLogin } from "../hooks/useHookLogin";
import { useHooksCompanies } from "../hooks/useHooksCompanies";
import { useHookUserCompany } from "../hooks/useHookUserCompany";
import { columnsUsers } from "../utils/ColumnsUtils";

const { Content } = Layout;
const { Option } = Select;

const Users: React.FC = () => {
  const { user } = useAuth();

  const {
    logins,
    updateUsers,
    showModal,
    modalVisible,
    handleEdit,
    editMode,
    handleCancel,
    handleDelete,
    selectedLogin,
    shouldResetForm,
    setShouldResetForm,
    searchValue,
    setSearchValue,
    isSearching,
    handleClearSearch,
    updateDataTable,
    spinning,
    searchParam,
    setSearchParam,
    handleKeyPress
  } = useHookLogin(user?.tenant.id);
  const { theme, palette } = useTheme();

  const {
    updateUserCompany,
    selectUserCompany,
    showModal: showModalUserCompany,
    modalVisible: modalVisibleUserCompany,
    editMode: editModeUserCompany,
    handleCancel: handleCancelUserCompany,
    user: linkedUser,
  } = useHookUserCompany(user?.tenant.id, user?.id);
  const { companies } = useHooksCompanies(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );

  const tableColumns = columnsUsers(
    showModalUserCompany,
    handleEdit,
    handleDelete
  );

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      <Spin spinning={spinning} tip="Buscando..." fullscreen />
      <Divider orientation="left" style={{ borderColor: palette.fontColor }}>
        <h1 className="text-3xl mb-1" style={{ color: palette.fontColor }}>
          Usuários
        </h1>
      </Divider>
      <div className="flex justify-end w-full h-20">
        <Button type="primary" className="mb-4" onClick={showModal}>
          Adicionar Usuário
        </Button>
      </div>
      <div className="flex flex-row justify-start mb-5">
        <Select
          defaultValue="id"
          className="mr-2 w-1/5 bg-slate-100 font-bold"
          style={{ background: palette.secondary }}
          onChange={(value) => setSearchParam(value)}
        >
          <Option value="id">ID</Option>
          <Option value="username">Nome</Option>
          <Option value="email">Email</Option>
        </Select>
        <Input
          placeholder="Pesquisar"
          className={
            theme === "light"
              ? `w-full mr-2 font-semibold placeholder:text-gray-500`
              : "mr-2 font-semibold placeholder:text-gray-300 border-transparent"
          }
          value={searchValue}
          style={{ background: palette.secondary, color: palette.fontColor, textTransform: 'uppercase' }}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e, searchParam, searchValue.toUpperCase())}
        />
        <Button
          type="primary"
          className=" mr-2"
          icon={<SearchOutlined />}
          onClick={() => updateDataTable(searchParam, searchValue.toUpperCase())}
        >
          Pesquisar
        </Button>
        {isSearching ? (
          <Button
            type="primary"
            className="mr-2"
            danger
            onClick={() => {
              handleClearSearch();
            }}
          >
            Limpar
          </Button>
        ) : null}
      </div>
      <Modal
        open={modalVisible}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        width={1000}
        maskClosable={false}
        className="overflow-hidden"
      >
        <Divider orientation="left">
          <h1 className="text-2xl">
            {editMode ? "Editar Usuário" : "Cadastrar Usuário"}
          </h1>
        </Divider>
        <div className="p-4 overflow-hidden">
          <FormUser
            updateTable={updateUsers}
            editMode={editMode}
            initialValues={selectedLogin}
            modalVisible={modalVisible}
            shouldResetForm={shouldResetForm}
            setShouldResetForm={setShouldResetForm}
            user={user || undefined}
          />
        </div>
      </Modal>
      <Modal
        open={modalVisibleUserCompany}
        onCancel={handleCancelUserCompany}
        footer={null}
        centered={true}
        width={1000}
        maskClosable={false}
        className="overflow-hidden"
      >
        <Divider orientation="left">
          <h1 className="text-2xl">
            {editModeUserCompany ? "Editar Vinculo" : "Vincular Empresa"}
          </h1>
        </Divider>
        <div className="p-4 overflow-hidden">
          <FormUserCompany
            updateTable={updateUserCompany}
            editMode={editModeUserCompany}
            initialValues={selectUserCompany}
            shouldResetForm={shouldResetForm}
            setShouldResetForm={setShouldResetForm}
            companies={companies}
            user={user || undefined}
            linkedUser={linkedUser}
          />
        </div>
      </Modal>
      <Layout style={{ background: palette.secondary }}>
        <Content className="overflow-auto">
          <Tabled columns={tableColumns} data={logins} />
        </Content>
      </Layout>
    </div>
  );
};

export default Users;
