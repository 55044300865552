/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  Button,
  Space,
  Tag,
  type TableProps
} from "antd";
import moment from "moment";
import PopupConfirm from "../components/PopupConfirm";
import { Asset } from "../intefaces/Asset";
import { ClientInterface } from "../intefaces/ClientInterface";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { ContractInterface } from "../intefaces/ContractInterface";
import { DepreciationInterface } from "../intefaces/DepreciationInterface";
import { LocalizationInterface } from "../intefaces/LocalizationInterface";
import { MovementDataInterface } from "../intefaces/MovementDataInterface";
import { MovementInterface } from "../intefaces/MovementInterface";
import { PaymentMethodInterface } from "../intefaces/PaymentMethodInterface";
import { PersonInterface } from "../intefaces/PersonInterface";
import { ResponsibleInterface } from "../intefaces/ResponsibleInterface";
import { SituationInterface } from "../intefaces/SituationInterface";
import { TenantInterface } from "../intefaces/TenantInterface";
import { TypeInterface } from "../intefaces/TypeInterface";
import { UserCompany } from "../intefaces/UserCompany";
import { UserInterface } from "../intefaces/UserInterface";

export const ColumnsPersons = (
  editFunction: (record: PersonInterface) => void,
  deleteFunction: (record: number) => void
): TableProps<PersonInterface>["columns"] => [
  {
    title: "ID",
    dataIndex: "person_code",
    key: "person_code",
    sorter: (a, b) => a.person_code - b.person_code,
    defaultSortOrder: "ascend",
  },
  {
    title: "Nome",
    dataIndex: "person_name",
    key: "person_name",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Tipo",
    dataIndex: "person_type",
    key: "person_type",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>
        {text === "client"
          ? "Cliente"
          : text === "supplier"
          ? "Fornecedor"
          : text}
      </span>
    ),
  },
  {
    title: "Status",
    dataIndex: "person_status",
    key: "person_status",
  },
  {
    title: "CPF/CNPJ",
    dataIndex: "person_cpfOrCnpj",
    key: "person_cpfOrCnpj",
  },
  {
    title: "Celular",
    dataIndex: "person_phone",
    key: "person_phone",
  },
  {
    title: "Email",
    dataIndex: "person_email",
    key: "person_email",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="middle">
        <a className="text-blue-500" onClick={() => editFunction(record)}>
          Editar
        </a>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.person_id)}
        />
      </Space>
    ),
  },
];

export const columnsCompany = (
  editFunction: (record: CompanyInterface) => void,
  deleteFunction: (record: number) => void
): TableProps<CompanyInterface>["columns"] => [
  {
    title: "ID",
    dataIndex: "company_code",
    key: "company_code",
    sorter: (a, b) => a.company_code - b.company_code,
    defaultSortOrder: "ascend",
  },
  {
    title: "Nome",
    dataIndex: "company_name",
    key: "company_name",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Nome Fantasia",
    dataIndex: "company_fantasy",
    key: "company_fantasy",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "CPF/CNPJ",
    dataIndex: "company_cpfOrCnpj",
    key: "company_cpfOrCnpj",
  },
  {
    title: "Celular",
    dataIndex: "company_phone",
    key: "company_phone",
  },
  {
    title: "CEP",
    dataIndex: "company_postal_code",
    key: "company_postal_code",
  },
  {
    title: "Endereço",
    dataIndex: "company_address",
    key: "company_address",
  },
  {
    title: "Email",
    dataIndex: "company_email",
    key: "company_email",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="middle">
        <a className="text-blue-500" onClick={() => editFunction(record)}>
          Editar
        </a>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.company_id)}
        />
      </Space>
    ),
  },
];

export const columnsResponsible = (
  editFunction: (record: ResponsibleInterface) => void,
  deleteFunction: (record: number) => void
): TableProps<ResponsibleInterface>["columns"] => [
  {
    title: "ID",
    dataIndex: "responsible_code",
    key: "responsible_code",
    sorter: (a, b) => a.responsible_code - b.responsible_code,
    defaultSortOrder: "ascend",
  },
  {
    title: "Nome",
    dataIndex: "responsible_name",
    key: "responsible_name",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Status",
    dataIndex: "responsible_status",
    key: "responsible_status",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>
        {text === "A" ? "ativo" : text === "I" ? "inativo" : text}
      </span>
    ),
  },
  {
    title: "CPF",
    dataIndex: "responsible_cpf",
    key: "responsible_cpf",
  },
  {
    title: "Celular",
    dataIndex: "responsible_phone",
    key: "responsible_phone",
  },
  {
    title: "Endereço",
    dataIndex: "responsible_address",
    key: "responsible_address",
  },
  {
    title: "Email",
    dataIndex: "responsible_email",
    key: "responsible_email",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="middle">
        <a className="text-blue-500" onClick={() => editFunction(record)}>
          Editar
        </a>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.responsible_id)}
        />
      </Space>
    ),
  },
];

export const columnsAsset = (
  editFunction: (record: Asset) => void,
  deleteFunction: (record: number) => void
): TableProps<Asset>["columns"] => [
  {
    title: "ID",
    dataIndex: "asset_code",
    key: "asset_code",
    sorter: (a, b) => a.asset_code - b.asset_code,
    defaultSortOrder: "ascend",
  },
  {
    title: "Serial",
    dataIndex: "asset_serial",
    key: "asset_serial",
  },
  {
    title: "Nota Fiscal",
    dataIndex: "asset_invoice_number",
    key: "asset_invoice_number",
  },
  {
    title: "Descrição",
    dataIndex: "asset_description",
    key: "asset_description",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Data da Compra",
    dataIndex: "asset_purchase_date",
    key: "asset_purchase_date",
    render: (purchaseDate: string) => moment(purchaseDate).format("DD/MM/YYYY"),
  },
  {
    title: "Cliente",
    dataIndex: "asset_client",
    key: "asset_client",
    hidden: true,
    render: (client: ClientInterface) => client?.client_uid,
  },
  {
    title: "Valor Compra R$",
    dataIndex: "asset_purchase_value",
    key: "asset_purchase_value",
    render: (value: number) => {
      const formattedValue =
        typeof value === "string"
          ? new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(value)
          : "R$ 0,00";
      return <span>{formattedValue}</span>;
    },
  },
  {
    title: "Depreciação Anual %",
    dataIndex: "asset_depreciation_rate",
    key: "asset_depreciation_rate",
    render: (text: string) => <span>{text} %</span>,
  },
  {
    title: "Estado de Conservação",
    dataIndex: "asset_conservation_state",
    key: "asset_conservation_state",
    render: (state: string) => (
      <Tag color={getTagColor(state)}>{getTagText(state)}</Tag>
    ),
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="middle">
        <a className="text-blue-500" onClick={() => editFunction(record)}>
          Editar
        </a>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.asset_id)}
        />
      </Space>
    ),
  },
];

export const columnsDepreciation =
  (): TableProps<DepreciationInterface>["columns"] => [
    {
      title: "Ano",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Valor Inicial R$",
      dataIndex: "initial_value",
      key: "initial_value",
      render: (value: number) => {
        const formattedValue =
          typeof value === "string"
            ? new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)
            : "R$ 0,00";
        return <span>{formattedValue}</span>;
      },
    },
    {
      title: "Vl. Dreciação Anual",
      dataIndex: "annual_depreciation_value",
      key: "annual_depreciation_value",
      render: (value: number) => {
        const formattedValue =
          typeof value === "string"
            ? new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)
            : "R$ 0,00";
        return <span>{formattedValue}</span>;
      },
    },
    {
      title: "Valor Final R$",
      dataIndex: "final_value",
      key: "final_value",
      render: (value: number) => {
        const formattedValue =
          typeof value === "string"
            ? new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)
            : "R$ 0,00";
        return <span>{formattedValue}</span>;
      },
    },
    {
      title: "Provisão",
      dataIndex: "monthly_Provision",
      key: "monthly_Provision",
      render: (value: number) => {
        const formattedValue =
          typeof value === "string"
            ? new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)
            : "R$ 0,00";
        return <span>{formattedValue}</span>;
      },
    },
  ];

export const columnsType = (
  editFunction: (record: TypeInterface) => void,
  deleteFunction: (record: number) => void
): TableProps<TypeInterface>["columns"] => [
  {
    title: "ID",
    dataIndex: "type_code",
    key: "type_code",
    sorter: (a, b) => a.type_code - b.type_code,
    defaultSortOrder: "ascend",
  },
  {
    title: "Descrição",
    dataIndex: "type_description",
    key: "type_description",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="small">
        <a className="text-blue-500" onClick={() => editFunction(record)}>
          Editar
        </a>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.type_id)}
        />
      </Space>
    ),
  },
];

export const columnsContract = (
  editFunction: (record: ContractInterface) => void,
  deleteFunction: (record: number) => void
): TableProps<ContractInterface>["columns"] => [
  {
    title: "ID",
    dataIndex: "contract_code",
    key: "contract_code",
    sorter: (a, b) => a.contract_code - b.contract_code,
    defaultSortOrder: "ascend",
  },
  {
    title: "Descrição",
    dataIndex: "constract_description",
    key: "constract_description",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Tempo",
    dataIndex: "contract_time_type",
    key: "contract_time_type",
    render: (time: string) => <p>{getContractTime(time)}</p>,
  },
  {
    title: "Valor R$",
    dataIndex: "contract_value",
    key: "contract_value",
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="middle">
        <a className="text-blue-500" onClick={() => editFunction(record)}>
          Editar
        </a>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.contract_id)}
        />
      </Space>
    ),
  },
];

export const columnsPaymentMethod = (
  editFunction: (record: PaymentMethodInterface) => void,
  deleteFunction: (record: number) => void
): TableProps<PaymentMethodInterface>["columns"] => [
  {
    title: "ID",
    dataIndex: "payment_method_code",
    key: "payment_method_code",
    sorter: (a, b) => a.payment_method_code - b.payment_method_code,
    defaultSortOrder: "ascend",
  },
  {
    title: "Descrição",
    dataIndex: "payment_method_description",
    key: "payment_method_description",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="small">
        <a className="text-blue-500" onClick={() => editFunction(record)}>
          Editar
        </a>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.payment_method_id)}
        />
      </Space>
    ),
  },
];

export const columnsSituation = (
  editFunction: (record: SituationInterface) => void,
  deleteFunction: (record: number) => void
): TableProps<SituationInterface>["columns"] => [
  {
    title: "ID",
    dataIndex: "situation_code",
    key: "situation_code",
    sorter: (a, b) => a.situation_code - b.situation_code,
    defaultSortOrder: "ascend",
  },
  {
    title: "Descrição",
    dataIndex: "situation_description",
    key: "payment_method_description",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="small">
        <a className="text-blue-500" onClick={() => editFunction(record)}>
          Editar
        </a>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.situation_id)}
        />
      </Space>
    ),
  },
];

export const columnsMovements = (
  editFunction: (record: MovementInterface) => void,
  deleteFunction: (record?: number) => void
): TableProps<MovementInterface>["columns"] => [
  {
    title: "ID",
    dataIndex: "code",
    key: "code",
    sorter: (a, b) => a.code! - b.code!,
    defaultSortOrder: "ascend",
  },
  {
    title: "Emissão",
    dataIndex: "create_at",
    key: "create_at",
    render: (text) => {
      const formattedDate = moment(text).format("DD/MM/YYYY");
      return <span>{formattedDate}</span>;
    },
  },
  {
    title: "Contrato",
    dataIndex: "contract_id",
    key: "contract_id",
    render: (contract: ContractInterface) => contract.constract_description,
  },
  {
    title: "Situação",
    dataIndex: "status",
    key: "status",
    render: (state: string) => (
      <Tag color={getSituationColor(state)}>{getSituationText(state)}</Tag>
    ),
  },
  {
    title: "Cliente",
    dataIndex: "person_id",
    key: "person_id",
    render: (client: PersonInterface) => client.person_name,
  },
  {
    title: "Valor R$",
    dataIndex: "data",
    key: "data",
    render: (data: MovementDataInterface[]) => {
      let totalValue = 0;
      data.forEach((movementData) => {
        if (movementData && movementData.total) {
          totalValue += parseFloat(movementData.total.toString());
        }
      });

      return totalValue.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="middle">
        <a className="text-blue-500" onClick={() => editFunction(record)}>
          Editar
        </a>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.id)}
        />
      </Space>
    ),
  },
];

export const columnsMovementsMain =
  (): TableProps<MovementInterface>["columns"] => [
    {
      title: "ID",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code! - b.code!,
    defaultSortOrder: "ascend",
    },
    {
      title: "Emissão",
      dataIndex: "create_at",
      key: "create_at",
      render: (text) => {
        const formattedDate = moment(text).format("DD/MM/YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Contrato",
      dataIndex: "contract_id",
      key: "contract_id",
      render: (contract: ContractInterface) => contract.constract_description,
    },
    {
      title: "Situação",
      dataIndex: "status",
      key: "status",
      render: (state: string) => (
        <Tag color={getSituationColor(state)}>{getSituationText(state)}</Tag>
      ),
    },
    {
      title: "Cliente",
      dataIndex: "person_id",
      key: "person_id",
      render: (client: PersonInterface) => client.person_name,
    },
    {
      title: "Valor R$",
      dataIndex: "data",
      key: "data",
      render: (data: MovementDataInterface[]) => {
        let totalValue = 0;
        data.forEach((movementData) => {
          if (movementData && movementData.total) {
            totalValue += parseFloat(movementData.total.toString());
          }
        });

        return totalValue.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
  ];

export const columnsTenants = (
  editFunction: (record: TenantInterface) => void,
  deleteFunction: (record: number) => void
): TableProps<TenantInterface>["columns"] => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: (a, b) => a.id - b.id,
    defaultSortOrder: "ascend",
  },
  {
    title: "UID",
    dataIndex: "uid",
    key: "uid",
  },
  {
    title: "CPF",
    dataIndex: "cpf",
    key: "cpf",
  },
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="small">
        <a className="text-blue-500" onClick={() => editFunction(record)}>
          Editar
        </a>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.id)}
        />
      </Space>
    ),
  },
];

export const columnsUsers = (
  modalFunction: (record: UserInterface) => void,
  editFunction: (record: UserInterface) => void,
  deleteFunction: (record: number) => void
): TableProps<UserInterface>["columns"] => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: (a, b) => a.id - b.id,
    defaultSortOrder: "ascend",
  },
  {
    title: "Nome",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Regra",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="small" className="w-20">
        <Button
          type="primary"
          className="bg-green-600"
          onClick={() => modalFunction(record)}
        >
          Vincular
        </Button>
        <Button
          type="link"
          className="text-blue-500 text hover:text-blue-700"
          onClick={() => editFunction(record)}
        >
          Editar
        </Button>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.id)}
        />
      </Space>
    ),
  },
];

export const columnsLocalizations = (
  editFunction: (record: LocalizationInterface) => void,
  deleteFunction: (record: number) => void
): TableProps<LocalizationInterface>["columns"] => [
  {
    title: "ID",
    dataIndex: "code",
    key: "code",
    sorter: (a, b) => a.code - b.code,
    defaultSortOrder: "ascend",
  },
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Descrição",
    dataIndex: "description",
    key: "description",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Bairro",
    dataIndex: "neighborhood",
    key: "neighborhood",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Endereço",
    dataIndex: "address",
    key: "address",
    render: (text: string) => (
      <span style={{ textTransform: "uppercase" }}>{text}</span>
    ),
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="small">
        <a className="text-blue-500" onClick={() => editFunction(record)}>
          Editar
        </a>
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.id)}
        />
      </Space>
    ),
  },
];

export const columnsUsersCompany = (
  deleteFunction: (record: number) => void
): TableProps<UserCompany>["columns"] => [
  {
    title: "ID",
    dataIndex: "user_code",
    key: "user_code",
    sorter: (a, b) => a.user_code - b.user_code,
    defaultSortOrder: "ascend",
  },
  {
    title: "Usuário",
    dataIndex: "user",
    key: "user",
    render: (user: UserInterface) => user.username,
  },
  {
    title: "Empresa",
    dataIndex: "company",
    key: "company",
    render: (company: CompanyInterface) => company.company_name,
  },
  {
    title: "Ações",
    key: "actions",
    render: (_, record) => (
      <Space size="small" className="w-20">
        <PopupConfirm
          title="Tem certeza que deseja excluir este registro?"
          description="Esta ação não pode ser desfeita."
          onConfirm={() => deleteFunction(record.id)}
        />
      </Space>
    ),
  },
];

function getTagColor(state: string): string {
  switch (state) {
    case "NO":
      return "cyan";
    case "OT":
      return "green";
    case "BO":
      return "lime";
    case "RE":
      return "gold";
    case "RU":
      return "volcano";
    case "IN":
      return "red";
    default:
      return "default";
  }
}

function getSituationColor(state: string): string {
  switch (state) {
    case "AB":
      return "blue";
    case "PG":
      return "Green";
    case "PA":
      return "yellow";
    case "CA":
      return "red";
    default:
      return "Desconhecido";
  }
}

function getContractTime(state: string): string {
  switch (state) {
    case "HO":
      return "Hora";
    case "DI":
      return "Dia";
    case "SE":
      return "Semana";
    case "ME":
      return "Mês";
    case "AN":
      return "Ano";
    default:
      return "Desconhecido";
  }
}

function getTagText(state: string): string {
  switch (state) {
    case "NO":
      return "Novo";
    case "OT":
      return "Ótimo";
    case "BO":
      return "Bom";
    case "RE":
      return "Regular";
    case "RU":
      return "Ruim";
    case "IN":
      return "Inservível";
    default:
      return "Desconhecido";
  }
}

function getSituationText(state: string): string {
  switch (state) {
    case "AB":
      return "Aberto";
    case "PG":
      return "Pago";
    case "PA":
      return "Parcial";
    case "CA":
      return "Cancelado";
    default:
      return "Desconhecido";
  }
}
