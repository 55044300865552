import axios, { AxiosError } from 'axios';
import { api } from '../api/api';
import { PaymentMethodInterface } from '../intefaces/PaymentMethodInterface';

export const createPaymentMethod = async (paymentMethod: PaymentMethodInterface, tId?: number): Promise<PaymentMethodInterface> => {
    try {
        const response = await api.post(`/new/payment/${tId}`, paymentMethod);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getPaymentMethod = async(param: string, value: string, tId?: number): Promise<PaymentMethodInterface[]> => {
    try {
      const response = await api.get(`/find/one/payment/${param}/${value}/${tId}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao buscar metodo de pagamento');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao buscar metodo de pagamento '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};

export const getAllPaymentMethods = async(tId?: number, companyId?: number[], role?: string) => {
    if(tId === undefined) return [];
    try {
      const response = await api.get(`/find/all/payments/${tId}/${companyId!.length > 0 ? companyId : [0]}/${role}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao buscar metodos de pagamento!');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao buscar metodos de pagamento '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};

export const updatePaymentMethod = async (payment_method_id: number, paymentMethod: PaymentMethodInterface, tId?: number): Promise<PaymentMethodInterface> => {
    try {
      const response = await api.put(`/update/payment/${tId}/${payment_method_id}`, paymentMethod);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao atualizar o metodo de pagamento');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao atualizar o metodo de pagamento '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};

export const deletePaymentMethod = async(payment_method_id: number, tId?: number) => {
    try {
      const response = await api.delete(`/delete/payment/${tId}/${payment_method_id}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao deletar o metodo de pagamento');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao deletar metodo de pagamento '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};
