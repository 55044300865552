/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AimOutlined,
  AppstoreOutlined,
  FileProtectOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  ColorPicker,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Layout,
  Select,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  Upload,
} from "antd";
import React from "react";
import { useHookCities } from "../hooks/useHookCities";
import { useHookLocalization } from "../hooks/useHookLocalization";
import { useHookPersons } from "../hooks/useHookPersons";
import { useHooksCompanies } from "../hooks/useHooksCompanies";
import { useHookSituation } from "../hooks/useHookSituation";
import { useHookTypes } from "../hooks/useHookTypes";
import { useHookFormAsset } from "../hooksForm/useHookFormAsset";
import { Asset } from "../intefaces/Asset";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { ContractInterface } from "../intefaces/ContractInterface";
import { LocalizationInterface } from "../intefaces/LocalizationInterface";
import { TypeInterface } from "../intefaces/TypeInterface";
import { UserInterface } from "../intefaces/UserInterface";
import { columnsDepreciation } from "../utils/ColumnsUtils";
import FormCompany from "./FormCompany";
import FormGroup from "./FormGroup";
import FormLocalization from "./FormLocalization";
import FormPerson from "./FormPerson";
import FormSituation from "./FormSituation";
import Tabled from "./Table";

const { Option } = Select;
const { TabPane } = Tabs;
const { Content } = Layout;
const dateFormat = "DD/MM/YYYY";

interface FormCompanyProps {
  updateTable: () => void;
  editMode: boolean;
  initialValues?: Asset;
  modalVisible: boolean;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  setEditMode: (visible: boolean) => void;
  suppliers?: any[];
  contracts?: ContractInterface[];
  companies?: CompanyInterface[];
  types?: TypeInterface[];
  situations?: any[];
  user?: UserInterface;
  localizations?: LocalizationInterface[] | null;
}

const FormAsset: React.FC<FormCompanyProps> = ({
  updateTable,
  editMode,
  initialValues,
  shouldResetForm,
  setShouldResetForm,
  suppliers,
  user,
}) => {
  const { cities } = useHookCities();

  const {
    types,
    updateTypes: myUpdateTypes,
    modalVisible: myModalVisible,
    selectType: mySelectType,
    shouldResetForm: myShouldResetForm,
    setShouldResetForm: mySetShouldResetForm,
    setEditMode: mySetEditMode,
  } = useHookTypes(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );

  const {
    situations,
    updateSituations: UpdateSituations,
    modalVisible: modalSituation,
    selectedSituation: selectSituation,
    shouldResetForm: shouldResetFormSituation,
    setShouldResetForm: setShouldResetFormSituation,
    setEditMode: setEditModeSituation,
  } = useHookSituation(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );

  const {
    companies,
    updateCompanies,
    modalVisible: modalCompanies,
    selectedCompanies: selecteCompanies,
    shouldResetForm: shouldResetFormCompanies,
    setShouldResetForm: setShouldResetFormCompanies,
    setEditMode: setEditModeCompanies,
    setModalVisible,
  } = useHooksCompanies(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );

  const {
    localizations,
    updateLocalizations,
    modalVisible: modalLocalizations,
    selectedLocalization,
    shouldResetForm: shouldResetFormLocalizations,
    setShouldResetForm: setShouldResetFormLocalizations,
    setEditMode: setEditModeLocalizations,
  } = useHookLocalization(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );

  const {
    persons,
    updatePersons,
    modalVisible: modalPersons,
    selectedPerson,
    shouldResetForm: shouldResetFormPersons,
    setShouldResetForm: setShouldResetFormPersons,
    setEditMode: setEditModePersons,
  } = useHookPersons(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );

  const {
    form,
    onFinish,
    handleColorChange,
    visible,
    onClose,
    showDrawer,
    currentForm,
    logo,
    handleChange,
    handleRemove,
    calculateRate,
    spinning,
    spinningText,
  } = useHookFormAsset(
    updateTable,
    editMode,
    shouldResetForm,
    setShouldResetForm,
    suppliers,
    companies,
    types,
    situations,
    initialValues,
    user,
    localizations
  );

  const tableColumns = columnsDepreciation();

  return (
    <div className="flex flex-row justify-between w-full h-1/2 mb-5">
      <Spin spinning={spinning} tip={spinningText} fullscreen />
      <div className="justify-start w-80">
        <h1 className="text-2xl flex flex-col">
          Detalhes do Patrimonio
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <div className="flex flex-row w-full">
          {logo ? (
            <Avatar
              src={logo}
              size={90}
              className="mb-6 shadow-md"
              onClick={handleRemove}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Form.Item name="asset_image" label="Imagem" className="w-1/1 mr-3">
              <Upload
                className="w-full flex-row "
                accept="image/*"
                listType="picture"
                maxCount={1}
                onChange={handleChange}
                beforeUpload={() => false}
              >
                <Button icon={<UploadOutlined />}>Selecione a imagem</Button>
              </Upload>
            </Form.Item>
          )}
        </div>
        <div className="flex flex-row w-full">
          <Form.Item name="asset_code" label="ID" className="w-1/1 mr-3">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="asset_description"
            label="Descrição"
            className="w-1/2 mr-3"
            rules={[{ required: true, message: "Insira a descrição!" }]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="asset_status"
            label="Situação"
            className="w-1/3"
            rules={[{ required: true }]}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                noStyle
                name="asset_status"
                rules={[{ required: true, message: "Selecione a Situação" }]}
              >
                <Select style={{ textTransform: "uppercase" }}>
                  {situations &&
                    situations.map((situation) => (
                      <Option
                        key={situation.situation_id}
                        value={situation.situation_id}
                        style={{ textTransform: "uppercase" }}
                      >
                        {situation.situation_description}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Tooltip title="Cadastrar Situação">
                <Button
                  type="default"
                  className="ml-2 w-7"
                  onClick={() =>
                    showDrawer(
                      <FormSituation
                        updateTable={UpdateSituations}
                        editMode={editMode}
                        initialValues={selectSituation}
                        modalVisible={modalSituation}
                        shouldResetForm={shouldResetFormSituation}
                        setShouldResetForm={setShouldResetFormSituation}
                        setEditMode={setEditModeSituation}
                        companies={companies}
                        user={user || undefined}
                        orientation="vertical"
                      />
                    )
                  }
                >
                  <FileProtectOutlined className="font-bold" />
                </Button>
              </Tooltip>
            </div>
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            name="asset_type"
            label="Grupo"
            className="w-1/3 mr-3"
            rules={[{ required: true }]}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                noStyle
                name="asset_type"
                rules={[{ required: true, message: "Selecione o Grupo" }]}
              >
                <Select
                  className="w-full"
                  style={{ textTransform: "uppercase" }}
                >
                  {types &&
                    types.map((type) => (
                      <Option
                        key={type.type_id}
                        value={type.type_id}
                        style={{ textTransform: "uppercase", width: "100%" }}
                      >
                        {type.type_description}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Tooltip title="Cadastrar Grupo">
                <Button
                  type="default"
                  className="ml-2 w-7"
                  title="Cadastrar Grupo"
                  onClick={() =>
                    showDrawer(
                      <FormGroup
                        updateTable={myUpdateTypes}
                        editMode={editMode}
                        initialValues={mySelectType}
                        modalVisible={myModalVisible}
                        shouldResetForm={myShouldResetForm}
                        setShouldResetForm={mySetShouldResetForm}
                        setEditMode={mySetEditMode}
                        companies={companies}
                        user={user || undefined}
                        orientarion="vertical"
                      />
                    )
                  }
                >
                  <AppstoreOutlined className="font-bold" />
                </Button>
              </Tooltip>
            </div>
          </Form.Item>
          {/**VOLTAR */}
          <Form.Item
            name="asset_purchase_value"
            label="Valor do Bem R$"
            className="w-1/4 mr-3"
            rules={[
              { required: true, message: "Valor do bem é requerido!" },
            ]}
          >
            <InputNumber
              placeholder="R$"
              className="w-full"
              formatter={(value) => {
                if (value) {
                  const [intPart, decPart] = value.toString().split(".");
                  const intFormatted = intPart.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "."
                  );
                  return `R$ ${intFormatted}${decPart ? "," + decPart : ""}`;
                }
                return "";
              }}
              parser={(value) =>
                value
                  ? value.replace(/\R\$\s?|(\.*)/g, "").replace(",", ".")
                  : ""
              }
              step={0.01}
            />
          </Form.Item>
          <Form.Item
            name="asset_life_cycle"
            label="Vida Util(Em meses)"
            className="w-1/5 mr-3"
            rules={[{ required: true, message: "Informe a vida util!" }]}
          >
            <InputNumber
              placeholder="Em meses"
              className="w-full"
              onBlur={(e) => calculateRate(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="asset_depreciation_rate"
            label="Taxa Deprec.(Anual)"
            className="w-1/5"
            rules={[{ required: true, message: "Informe a taxa!" }]}
          >
            <InputNumber placeholder="%" className="w-full" disabled />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            name="asset_conservation_state"
            label="Estado"
            className="w-1/3 mr-3"
          >
            <Select>
              <Option value="NO">
                <Tag className="w-10/12" color="cyan">
                  Novo
                </Tag>
              </Option>
              <Option value="OT">
                <Tag className="w-10/12" color="green">
                  Ótimo
                </Tag>
              </Option>
              <Option value="BO">
                <Tag className="w-10/12" color="lime">
                  Bom
                </Tag>
              </Option>
              <Option value="RE">
                <Tag className="w-10/12" color="gold">
                  Regular
                </Tag>
              </Option>
              <Option value="RU">
                <Tag className="w-10/12" color="volcano">
                  Ruim
                </Tag>
              </Option>
              <Option value="IN">
                <Tag className="w-10/12" color="red">
                  Inservível
                </Tag>
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="asset_details"
            label="Detalhes"
            className="w-full mr-3"
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item name="asset_color" label="Cor" className="w-1/6">
            <ColorPicker
              format="hex"
              className="w-full"
              size="middle"
              showText
              onChange={handleColorChange}
            />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            name="asset_company"
            label="Empresa"
            className="w-2/3 mr-3"
            rules={[{ required: true }]}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                noStyle
                name="asset_company"
                rules={[{ required: true, message: "Selecione a Empresa" }]}
              >
                <Select style={{ textTransform: "uppercase" }}>
                  {companies &&
                    companies.map((company) => (
                      <Option
                        key={company.company_id}
                        value={company.company_id}
                        style={{ textTransform: "uppercase" }}
                      >
                        {company.company_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Tooltip title="Cadastrar Empresa">
                <Button
                  type="default"
                  className="ml-2 w-7"
                  onClick={() =>
                    showDrawer(
                      <FormCompany
                        updateTable={updateCompanies}
                        editMode={editMode}
                        initialValues={selecteCompanies}
                        modalVisible={modalCompanies}
                        shouldResetForm={shouldResetFormCompanies}
                        setShouldResetForm={setShouldResetFormCompanies}
                        setEditMode={setEditModeCompanies}
                        cities={cities}
                        user={user || undefined}
                        setModalVisible={setModalVisible}
                        orientation="vertical"
                      />
                    )
                  }
                >
                  <ShopOutlined className="font-bold" />
                </Button>
              </Tooltip>
            </div>
          </Form.Item>
          <Form.Item
            name="asset_purchase_date"
            label="Data da Compra"
            className="w-1/5 mr-3"
          >
            <DatePicker format={dateFormat} className="w-full" />
          </Form.Item>
          <Form.Item
            name="asset_value"
            label="Valor Venda R$"
            className="w-1/5"
          >
            <InputNumber
              placeholder="R$"
              className="w-full"
              formatter={(value) => {
                if (value) {
                  const [intPart, decPart] = value.toString().split(".");
                  const intFormatted = intPart.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "."
                  );
                  return `R$ ${intFormatted}${decPart ? "," + decPart : ""}`;
                }
                return "";
              }}
              parser={(value) =>
                value
                  ? value.replace(/\R\$\s?|(\.*)/g, "").replace(",", ".")
                  : ""
              }
              step={0.01}
            />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            name="asset_localization"
            label="Localização"
            className="w-1/2 mr-3"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item noStyle name="asset_localization">
                <Select
                  className="w-full"
                  style={{ textTransform: "uppercase" }}
                >
                  {localizations &&
                    localizations.map((localization) => (
                      <Option
                        key={localization.id}
                        value={localization.id}
                        style={{ textTransform: "uppercase" }}
                      >
                        {localization.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Tooltip title="Cadastrar Localização">
                <Button
                  type="default"
                  className="ml-2 w-7"
                  onClick={() =>
                    showDrawer(
                      <FormLocalization
                        updateTable={updateLocalizations}
                        editMode={editMode}
                        initialValues={selectedLocalization}
                        modalVisible={modalLocalizations}
                        shouldResetForm={shouldResetFormLocalizations}
                        setShouldResetForm={setShouldResetFormLocalizations}
                        setEditMode={setEditModeLocalizations}
                        companies={companies}
                        user={user || undefined}
                        orientation="vertical"
                      />
                    )
                  }
                >
                  <AimOutlined className="font-bold" />
                </Button>
              </Tooltip>
            </div>
          </Form.Item>
          <Form.Item name="asset_supplier" label="Fornecedor" className="w-1/2">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item noStyle>
                <Select style={{ textTransform: "uppercase" }}>
                  {persons &&
                    persons
                      .filter((supplier) => supplier.person_type === "supplier")
                      .map((supplier) => (
                        <Option
                          value={supplier.person_id}
                          style={{ textTransform: "uppercase" }}
                        >
                          {supplier.person_name}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
              <Tooltip title="Cadastrar Fornecedores">
                <Button
                  type="default"
                  className="ml-2 w-7"
                  onClick={() =>
                    showDrawer(
                      <FormPerson
                        updateTable={updatePersons}
                        editMode={editMode}
                        initialValues={selectedPerson}
                        modalVisible={modalPersons}
                        shouldResetForm={shouldResetFormPersons}
                        setShouldResetForm={setShouldResetFormPersons}
                        setEditMode={setEditModePersons}
                        cities={cities}
                        user={user || undefined}
                        orientation="vertical"
                      />
                    )
                  }
                >
                  <TeamOutlined className="font-bold" />
                </Button>
              </Tooltip>
            </div>
          </Form.Item>
        </div>
        <Tabs
          defaultActiveKey="1"
          className="bg-slate-200 p-1 h-full overflow-hidden"
        >
          <TabPane tab="Informações Fiscais" key="1">
            <div className="flex flex-row">
              <Form.Item
                name="asset_serial"
                label="Nº Serial"
                className="w-1/3 mr-3"
                normalize={(value: string) => value?.toUpperCase()}
              >
                <Input style={{ textTransform: "uppercase" }} />
              </Form.Item>
              <Form.Item
                name="asset_identification"
                label="Identificação"
                className="w-1/4 mr-3"
                normalize={(value: string) => value?.toUpperCase()}
              >
                <Input style={{ textTransform: "uppercase" }} />
              </Form.Item>
              <Form.Item
                name="asset_invoice_number"
                label="Nº Nota Fiscal"
                className="w-1/5 mr-3"
              >
                <InputNumber placeholder="Nº" className="w-full" />
              </Form.Item>
              <Form.Item
                name="asset_invoice_date"
                label="Data N.Fiscal"
                className="w-1/5 mr-3"
              >
                <DatePicker format={dateFormat} className="w-full" />
              </Form.Item>
            </div>
            <div className="flex flex-row">
              <Form.Item
                name="asset_guarantee_date"
                label="Garantia Até"
                className="w-1/5 mr-3"
              >
                <DatePicker format={dateFormat} className="w-full" />
              </Form.Item>
              <Form.Item
                name="asset_insurance_validity"
                label="Seguro até"
                className="w-1/5"
              >
                <DatePicker format={dateFormat} className="w-full" />
              </Form.Item>
            </div>
          </TabPane>
          <TabPane tab="Revisões" key="2">
            <div className="flex flex-row">
              <Form.Item
                name="asset_months_revision"
                label="Meses Revisão"
                className="w-1/5 mr-3"
              >
                <InputNumber placeholder="A cada..." className="w-full" />
              </Form.Item>
              <Form.Item
                name="asset_lasted_revision_date"
                label="Última Revisão"
                className="w-1/5 mr-3"
              >
                <DatePicker format={dateFormat} className="w-full" />
              </Form.Item>
              <Form.Item
                name="asset_next_revision_date"
                label="Proxima Revisão"
                className="w-1/5 mr-3"
              >
                <DatePicker format={dateFormat} className="w-full" />
              </Form.Item>
              <Form.Item
                name="asset_revaluation_value"
                label="Valor Revisão R$"
                className="w-1/6"
              >
                <InputNumber placeholder="R$" className="w-full" />
              </Form.Item>
            </div>
          </TabPane>
          <TabPane tab="Veiculos Detalhes" key="3">
            <div className="flex flex-row">
              <Form.Item
                name="asset_chassis"
                label="Chassi"
                className="w-1/5 mr-3"
              >
                <InputNumber placeholder="Nº Chassi" className="w-full" />
              </Form.Item>
              <Form.Item
                name="asset_license_plate"
                label="Placa Veiculo"
                className="w-1/5 mr-3"
              >
                <InputNumber placeholder="Nº Placa" className="w-full" />
              </Form.Item>
              <Form.Item
                name="asset_year_model"
                label="Modelo/Ano"
                className="w-1/5 mr-3"
              >
                <InputNumber placeholder="Ano" className="w-full" />
              </Form.Item>
              <Form.Item
                name="asset_vehicle_color"
                label="Cor"
                className="w-1/6 mr-3"
              >
                <ColorPicker
                  format="hex"
                  className="w-full"
                  size="middle"
                  showText
                  onChange={handleColorChange}
                />
              </Form.Item>
              <Form.Item name="asset_brand" label="Marca" className="w-1/3">
                <Input />
              </Form.Item>
            </div>
          </TabPane>
          {editMode ? (
            <TabPane tab="Depreciações" key="4">
              <div className="flex flex-row">
                <Layout className="bg-white">
                  <Content className="overflow-auto">
                    <Tabled
                      columns={tableColumns}
                      data={initialValues?.asset_depreciations ?? []}
                    />
                  </Content>
                </Layout>
              </div>
            </TabPane>
          ) : (
            ""
          )}
        </Tabs>

        <div className="flex flex-row justify-end">
          <Button
            type="primary"
            className="w-1/5 mt-3"
            color="black"
            htmlType="submit"
            style={{ backgroundColor: "green" }}
          >
            Salvar
          </Button>
        </div>
      </Form>

      <Drawer
        title="Cadastro Ágil"
        width={600}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
        maskClosable={false}
      >
        {currentForm}
      </Drawer>
    </div>
  );
};

export default FormAsset;
