/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  SearchOutlined,
  TeamOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Spin,
  Statistic
} from "antd";
import React from "react";
import styled from "styled-components";
import FormTenant from "../components/FormTenant";
import Tabled from "../components/Table";
import { useTheme } from "../contexts/ThemeContext";
import { useHookCities } from "../hooks/useHookCities";
import { useHookTenant } from "../hooks/useHookTenant";
import { columnsTenants } from "../utils/ColumnsUtils";

const { Content } = Layout;
const { Option } = Select;

const CustomStatisticTitle = styled.div`
  color: black;
`;

const AdminMain: React.FC = () => {
  const {
    tenants,
    updateTenants,
    handleDelete,
    updateDataTable,
    searchValue,
    isSearching,
    setSearchValue,
    spinning,
    showModal,
    modalVisible,
    handleEdit,
    editMode,
    handleCancel,
    handleClearSearch,
    selectedTenant,
    shouldResetForm,
    setShouldResetForm,
    setEditMode,
    setModalVisible,
  } = useHookTenant();

  const tableColumns = columnsTenants(handleEdit, handleDelete);
  const { palette, theme } = useTheme();
  const { cities } = useHookCities();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="flex flex-col items-center h-full justify-center w-full">
        <Spin spinning={spinning} tip="Buscando..." fullscreen />
        <Divider orientation="left">
          <h1 className="text-3xl mb-1" style={{ color: palette.fontColor }}>
            Clientes/Tenants
          </h1>
        </Divider>

        <div className="flex justify-between w-full h-1/4 mb-4 overflow-hidden">
          <Row className="flex items-center justify-around w-full h-full">
            <Col span={5}>
              <Card
                bordered={false}
                style={{ background: palette.secondary }}
                className="shadow-md"
              >
                <Statistic
                  title={
                    <CustomStatisticTitle>
                      Clientes Cadastrados
                    </CustomStatisticTitle>
                  }
                  value={tenants.length}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  prefix={<TeamOutlined />}
                />
              </Card>
            </Col>
            {/*<Col span={5}>
              <Card
                bordered={false}
                style={{ background: palette.secondary }}
                className="shadow-md"
              >
                <Statistic
                  title={
                    <CustomStatisticTitle>
                      Clientes Inativos
                    </CustomStatisticTitle>
                  }
                  value={9.3}
                  precision={2}
                  valueStyle={{ color: "brown" }}
                  prefix={<StopOutlined />}
                />
              </Card>
            </Col>
            <Col span={5}>
              <Card
                bordered={false}
                style={{ background: palette.secondary }}
                className="shadow-md"
              >
                <Statistic
                  title={
                    <CustomStatisticTitle>
                      Clientes em Debito
                    </CustomStatisticTitle>
                  }
                  value={9.3}
                  precision={2}
                  valueStyle={{ color: "#cf1322" }}
                  prefix={<CreditCardOutlined />}
                />
              </Card>
            </Col>*/}
          </Row>
        </div>

        <div className="flex justify-end w-full mb-2" style={{ height: "7%" }}>
          <Button
            type="primary"
            className="mb-2 bg-green-600"
            onClick={showModal}
          >
            Adicionar Cliente
          </Button>
        </div>

        <div
          className="flex flex-row justify-start mb-5 w-full"
          style={{ height: "5%" }}
        >
          <Select
            defaultValue="id"
            className="mr-2 w-1/5 h-full font-bold"
            style={{ background: palette.secondary }}
          >
            <Option value="id">ID</Option>
          </Select>
          <Input
            placeholder="Pesquisar"
            className={
              theme === "light"
                ? `w-full mr-2 font-bold placeholder:text-gray-500`
                : "mr-2 font-bold placeholder:text-gray-300 border-transparent"
            }
            style={{ background: palette.secondary, color: palette.fontColor }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
            type="primary"
            className="h-full"
            icon={<SearchOutlined />}
            onClick={() => updateDataTable(parseInt(searchValue))}
          >
            Pesquisar
          </Button>
          {isSearching ? (
            <Button
              type="primary"
              className="ml-2"
              danger
              onClick={() => {
                handleClearSearch();
              }}
            >
              Limpar
            </Button>
          ) : null}
        </div>

        <Modal
          open={modalVisible}
          onCancel={handleCancel}
          footer={null}
          centered={true}
          width={1200}
          maskClosable={false}
          className="overflow-hidden"
        >
          <Divider orientation="left">
            <h1 className="text-2xl">
              {editMode ? "Editar Tenant" : "Cadastrar Tenant"}
            </h1>
          </Divider>
          <div className="p-4">
            <FormTenant
              updateTable={updateTenants}
              editMode={editMode}
              initialValues={selectedTenant}
              modalVisible={modalVisible}
              shouldResetForm={shouldResetForm}
              setShouldResetForm={setShouldResetForm}
              setEditMode={setEditMode}
              cities={cities}
              setModalVisible={setModalVisible}
            />
          </div>
        </Modal>

        <Layout
          style={{
            width: '100%',
            background: palette.secondary,
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
          }}
        >
          <Content className="overflow-auto w-full h-full">
            <Tabled columns={tableColumns} data={tenants} />
          </Content>
        </Layout>
      </div>
    </div>
  );
};

export default AdminMain;
