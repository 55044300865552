/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { TypeInterface } from "../intefaces/TypeInterface";
import { deleteType, getAllTypes, getType } from "../service/typeService";

export const useHookTypes = (uid?: number, companyId?: number[], role?: string) => {
    const [types, setTypes] = useState<TypeInterface[]>([]);
    const [selectType, setSelectedType] = useState<TypeInterface>();
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchParam, setSearchParam] = useState<string>('type_code');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);

    useEffect(() => {
        const fetchType = async () => {
            try {
                const typeData = await getAllTypes(uid, companyId, role);
                setTypes(typeData);
            } catch (error) {
                console.error('Erro ao buscar tipos: ', error);
            }
        };

        fetchType();
    }, []);


    const updateTypes = async () => {
        try {
            const typeData = await getAllTypes(uid, companyId, role);
            setTypes(typeData);
        } catch (error) {
            console.error('Erro ao buscar tipos: ', error);
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, param: string, value: string) => {
        if (event.key === 'Enter') {
            updateDataTable(param, value);
        } else if (event.key === 'Backspace') {
            handleClearSearch();
        }
    }

    const updateDataTable = async (param: string, value: string) => {
        try {

            if (value === "") {
                message.error("Campo de busca não pode ser vázio!")
                return;
            }

            /*if(value.startsWith("*") && param === 'type_code') {
                message.error("O campo ID não pode conter (*).")
                return;
            }*/

            let findType: React.SetStateAction<TypeInterface[]>;

            if (param === 'type_code') {
                findType = await getType(param, value, uid);
            } else {
                const addParam = '*' + value;
                findType = await getType(param, addParam, uid);
            }

            setSpinning(true);

            setTimeout(() => {
                if (findType) {
                    setTypes(findType);
                    //setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                }

                message.error('Nenhum tipo encontrado!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar tipo: ', error);
            message.error('Erro ao buscar tipo');
        }
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleEdit = (record: TypeInterface) => {
        setSelectedType(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
    };

    const handleDelete = async (record: number) => {
        try {
            await deleteType(record, uid);
            updateTypes();
            message.success('Tipo deletado com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar tipo: ', error);
            message.error('Erro ao deletar tipo!');
        }
    };

    const handleClearSearch = () => {
        updateTypes();
        setSearchValue('');
        setIsSearching(false);
    };

    return {
        types,
        updateTypes,
        selectType,
        setSelectedType,
        showModal,
        modalVisible,
        setModalVisible,
        handleEdit,
        editMode,
        handleCancel,
        handleDelete,
        shouldResetForm,
        setShouldResetForm,
        setEditMode,
        searchValue,
        setSearchValue,
        isSearching,
        setIsSearching,
        handleClearSearch,
        updateDataTable,
        spinning,
        searchParam,
        setSearchParam,
        handleKeyPress
    };

}