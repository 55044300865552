/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import BackgroundDark from '../assets/images/1.png';
import BackgroundLight from '../assets/images/2.png';
import { useTheme } from "../contexts/ThemeContext";

const Main: React.FC = () => {
  const { theme } = useTheme();

  return (
    <div className="flex flex-col w-full h-full darken-image">
      <img src={theme === "light" ? BackgroundLight : BackgroundDark} className="w-full h-full" />
    </div>
  );
};

export default Main;
