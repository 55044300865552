import React from 'react';
import type { PopconfirmProps } from 'antd';
import { Button, message, Popconfirm } from 'antd';

interface PopupConfirmProps extends PopconfirmProps {
  title: string;
  description: string;
}

const confirm: PopconfirmProps['onConfirm'] = () => {
  message.success('Click on Yes');
};

const cancel: PopconfirmProps['onCancel'] = () => {
  message.error('Exclusão cancelada!');
};

const PopupConfirm: React.FC<PopupConfirmProps> = ({ title, description, ...restProps }) => (
  <Popconfirm
    title={title}
    description={description}
    onConfirm={confirm}
    onCancel={cancel}
    {...restProps}
  >
    <Button danger type='text'>Deletar</Button>
  </Popconfirm>
);

export default PopupConfirm;
