// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.logo-vertical {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 6%;
    margin-bottom: 1rem;
    color: rgba(255, 255, 255, 0.925);
}

.logo-vertical img {
    width: 15%;
    height: 80%;
}

.logo-vertical h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 90%;
    padding: 0;
    margin: 0;
    font-size: 20px;
    margin-left: 1rem;
    margin-top: 0.8rem;
    font-family: "Montserrat", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/home.css"],"names":[],"mappings":"AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,UAAU;IACV,mBAAmB;IACnB,iCAAiC;AACrC;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,WAAW;IACX,UAAU;IACV,SAAS;IACT,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,qCAAqC;AACzC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');\n\n.home {\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n}\n\n.logo-vertical {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 90%;\n    height: 6%;\n    margin-bottom: 1rem;\n    color: rgba(255, 255, 255, 0.925);\n}\n\n.logo-vertical img {\n    width: 15%;\n    height: 80%;\n}\n\n.logo-vertical h1 {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 60%;\n    height: 90%;\n    padding: 0;\n    margin: 0;\n    font-size: 20px;\n    margin-left: 1rem;\n    margin-top: 0.8rem;\n    font-family: \"Montserrat\", sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
