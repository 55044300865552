/* eslint-disable @typescript-eslint/no-unused-vars */
export const CpfOrCnpjMask = (value: string): string => {
    if (!value) return '';
  
    const cleanValue = value.replace(/\D/g, '');
  
    if (cleanValue.length <= 11) {
      // CPF: 000.000.000-00
      return cleanValue
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      // CNPJ: 00.000.000/0000-00
      return cleanValue
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
    }
};

export const PhoneMask = (value: string): string => {
  if (!value) return '';

  const cleanValue = value.replace(/\D/g, '');

  // (XX) X XXXX-XXXX
  return cleanValue
      .replace(/^(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{1})(\d{4})(\d{4})$/, '$1 $2-$3');
};

