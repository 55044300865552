
import axios, { AxiosError } from "axios";
import { api } from "../api/api";
import { UserInterface } from "../intefaces/UserInterface";

export const createUser = async (user: UserInterface, tId?: number): Promise<UserInterface> => {
  try {
    const response = await api.post(`/new/user/${tId}`, user);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const loginUser = async (email: string, password: string): Promise<UserInterface> => {
  try {
    const response = await api.post(`/login/${email}/${password}`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Usuário não encontrado');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao fazer login do usuário: ' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
};

export const getUser = async (param: string, value: string, tId?: number): Promise<UserInterface[]> => {
  try {
    const response = await api.get(`/find/one/user/${param}/${value}/${tId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Usuário não encontrado');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao buscar usuário: ' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
};

export const getAllUsers = async (tId?: number) => {
  if (tId === undefined) return;
  try {
    const response = await api.get(`/find/all/users/${tId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Usuários não encontrados');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao buscar usuários: ' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
};

export const updateUser = async (user: UserInterface, id: number, tId?: number) => {
  try {
    const response = await api.put(`/update/user/${id}/${tId}`, user);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao atualizar usuário!');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao atualizar usuário: ' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
};

export const updateUserPassword = async (oldPass: string, newPass: string, id?: number, tId?: number) => {
  try {
    const response = await api.put(`/update/password/${id}/${tId}`, { oldPass, newPass });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao atualizar senha!');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao atualizar senha: ' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const response = await api.post(`/forgot/password`, { email });
    if (response.status === 200) {
      return response.data;
    } 
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao recuperar senha: ' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
}

export const resetPassword = async (token: string, email: string, newPassword: string) => {
  try {
    const response = await api.put(`/reset/password`, { token, email, newPassword });
    if (response.status === 200) {
      return response.data;
    } 
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao recuperar senha: ' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
}

export const deleteUser = async (id: number, tId?: number) => {
  try {
    const response = await api.delete(`/delete/user/${id}/${tId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Usuário não encontrado');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao deletar usuário: ' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
};

