import React from "react";

const Expense = () => {

    return (
        <div>
            <h1>Expense</h1>
        </div>
    );
}

export default Expense;