/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { CityInterface } from "../intefaces/CityInterface";
import { getAllCities } from "../service/cityService";

export const useHookCities = () => {
    const [cities, setCities] = useState<CityInterface[]>([]);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const citiesData = await getAllCities();
                setCities(citiesData);
            } catch (error) {
                console.log('Erro ao buscar cidades: ', error)
            }
        };

        fetchCities();
        
    }, []);

    return { cities }
};