/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Asset } from "../intefaces/Asset";
import { deleteAsset, getAllAssets, getAsset } from "../service/assetService";
import { getCompany } from "../service/companyService";

export const useHookAssets = (uid?: number, companyId?: number[], role?: string) => {
    const [assets, setAssets] = useState<Asset[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<Asset>();
    const [searchValue, setSearchValue] = useState('');
    const [searchParam, setSearchParam] = useState<string>('asset_code');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);

    useEffect(() => {
        const fetchAssets = async () => {
            try {
                const assetsData = await getAllAssets(uid, companyId, role);
                setAssets(assetsData);
            } catch (error) {
                console.error('Erro ao buscar ativos:', error);
            }
        };

        fetchAssets();
    }, []);

    const updateAssets = async () => {
        try {
            const assetData = await getAllAssets(uid, companyId, role);
            setAssets(assetData);
        } catch (error) {
            console.error('Erro ao buscar ativos:', error);
        }
    };

    const handleDelete = async (record: number) => {
        try {
            await deleteAsset(record, uid);
            updateAssets();
            message.success('Ativo excluido com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar ativo:', error);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, param: string, value: string) => {
        if (event.key === 'Enter') {
            updateDataTable(param, value);
        } else if (event.key === 'Backspace') {
            handleClearSearch();
        }
    }

    const updateDataTable = async (param: string, value: string) => {
        try {

            let findAsset: React.SetStateAction<Asset[]>;

            if (param === 'asset_code' || param === 'asset_invoice_number') {
                findAsset = await getAsset(param, value, uid);
                //return;
            } else {
                value = '*' + value;
                if (param === "asset_company") {
                    const findCompany = await getCompany("company_name", value, uid);
                    if (findCompany.length <= 0) {
                        message.error("Nenhum ativo encontrado para esta empresa!")
                        return;
                    }
                    value = findCompany[0].company_id.toString();
                }

                findAsset = await getAsset(param, value, uid);

            }

            //findAsset = await getAsset(param, value, uid);
            setSpinning(true);

            setTimeout(() => {
                if (findAsset) {
                    setAssets(findAsset);
                    setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                }

                message.error('Nenhum ativo encontrado!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar ativo:', error);
            message.error('Erro ao buscar ativo');
        }
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleEdit = (record: Asset) => {
        setSelectedAsset(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
    };

    const handleClearSearch = () => {
        updateAssets();
        setIsSearching(false);
    };

    return {
        assets,
        updateAssets,
        handleDelete,
        updateDataTable,
        searchValue,
        isSearching,
        setSearchValue,
        setIsSearching,
        spinning,
        showModal,
        modalVisible,
        handleEdit,
        editMode,
        selectedAsset,
        handleCancel,
        handleClearSearch,
        shouldResetForm,
        setEditMode,
        setShouldResetForm,
        searchParam,
        setSearchParam,
        handleKeyPress
    };

}