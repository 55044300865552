import axios, { AxiosError }  from 'axios';
import { api } from '../api/api';
import { CityInterface } from '../intefaces/CityInterface';

export const createCity = async(city: CityInterface) => {
    try {
        const response = await api.post(`/new/city`, city);
        return response.data;
    } catch (error) {
       throw error; 
    }
};

export const getCity = async (ibge: number) => {
    try {
        const response = await api.get(`/find/one/city/${ibge}`);
        if(response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao buscar a cidade!');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('Erro ao buscar a cidade: ' + (error as AxiosError).message);
            return error.request.status
        } else {
            throw error;
        }
    }
};

export const getAllCities = async () => {
    try {
        const response = await api.get(`/find/all/cities`);
        if(response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao buscar as cidades!');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar as cidades: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
};

export const updateCity = async (citi_id: string, formData: FormData) => {
    try {
        const response = await api.put(`/update/city}/${citi_id}`, formData);
        if(response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao atualizar a cidade!');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao atualizar a cidade: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
};

export const deleteCity = async (city_id: string) => {
    try {
        const response = await api.delete(`/delete/city/${city_id}`);
        if(response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao deletar a cidade!');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao deletar a cidade: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
};