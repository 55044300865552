/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, Spin } from "antd";
import Logo from "../assets/images/Logo-main.png";
import { useHookForgotPass } from "../hooks/useHookForgotPass";
import { CheckCircleFilled } from "@ant-design/icons";
import { Footer } from "antd/es/layout/layout";

export const ForgotPassword: React.FC = () => {
  const { form, onFinish, spinning, tab } = useHookForgotPass();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full overflow-hidden bg-slate-900">
      <Spin spinning={spinning} tip="Validando Email..." fullscreen />

      <h1>
        <img src={Logo} alt="Logo iPatrimonio" className="w-52 mb-5" />
      </h1>

      <div
        title="Recuperar Senha"
        className="flex flex-col items-center justify-center w-1/3 h-2/3 bg-transparent p-4 relative"
      >
        <Form
          form={form}
          onFinish={onFinish}
          className={
            tab === 0
              ? "flex flex-col w-full h-full items-center justify-center bg-slate-300 shadow-lg rounded-md absolute opacity-100 transition-all duration-500 overflow-hidden"
              : "flex flex-col w-0 h-0 items-center justify-center bg-slate-300 shadow-lg rounded-md absolute opacity-0 transition-all duration-500 overflow-hidden"
          }
          style={{ width: "100%", height: "100%" }}
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
        >
          <div className="flex flex-col items-start justify-center w-80 h-full">
            <h1 className="flex flex-col text-3xl text-slate-800 mb-16">
              Recuperação de Senha
              <span className="text-slate-500 text-sm mt-10 pb-3 border-b">
                Para redefinir sua senha, informe seu email para que possamos
                validar seu cadastro, lhe enviaremos um email contendo as
                intruções para alterar sua senha!
              </span>
            </h1>
            <Form.Item
              label="Email"
              name="email"
              className="items-center justify-start w-full"
              rules={[{ required: true, message: "Email é requerido!" }]}
              normalize={(value: string) => value?.toUpperCase()}
            >
              <Input
                placeholder="Digite seu email @"
                className="flex w-full"
                style={{ textTransform: "uppercase" }}
              />
            </Form.Item>
            <Form.Item className="flex items-center justify-center w-full mb-16">
              <Button type="primary" htmlType="submit">
                Recuperar Senha
              </Button>
            </Form.Item>
            {/** Botão de voltar */}
            <div className="flex flex-row items-center justify-center w-full">
              <Button
                type="link"
                className="text-slate-700 text-sm"
                onClick={() => (window.location.href = "/")}
              >
                Voltar para Login
              </Button>
            </div>
          </div>
        </Form>
        <div
          className={
            tab === 1
              ? "flex flex-col w-full h-full items-center justify-center bg-slate-300 shadow-lg rounded-md absolute opacity-100 transition-all duration-500 overflow-hidden"
              : "flex flex-col w-0 h-0 items-center justify-center bg-slate-300 shadow-lg rounded-md absolute opacity-0 transition-all duration-500 overflow-hidden"
          }
        >
          {/**Avisa ao usuário que o link de recuperação foi enviado" */}
          <div className="flex flex-col items-center justify-center w-full h-full">
            <h1 className="flex items-center justify-center w-3/4 flex-col text-2xl text-slate-800 mb-2">
              Link de recuperação enviado!
            </h1>
            <CheckCircleFilled className="text-green-500 text-9xl mt-6 mb-16" />
            <p className="flex items-center justify-center w-2/3 text-center text-slate-700 text-sm">
              Verifique seu email para confirmar a solicitação de recuperação de
              senha
            </p>

            {/** Botão de voltar */}
            <Footer className="w-full text-center bg-transparent p-4 absolute bottom-14 z-50">
              <Button
                type="link"
                className="text-slate-700 text-sm"
                onClick={() => (window.close())}
              >
                Fechar guia
              </Button>
            </Footer>
          </div>
        </div>
      </div>
    </div>
  );
};
