/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message } from "antd";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { forgotPassword, resetPassword } from "../service/userService";

export const useHookForgotPass = () => {
    const [form] = Form.useForm();
    const [formReset] = Form.useForm();
    const [spinning, setSpinning] = useState(false);
    const [tab, setTab] = useState(0);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    /**Metodo onFinish */
    const onFinish = async (values: any) => {
        try {
            setSpinning(true);
            const userInfo = await forgotPassword(values.email);
            if (userInfo.message === 'Sucess') {
                setTab(1);
                setSpinning(false);
                return;
            } else if (userInfo.message === 'Not found') {
                message.error('Falha ao encontrar usuário, ou cadastro inexistente!');
                setSpinning(false);
                return;
            } else if (userInfo.message === 'Error') {
                message.error('Erro enviar link de recuperação!');
                setSpinning(false);
                return;
            }

            message.error('Falha ao encontrar usuário, ou cadastro inexistente!');
            setSpinning(false);

        } catch (error) {
            console.log(error);
            message.error("Erro ao tentar recuperar senha.");
            setSpinning(false);
        }
    }

    const onFinishReset = async (values: any) => {
        try {
            setSpinning(true);
            const userInfo = await resetPassword(token!, email!, values!.password);
            if (userInfo.message === 'Success') {
                message.success('Senha resetada com sucesso!');
                setTab(1);
                setSpinning(false);
                return;
            } else if (userInfo.message === 'Not Found') {
                message.error('Link de recuperação inválido!');
                setSpinning(false);
                return;
            } else if (userInfo.message === 'Expired' || userInfo.message === 'Invalid') {
                message.error('Token expirado ou invalido!');
                setSpinning(false);
                return;
            }

            setSpinning(false);

        } catch (error) {
            console.log(error);
            message.error("Erro ao tentar resetar senha.");
            setSpinning(false);
        }

    }

    return {
        form,
        formReset,
        onFinish,
        onFinishReset,
        spinning,
        tab
    }

}