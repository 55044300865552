import axios, { AxiosError } from 'axios';
import { api } from '../api/api';
import { TypeInterface } from '../intefaces/TypeInterface';

export const createType = async (type: TypeInterface, tId?: number): Promise<TypeInterface> => {
    try {
        const response = await api.post(`/new/type/${tId}`, type);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getType = async(param: string, value: string, tId?: number): Promise<TypeInterface[]> => {
    try {
      const response = await api.get(`/find/one/type/${param}/${value}/${tId}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao buscar tipo');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao buscar tipo '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};

export const getAllTypes = async(tId?: number, companyId?: number[], role?: string) => {
    if(tId === undefined) return null;
    try {
      const response = await api.get(`/find/all/types/${tId}/${companyId!.length > 0 ? companyId : [0]}/${role}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao buscar tipos!');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao buscar tipos '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};

export const updateType = async(type_id: number, type: TypeInterface, tId?: number): Promise<TypeInterface> => {
    try {
      const response = await api.put(`/update/type/${tId}/${type_id}`, type);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao atualizar a tipo');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao atualizar a tipo '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};

export const deleteType = async(type_id: number, tId?: number) => {
    try {
      const response = await api.delete(`/delete/type/${tId}/${type_id}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao deletar tipo');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao deletar tipo '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};
