/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useState } from 'react';
import { Avatar, Dropdown, Menu, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useAuth } from '../contexts/AuthContext';
import FormChangePassword from './FormChangePassword';

interface AvatarMenuProps {
  style?: CSSProperties;
}

const AvatarMenu: React.FC<AvatarMenuProps> = ({ style }) => {
  const { user, logout } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={showModal}>Mudar senha</Menu.Item>
      <Menu.Item key="3" style={{ color: 'red' }} onClick={() => logout()}>Sair</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <Avatar icon={<UserOutlined />} style={style} />
      </Dropdown>
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        width={600}
    >
      <FormChangePassword user={user || undefined} />
    </Modal></>
  );
};

export default AvatarMenu;
