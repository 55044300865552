/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import { useAuth } from "./contexts/AuthContext";
import ProtectedRoute from "./contexts/ProtectedRoutes";
import Admin from "./pages/Admin";
import AdminMain from "./pages/AdminMain";
import Asset from "./pages/Asset";
import Company from "./pages/Company";
import Contract from "./pages/Contract";
import Dashboard from "./pages/Dashboard";
import Expense from "./pages/Expense";
import { ForgotPassword } from "./pages/ForgotPassword";
import Groups from "./pages/Groups";
import Home from "./pages/Home";
import Localization from "./pages/Localization";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Movement from "./pages/Movement";
import PaymentMethods from "./pages/PaymentMethods";
import Persons from "./pages/Persons";
import { ResetPassword } from "./pages/ResetPassword";
import Responsible from "./pages/Responsible";
import Situation from "./pages/Situation";
import Users from "./pages/Users";


function App() {
  const { logado, user } = useAuth();

  const renderRouteBasedOnRole = () => {
    if (logado) {
      if (user?.role === "admin" || user?.role === "user") {
        return <Navigate to="/home/main" />;
      } else if (user?.role === "super") {
        return <Navigate to="/admin" />;
      }
    }

    return <Login />;
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/admin"
            element={<ProtectedRoute element={<Admin />} roles={["super"]} />}
          >
            <Route path="main" element={<AdminMain />} />
            <Route path="/admin/setting" element={<Main />} />
          </Route>

          <Route path="/" element={renderRouteBasedOnRole()} />
          <Route path="/forgot/pass" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="home" element={logado ? <Home /> : <Navigate to="/" />}>
            <Route path="main" element={<Main />} />
            <Route path="company" element={<Company />} />
            <Route path="persons" element={<Persons />} />
            <Route path="responsible" element={<Responsible />} />
            <Route path="order" element={<Movement />} />
            <Route path="paymentMethods" element={<PaymentMethods />} />
            <Route path="expenses" element={<Expense />} />
            <Route path="assets" element={<Asset />} />
            <Route path="localizations" element={<Localization />} />
            <Route path="groups" element={<Groups />} />
            <Route path="situations" element={<Situation />} />
            <Route path="contracts" element={<Contract />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="settings/users" element={<Users />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
