/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import type { TableProps } from "antd";
import { Empty, Table } from "antd";
import React from "react";
import { useTheme } from "../contexts/ThemeContext";

interface TableProprieties {
  data: any[];
  columns: TableProps<any>["columns"];
}

const Tabled: React.FC<TableProprieties> = ({
  columns,
  data,
}: TableProprieties) => {
  const { palette } = useTheme();

  const customStyles = {
    headerStyle: {
      backgroundColor: palette.background,
      color: palette.fontColor,
      border: 'none'
    },
    bodyStyle: {
      backgroundColor: palette.secondary,
      color: palette.fontColor,
      border: 'none'
    },
  };

  return (
    <div className="w-full h-full">
      {data.length === 0 ? (
        <div className="flex justify-center flex-col items-center w-full h-full">
          <Empty description="" />
          <h1 className="text-xl p-3 rounded-sm shadow-md mt-8" style={{ background: palette.fontColor, color: palette.secondary }}>Nenhum dado encontrado.</h1>
          <span className="text-sm mt-4" style={{ color: palette.fontColor }}>Realize cadastro e adicione informações para que seus dados aparecam aqui</span>
        </div>
      ) : (
        <Table
          columns={columns?.map((column) => ({
            ...column,
            onHeaderCell: () => ({
              style: customStyles.headerStyle,
            }),
            onCell: () => ({
              style: customStyles.bodyStyle,
            }),
          }))}
          key={1}
          pagination={{ pageSize: 6 }}
          dataSource={data}
          locale={{ emptyText: <Empty description="Nenhum dado encontrado" /> }}
          className="w-full fixed-header-table overflow-y-scroll border-none"
          //locale={{ emptyText: <Empty description="Sem dados" className="text-xl p-3 rounded-sm shadow-md mt-12" style={{ background: palette.fontColor, color: palette.secondary }} /> }}
          style={{
            backgroundColor: palette.secondary,
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
          }}
        />
      )}
    </div>
  );
};

export default Tabled;
