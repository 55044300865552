/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { SituationInterface } from "../intefaces/SituationInterface";
import { UserInterface } from "../intefaces/UserInterface";
import { createSituation, updateSituation } from "../service/situationService";

export const useHookFormSituations = (
  updateTable: () => void,
  editMode: boolean,
  shouldResetForm: boolean,
  setShouldResetForm: (visible: boolean) => void,
  companies?: CompanyInterface[],
  initialValues?: SituationInterface,
  user?: UserInterface
) => {
  const [form] = Form.useForm<SituationInterface>();
  const [searchValue, setSearchValue] = useState("");
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [spinningText, setSpinningText] = React.useState<string>("");

  const onFinish = async (values: SituationInterface) => {
    try {
      if (editMode && initialValues) {
        setSpinning(true);
        setSpinningText("Atualizando...");
        if (typeof values.situation_company === "string" && companies) {
          let company = companies.filter(
            (company) =>
              company.company_name === form.getFieldValue("situation_company")
          );
          if (company && company.length > 0) {
            values.situation_company = company[0];
          }
        } else if (typeof values.situation_company === "number" && companies) {
          let company = companies.filter(
            (company) =>
              company.company_id === form.getFieldValue("situation_company")
          );
          if (company && company.length > 0) {
            values.situation_company = company[0];
          }
        }

        await updateSituation(
          initialValues.situation_id,
          values,
          user?.tenant.id
        );
        message.success("Situação atualizada com sucesso");
        form.resetFields();
        updateTable();
        setSpinning(false);
        setSpinningText("");
      } else {
        setSpinning(true);
        setSpinningText("Criando...");
        if (values.situation_company && companies) {
          let company = companies.filter(
            (company) =>
              company.company_id === form.getFieldValue("situation_company")
          );
          if (company && company.length > 0) {
            values.situation_company = company[0];
          }
        }

        values.situation_code = 0;

        await createSituation(values, user?.tenant.id);
        message.success("Situação criada com sucesso");
        form.resetFields();
        updateTable();
        setSpinning(false);
        setSpinningText("");
      }
    } catch (error) {
      console.error("Erro ao criar situação:", error);
      message.error("Erro ao criar situação");
      setSpinning(false);
      setSpinningText("");
    }
  };

  useEffect(() => {
    if (editMode && initialValues) {
      form.setFieldsValue(initialValues);
      form.setFieldValue(
        "situation_company",
        initialValues.situation_company.company_name
      );
    } else {
      form.resetFields();
    }
  }, [editMode, initialValues]);

  useEffect(() => {
    if (shouldResetForm) {
      form.resetFields();
      setShouldResetForm(false);
      setSearchValue("");
    }
  }, [shouldResetForm]);

  return {
    form,
    onFinish,
    spinning,
    spinningText,
  };
};
