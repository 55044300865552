/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message } from "antd";
import React, { useEffect } from "react";
import { CityInterface } from "../intefaces/CityInterface";
import { PersonInterface } from "../intefaces/PersonInterface";
import { UserInterface } from "../intefaces/UserInterface";
import { createPerson, updatePerson } from "../service/personService";
import { CpfOrCnpjMask, PhoneMask } from "../utils/Masks";
import { searchPostalCode } from "../utils/UseCases";

export const useHookFormPerson = (
  updateTable: () => void,
  editMode: boolean,
  shouldResetForm: boolean,
  setShouldResetForm: (visible: boolean) => void,
  cities?: CityInterface[] | null,
  initialValues?: PersonInterface,
  user?: UserInterface
) => {
  const [form] = Form.useForm<PersonInterface>();
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [spinningText, setSpinningText] = React.useState<string>("");

  const onFinish = async (values: PersonInterface) => {
    try {
      if (editMode && initialValues) {
        setSpinning(true);
        setSpinningText("Atualizando...");
        if (typeof values.person_city === "string" && cities) {
          let city = cities.filter(
            (city) =>
              city.city_description.toLowerCase() ===
              form.getFieldValue("person_city").toLowerCase()
          );
          if (city && city.length > 0) {
            values.person_city = city[0];
          }
        } else if (typeof values.person_city === "number" && cities) {
          let city = cities.filter(
            (city) => city.city_id === form.getFieldValue("person_city")
          );
          if (city && city.length > 0) {
            values.person_city = city[0];
          }
        }

        await updatePerson(initialValues.person_id, values, user?.tenant.id);
        message.success("Cliente atualizado com sucesso");
        form.resetFields();
        updateTable();
        setSpinning(false);
        setSpinningText("");
      } else {
        setSpinning(true);
        setSpinningText("Criando...");
        if (values.person_city && cities) {
          if (typeof values.person_city === "string" && cities) {
            let city = cities.filter(
              (city) =>
                city.city_description.toLowerCase() ===
                values.person_city.toString().toLowerCase()
            );
            if (city && city.length > 0) {
              values.person_city = city[0];
            }
          }
        }

        values.person_code = 0;

        await createPerson(values, user?.tenant.id);
        message.success("Cliente criado com sucesso");
        form.resetFields();
        updateTable();
        setSpinning(false);
        setSpinningText("");
      }
    } catch (error) {
      console.error("Erro ao criar cliente:", error);
      message.error("Erro ao criar cliente");
      setSpinning(false);
      setSpinningText("");
    }
  };

  useEffect(() => {
    if (editMode && initialValues) {
      form.setFieldsValue(initialValues);
      form.setFieldValue(
        "person_city",
        initialValues.person_city?.city_description
      );
    } else {
      form.resetFields();
    }
  }, [editMode, initialValues]);

  useEffect(() => {
    if (shouldResetForm) {
      form.resetFields();
      setShouldResetForm(false);
    }
  }, [shouldResetForm]);

  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const maskedValue = CpfOrCnpjMask(value);
    form.setFieldValue("person_cpfOrCnpj", maskedValue);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const maskedValue = PhoneMask(value);
    form.setFieldValue("person_phone", maskedValue);
  };

  const handleSearchPostalCode = async (postalCode: string) => {
    try {
      if (postalCode.trim() === "") {
        form.setFieldValue("person_cep", "");
        form.setFieldValue("person_city", "");
        form.setFieldValue("person_neighborhood", "");
        form.setFieldValue("person_address", "");
        return;
      }

      const addressData = await searchPostalCode(postalCode);

      form.setFieldValue("person_cep", addressData.cep);
      form.setFieldValue("person_city", addressData.localidade.toUpperCase());
      form.setFieldValue(
        "person_neighborhood",
        addressData.bairro.toUpperCase()
      );
      form.setFieldValue(
        "person_address",
        addressData.logradouro.toUpperCase()
      );
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    onFinish,
    handleCpfChange,
    handlePhoneChange,
    handleSearchPostalCode,
    spinning,
    spinningText,
  };
};
