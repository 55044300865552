/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PopupConfirm from "../components/PopupConfirm";
import { Asset } from "../intefaces/Asset";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { ContractInterface } from "../intefaces/ContractInterface";
import { MovementDataInterface } from "../intefaces/MovementDataInterface";
import { MovementInterface } from "../intefaces/MovementInterface";
import { PersonInterface } from "../intefaces/PersonInterface";
import { ResponsibleInterface } from "../intefaces/ResponsibleInterface";
import { UserInterface } from "../intefaces/UserInterface";
import {
  createMovement,
  deleteMovemenData,
  updateMovement,
} from "../service/movementService";

export const useHookFormMovement = (
  clients: PersonInterface[],
  updateTable: () => void,
  editMode: boolean,
  shouldResetForm: boolean,
  initialValues?: MovementInterface | null,
  assetsData?: Asset[],
  contracts?: ContractInterface[],
  companies?: CompanyInterface[],
  responsible?: ResponsibleInterface[],
  user?: UserInterface
) => {
  const [form] = Form.useForm<MovementInterface>();
  const [searchValue, setSearchValue] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState<
    PersonInterface | undefined
  >();
  const [items, setItems] = useState<MovementDataInterface[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>();
  const [selectedContract, setSelectedContract] = useState<
    ContractInterface | undefined
  >();
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [spinningText, setSpinningText] = React.useState<string>("");

  const onFinish = async (values: MovementInterface) => {
    try {
      if (editMode && initialValues) {
        setSpinning(true);
        setSpinningText("Atualizando...");
        if (values.company_id && companies) {
          let companyData = companies.filter((company) =>
            typeof values.company_id === "string"
              ? company.company_name === form.getFieldValue("company_id")
              : company.company_id === form.getFieldValue("company_id")
          );
          if (companyData && companyData.length > 0) {
            values.company_id = companyData[0];
          }
        }

        if (values.contract_id && contracts) {
          let contractData = contracts.filter((contract) =>
            typeof values.contract_id === "string"
              ? contract.constract_description ===
                form.getFieldValue("contract_id")
              : contract.contract_id === form.getFieldValue("contract_id")
          );
          if (contractData && contractData.length > 0) {
            values.contract_id = contractData[0];
          }
        }

        if (values.person_id && clients) {
          let clientData = clients.filter((client) =>
            typeof values.person_id === "string"
              ? client.person_name === form.getFieldValue("person_id")
              : client.person_id === form.getFieldValue("person_id")
          );
          if (clientData && clientData.length > 0) {
            values.person_id = clientData[0];
          }
        }

        if (values.responsible_id && responsible) {
          let responsibleData = responsible.filter((responsible) =>
            typeof values.responsible_id === "string"
              ? responsible.responsible_name ===
                form.getFieldValue("responsible_id")
              : responsible.responsible_id ===
                form.getFieldValue("responsible_id")
          );
          if (responsibleData && responsibleData.length > 0) {
            values.responsible_id = responsibleData[0];
          }
        }

        const movement: MovementInterface = {
          create_at: initialValues.create_at,
          contract_id: values.contract_id,
          status: "AB",
          person_id: values.person_id,
          company_id: values.company_id,
          responsible_id: values.responsible_id,
          data: [...items],
          tenant: user?.tenant,
        };

        if (initialValues.id) {
          await updateMovement(initialValues.id, movement, user?.tenant.id);
          form.resetFields();
          message.success("Movimentação atualizada com sucesso");
          updateTable();
          setItems([]);
          setSpinning(false);
          setSpinningText("");
        }
      } else {
        setSpinning(true);
        setSpinningText("Criando...");
        if (values.company_id && companies) {
          let companyData = companies.filter(
            (company) => company.company_id === form.getFieldValue("company_id")
          );
          if (companyData && companyData.length > 0) {
            values.company_id = companyData[0];
          }
        }

        if (values.contract_id && contracts) {
          let contractData = contracts.filter(
            (contract) =>
              contract.contract_id === form.getFieldValue("contract_id")
          );
          if (contractData && contractData.length > 0) {
            values.contract_id = contractData[0];
          }
        }

        if (values.person_id && clients) {
          let clientData = clients.filter(
            (client) => client.person_id === form.getFieldValue("person_id")
          );
          if (clientData && clientData.length > 0) {
            values.person_id = clientData[0];
          }
        }

        if (values.responsible_id && responsible) {
          let responsibleData = responsible.filter(
            (responsible) =>
              responsible.responsible_id ===
              form.getFieldValue("responsible_id")
          );
          if (responsibleData && responsibleData.length > 0) {
            values.responsible_id = responsibleData[0];
          }
        }

        const movement: MovementInterface = {
          code: 0,
          create_at: new Date(),
          contract_id: values.contract_id,
          status: "AB",
          person_id: values.person_id,
          company_id: values.company_id,
          responsible_id: values.responsible_id,
          data: [...items],
        };

        await createMovement(movement, user?.tenant.id);
        form.resetFields();
        message.success("Movimentação criada com sucesso!");
        updateTable();
        setItems([]);
        setSpinning(false);
        setSpinningText("");
      }
    } catch (error) {
      console.error("Erro ao criar movimentação:", error);
      message.error("Erro ao criar movimentação");
      setSpinning(false);
      setSpinningText("");
    }
  };

  const handleFinished = () => {
    onFinish(form.getFieldsValue());
  };

  const handleSupplierSelect = (value: string) => {
    const selectedSupplier = clients?.find(
      (client) => client.person_id === parseInt(value, 10)
    );
    setSelectedSupplier(selectedSupplier);
    if (selectedSupplier) {
      form.setFieldValue("person_address", selectedSupplier?.person_address);
      form.setFieldValue("person_email", selectedSupplier?.person_email);
      form.setFieldValue(
        "person_cpfOrCnpj",
        selectedSupplier?.person_cpfOrCnpj
      );
      form.setFieldValue("person_phone", selectedSupplier?.person_phone);
      form.setFieldValue(
        "person_city",
        selectedSupplier.person_city?.city_description
      );
      form.setFieldValue(
        "person_state",
        selectedSupplier.person_city?.city_state
      );
    }
  };

  const convertDateFields = (data: any) => {
    const newData = { ...data };
    const dateFields = ["create_at"];

    dateFields.forEach((field) => {
      if (newData[field]) {
        newData[field] = moment(newData[field]);
      }
    });

    return newData;
  };

  const handleAssetSelect = (value: string) => {
    const selectedAsset = assetsData?.find(
      (asset) => asset.asset_id === parseInt(value, 10)
    );
    setSelectedAsset(selectedAsset);
  };

  const handleContractSelect = (value: string) => {
    const selectedContract = contracts?.find(
      (contract) => contract.contract_id === parseInt(value, 10)
    );
    setSelectedContract(selectedContract);
    if (selectedContract) {
      form.setFieldValue(
        "contract_term",
        getTypeContractTerm(selectedContract.contract_time_type)
      );
    }
  };

  const getTypeContractTerm = (term: string) => {
    if (term.includes("HO")) {
      return "Hora(s)";
    } else if (term.includes("DI")) {
      return "Dia(s)";
    } else if (term.includes("SE")) {
      return "Semana(s)";
    } else if (term.includes("ME")) {
      return "Mês(es)";
    } else if (term.includes("AN")) {
      return "Ano(s)";
    } else {
      return "Sem prazo";
    }
  };

  const handleInitialAddItem = (movement_data: MovementDataInterface[]) => {
    setItems(movement_data);
  };

  const handleAddItem = () => {
    if (selectedAsset && selectedContract) {
      const movement: MovementInterface = {
        create_at: new Date(),
        contract_id: form.getFieldValue("contract_id"),
        status: "AB",
        person_id: form.getFieldValue("person_id"),
        company_id: form.getFieldValue("company_id"),
        responsible_id: form.getFieldValue("responsible_id"),
        data: form.getFieldValue("data"),
      };

      const newItem: MovementDataInterface = {
        id: items.length + 1,
        asset_id: selectedAsset,
        time: form.getFieldValue("time"),
        quantity: form.getFieldValue("quantity"),
        value: selectedContract.contract_value,
        total:
          form.getFieldValue("quantity") *
          form.getFieldValue("time") *
          selectedContract.contract_value,
        movement_id: movement,
      };
      setItems([...items, newItem]);
      form.resetFields(["asset_id", "time", "quantity"]);
    }
  };

  const handleDelete = async (record: number) => {
    try {
      await deleteMovemenData(record);
      message.success("Item excluido com sucesso!");
    } catch (error) {
      console.error("Erro ao deletar item: ", error);
    }
  };

  const handleRemoveItem = (record: number) => {
    if (initialValues) {
      handleInitialAddItem(initialValues.data);
      handleDelete(record);
      updateTable();

      const updatedItems = items.filter(
        (item: MovementDataInterface) => item.id !== record
      );
      setItems(updatedItems);
    } else {
      const updatedItems = items.filter(
        (item: MovementDataInterface) => item.id !== record
      );
      setItems(updatedItems);
    }
  };

  const columns: ColumnsType<MovementDataInterface> = [
    {
      title: "Ativo",
      dataIndex: "asset_id",
      key: "asset_id",
      render: (asset: Asset) => asset.asset_description,
      width: 200,
    },
    {
      title: "Tempo",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Quant.",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Valor Unit.",
      dataIndex: "value",
      key: "value",
      render: (value) =>
        value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total) =>
        total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
    },
    {
      title: "Ação",
      key: "action",
      render: (record: MovementDataInterface) => (
        <Space size="middle">
          <PopupConfirm
            title="Tem certeza que deseja excluir este item?"
            description="Esta ação não pode ser desfeita."
            onConfirm={() => handleRemoveItem(record.id)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (editMode && initialValues) {
      const initialValuesWithDate = convertDateFields(initialValues);
      form.setFieldsValue(initialValuesWithDate);

      form.setFieldValue("person_id", initialValues.person_id?.person_name);
      form.setFieldValue("company_id", initialValues.company_id?.company_name);
      form.setFieldValue(
        "responsible_id",
        initialValues.responsible_id?.responsible_name
      );
      form.setFieldValue(
        "contract_id",
        initialValues.contract_id?.constract_description
      );

      handleSupplierSelect(initialValues.person_id?.person_id.toString());
      handleInitialAddItem(initialValues.data);
    } else {
      form.resetFields();
    }
  }, [editMode, initialValues]);

  useEffect(() => {
    if (shouldResetForm) {
      form.resetFields();
      handleInitialAddItem([]);
      setItems([]);
      setSearchValue("");
    }
  }, [shouldResetForm]);

  return {
    form,
    selectedSupplier,
    handleSupplierSelect,
    items,
    setItems,
    selectedAsset,
    setSelectedAsset,
    setSelectedSupplier,
    searchValue,
    setSearchValue,
    convertDateFields,
    onFinish,
    handleAssetSelect,
    handleAddItem,
    assetsData,
    columns,
    handleFinished,
    handleInitialAddItem,
    handleContractSelect,
    spinning,
    spinningText,
  };
};
