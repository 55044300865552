/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message, Modal } from "antd";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { CityInterface } from "../intefaces/CityInterface";
import { TenantInterface } from "../intefaces/TenantInterface";
import { createTenant, updateTenant } from "../service/tenantService";
import { CpfOrCnpjMask } from "../utils/Masks";
import { searchPostalCode } from "../utils/UseCases";

export const useHookFormTenant = (
  updateTable: () => void,
  editMode: boolean,
  shouldResetForm: boolean,
  setShouldResetForm: (visible: boolean) => void,
  initialValues?: TenantInterface,
  cities?: CityInterface[]
) => {
  const [form] = Form.useForm<TenantInterface>();
  const [searchValue, setSearchValue] = useState("");
  const [spinning, setSpinning] = useState(false);
  const [spinningText, setSpinningText] = useState("");
  const [selectedValue, setSelectedValue] = useState("admin");

  const onFinish = async (values: TenantInterface) => {
    try {
      if (editMode && initialValues) {
        setSpinning(true);
        setSpinningText("Atualizando...");
        if (typeof values.city === "string" && cities) {
          let city = cities.filter(
            (city) =>
              city.city_description.toLowerCase() ===
              values.city.toString().toLowerCase()
          );
          if (city && city.length > 0) {
            values.city = city[0];
          }
        }

        await updateTenant(values, values.id);
        message.success("Tenants atualizado com sucesso!");
        form.resetFields();
        setSpinning(false);
        setSpinningText("");
        updateTable();
      } else {
        setSpinning(true);
        setSpinningText("Criando...");
        if (typeof values.city === "string" && cities) {
          let city = cities.filter(
            (city) =>
              city.city_description.toLowerCase() ===
              values.city.toString().toLowerCase()
          );

          if (city && city.length > 0) {
            values.city = city[0];
          }
        }

        const create = await createTenant(values);
        if (create === 200) {
          message.success("Tenants criado com sucesso!");
          form.resetFields();
          setSpinning(false);
          setSpinningText("");
          updateTable();
        }
      }
    } catch (error) {
      const errorMessage = error as AxiosError;
      console.error(
        "Erro ao criar tenant:",
        (errorMessage.response?.data as { message: string }).message
      );
      message.error(
        (errorMessage.response?.data as { message: string }).message
      );
      setSpinning(false);
      setSpinningText("");
    }
  };

  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const maskedValue = CpfOrCnpjMask(value);
    form.setFieldValue("cpf", maskedValue);
  };

  const handleSearchPostalCode = async (postalCode: string) => {
    try {
      if (postalCode.trim() === "") {
        form.setFieldValue("neighborhood", "");
        form.setFieldValue("adress", "");
        return;
      }
      const addressData = await searchPostalCode(postalCode);
      form.setFieldValue("cep", addressData.cep);
      form.setFieldValue("city", addressData.localidade.toUpperCase());
      form.setFieldValue("neighborhood", addressData.bairro);
      form.setFieldValue("adress", addressData.logradouro);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (value: string) => {
    if (value === "super") {
      Modal.confirm({
        title: "Confirmação",
        content: "Você realmente deseja adicionar um Super?",
        centered: true,
        onOk() {
          form.setFieldValue("type", value);
        },
        onCancel() {
          form.setFieldValue("type", "admin");
        },
      });
    } else {
      setSelectedValue(value);
    }
  };

  useEffect(() => {
    if (editMode && initialValues) {
      form.setFieldsValue(initialValues);
      form.setFieldValue("city", initialValues.city?.city_description);
    } else {
      form.resetFields();
    }
  }, [editMode, initialValues]);

  useEffect(() => {
    if (shouldResetForm) {
      form.resetFields();
      setShouldResetForm(false);
      setSearchValue("");
    }
  }, [shouldResetForm]);

  return {
    form,
    spinning,
    onFinish,
    handleCpfChange,
    handleSearchPostalCode,
    spinningText,
    handleChange,
    selectedValue
  };
};
