/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { Form, Input, Select, Button, InputNumber, Tabs, Table, Spin } from "antd";
import { Asset } from "../intefaces/Asset";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { ContractInterface } from "../intefaces/ContractInterface";
import { PersonInterface } from "../intefaces/PersonInterface";
import { MovementInterface } from "../intefaces/MovementInterface";
import { ResponsibleInterface } from "../intefaces/ResponsibleInterface";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useHookFormMovement } from "../hooksForm/useHookFormMovement";
import { UserInterface } from "../intefaces/UserInterface";

const { Option } = Select;
const { TabPane } = Tabs;

interface FormMovementProps {
  updateTable: () => void;
  editMode: boolean;
  initialValues?: MovementInterface;
  modalVisible: boolean;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  setEditMode: (visible: boolean) => void;
  suppliers: PersonInterface[];
  contracts?: ContractInterface[];
  companies?: CompanyInterface[];
  responsible?: ResponsibleInterface[];
  assets?: Asset[];
  user?: UserInterface;
}

const FormMovement: React.FC<FormMovementProps> = ({
  updateTable,
  editMode,
  initialValues,
  shouldResetForm,
  suppliers,
  contracts,
  companies,
  responsible,
  assets,
  user,
}) => {
  const {
    form,
    handleSupplierSelect,
    items,
    onFinish,
    handleAssetSelect,
    handleAddItem,
    columns,
    handleContractSelect,
    spinning,
    spinningText,
  } = useHookFormMovement(
    suppliers,
    updateTable,
    editMode,
    shouldResetForm,
    initialValues,
    assets,
    contracts,
    companies,
    responsible,
    user
  );

  return (
    <div className="flex flex-row justify-between w-full h-1/2 mb-5">
      <Spin spinning={spinning} tip={spinningText} fullscreen />
      <div className="justify-start w-80">
        <h1 className="text-2xl flex flex-col">
          Detalhes do Patrimonio
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <Tabs defaultActiveKey="1" className="p-1">
          <TabPane tab="Informações do Pedido" key="1">
            <div className="flex flex-row w-full">
              <Form.Item name="code" label="ID" className="w-1/1 mr-3">
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="person_id"
                label="Cliente"
                className="w-1/2 mr-3"
                rules={[
                  { required: true, message: "Por favor insira do cliente!" },
                ]}
              >
                <Select
                  showSearch
                  onChange={handleSupplierSelect}
                  filterOption={(input, option) => {
                    if (!option) return true; // se as opções forem nulas é renderizdo todos os clientes
                    if (!option.label) return false; // se não existir não é retornado nada
                    return option.label
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase()); // caso exista e retornado o cliente
                  }}
                  style={{ textTransform: "uppercase" }}
                  placeholder="Selecione um cliente"
                >
                  {suppliers &&
                    suppliers.filter((supplier) => supplier.person_type === 'client').map((supplier) => (
                      <Option
                        key={supplier.person_id}
                        value={supplier.person_id}
                        label={supplier.person_name}
                        style={{ textTransform: "uppercase" }}
                      >
                        {supplier.person_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="status" label="Situação" className="w-1/4">
                <Select defaultValue={"AB"} disabled>
                  <Option value="AB">Aberto</Option>
                  <Option value="I">Inativo</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="flex flex-row">
              <Form.Item
                name="person_cpfOrCnpj"
                label="CPF/CNPJ"
                className="w-1/4 mr-3"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="person_phone"
                label="Celular"
                className="w-1/4 mr-3"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item name="person_email" label="Email" className="w-1/2">
                <Input disabled />
              </Form.Item>
            </div>
            <div className="flex flex-row">
              <Form.Item
                name="person_address"
                label="Endereço"
                className="w-1/2 mr-3"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="person_city"
                label="Cidade"
                className="w-1/3 mr-3"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item name="person_state" label="UF" className="w-1/5">
                <Input disabled />
              </Form.Item>
            </div>
            <div className="flex flex-row">
              <Form.Item
                name="company_id"
                label="Empresa"
                className="w-1/2 mr-3"
                rules={[
                  { required: true, message: "Por favor informe a empresa!" },
                ]}
              >
                <Select style={{ textTransform: "uppercase" }}>
                  {companies &&
                    companies.map((company) => (
                      <Option
                        key={company.company_id}
                        value={company.company_id}
                        style={{ textTransform: "uppercase" }}
                      >
                        {company.company_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="responsible_id"
                label="Responsavel"
                className="w-1/4 mr-3"
                rules={[
                  {
                    required: true,
                    message: "Por favor insira o responsavel!",
                  },
                ]}
              >
                <Select style={{ textTransform: "uppercase" }}>
                  {responsible &&
                    responsible.map((responsible) => (
                      <Option
                        value={responsible.responsible_id}
                        style={{ textTransform: "uppercase" }}
                      >
                        {responsible.responsible_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="contract_id"
                label="Contrato"
                className="w-1/4"
                style={{ textTransform: "uppercase" }}
              >
                <Select onChange={handleContractSelect}>
                  {contracts &&
                    contracts.map((contract) => (
                      <Option
                        value={contract.contract_id}
                        style={{ textTransform: "uppercase" }}
                      >
                        {contract.constract_description}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="flex flex-row justify-end w-full">
              <Button
                type="primary"
                className="w-1/5"
                color="black"
                htmlType="submit"
                style={{ backgroundColor: "green" }}
              >
                Salvar
              </Button>
            </div>
          </TabPane>
          <TabPane tab="Itens do pedido" key="2">
            <div className="flex flex-row">
              <Form.Item
                name="asset_id"
                label="Ativo/Equip."
                className="w-1/2 mr-3"
              >
                <Select
                  showSearch
                  onChange={handleAssetSelect}
                  filterOption={(input, option) => {
                    if (!option) return true;
                    if (!option.label) return false;
                    return option.label
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  style={{ textTransform: "uppercase" }}
                  placeholder="Selecione um ativo"
                >
                  {assets &&
                    assets.map((asset) => (
                      <Option
                        key={asset.asset_id}
                        value={asset.asset_id}
                        label={asset.asset_description}
                        style={{ textTransform: "uppercase" }}
                      >
                        {asset.asset_description}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="contract_term" label="Termo Contrato" className="w-1/6 mr-3">
                <Input disabled/>
              </Form.Item>
              <Form.Item name="time" label="Tempo" className="w-1/8 mr-3">
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="quantity"
                label="Quantidade"
                className="w-1/8 mr-3"
              >
                <InputNumber />
              </Form.Item>
              <Button
                className="flex items-center justify-center"
                style={{
                  marginTop: "1.85rem",
                  background: "green",
                  color: "white",
                }}
                onClick={handleAddItem}
              >
                <PlusCircleOutlined />
              </Button>
            </div>
            <div className="flex flex-row w-full">
              <Table
                className="w-full"
                dataSource={items}
                columns={columns}
                scroll={{ y: 240 }}
                sticky
              />
            </div>
          </TabPane>
        </Tabs>

        {/*<div className="flex flex-row justify-end">
                        <Button 
                            type="primary" 
                            className="w-1/5" 
                            color="black"
                            htmlType="submit"
                            style={{ backgroundColor: 'green'}}
                        >
                            Salvar
                        </Button>
                    </div>*/}
      </Form>
    </div>
  );
};

export default FormMovement;
