/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Form, Input, Button, Select, Spin } from "antd";
import { TypeInterface } from "../intefaces/TypeInterface";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { useHookFormTypes } from "../hooksForm/useHookFormTypes";
import { UserInterface } from "../intefaces/UserInterface";

const { Option } = Select;

interface FormGroupProps {
  updateTable: () => void;
  editMode: boolean;
  initialValues?: TypeInterface;
  modalVisible: boolean;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  setEditMode: (visible: boolean) => void;
  companies?: CompanyInterface[];
  user?: UserInterface;
  orientarion?: string;
}

const FormGroup: React.FC<FormGroupProps> = ({
  updateTable,
  editMode,
  initialValues,
  shouldResetForm,
  setShouldResetForm,
  companies,
  user,
  orientarion,
}) => {
  const { form, onFinish, spinning, spinningText } = useHookFormTypes(
    updateTable,
    editMode,
    shouldResetForm,
    setShouldResetForm,
    companies,
    initialValues,
    user
  );

  return (
    <div
      className={
        orientarion === "vertical"
          ? "flex flex-col justify-between w-full h-1/2 mb-5"
          : "flex flex-row justify-between w-full h-1/2 mb-5"
      }
    >
      <Spin spinning={spinning} tip={spinningText} fullscreen />
      <div
        className={
          orientarion === "vertical"
            ? "justify-start w-80 mb-10"
            : "justify-start w-80"
        }
      >
        <h1 className="text-2xl flex flex-col">
          Detalhes do Tipo/Grupo
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <div
          className={
            orientarion === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="type_code"
            label="ID"
            className={orientarion === "vertical" ? "w-40 mr-3" : "w-1/1 mr-3"}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="type_description"
            label="Descrição"
            className={
              orientarion === "vertical" ? "w-full mr-3" : "w-1/2 mr-3"
            }
            rules={[
              { required: true, message: "Insira uma descrição!" },
            ]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="type_company"
            label="Empresa"
            className={orientarion === "vertical" ? "w-full" : "w-1/4"}
            rules={[
              { required: true, message: "Selecione uma empresa!" },
            ]}
          >
            <Select style={{ textTransform: "uppercase" }}>
              {companies &&
                companies.map((company) => (
                  <Option
                    key={company.company_id}
                    value={company.company_id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {company.company_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div
          className={
            orientarion === "vertical"
              ? "flex flex-row justify-start"
              : "flex flex-row justify-end"
          }
        >
          <Button
            type="default"
            className="w-1/5"
            htmlType="submit"
            style={{ backgroundColor: "green", color: "white" }}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormGroup;
