/* eslint-disable @typescript-eslint/no-unused-vars */
import { SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Layout, Modal, Select, Spin } from "antd";
import React from "react";
import FormContract from "../components/FormContract";
import Tabled from "../components/Table";
import { useAuth } from "../contexts/AuthContext";
import { useTheme } from "../contexts/ThemeContext";
import { useHookContracts } from "../hooks/useHookContracts";
import { useHooksCompanies } from "../hooks/useHooksCompanies";
import { columnsContract } from "../utils/ColumnsUtils";

const { Content } = Layout;
const { Option } = Select;

const Contract: React.FC = () => {
  const { user } = useAuth();

  const {
    contracts,
    updateContracts,
    showModal,
    modalVisible,
    handleEdit,
    editMode,
    handleCancel,
    handleDelete,
    selectedContract,
    shouldResetForm,
    setShouldResetForm,
    setEditMode,
    searchValue,
    setSearchValue,
    isSearching,
    handleClearSearch,
    updateDataTable,
    spinning,
    searchParam,
    setSearchParam,
    handleKeyPress
  } = useHookContracts(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );
  const { companies } = useHooksCompanies(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );
  const { theme, palette } = useTheme();

  const tableColumns = columnsContract(handleEdit, handleDelete);

  return (
    <div className="flex flex-col w-full h-full">
      <Spin spinning={spinning} tip="Buscando..." fullscreen />
      <Divider orientation="left" style={{ borderColor: palette.fontColor }}>
        <h1 className="text-3xl mb-1" style={{ color: palette.fontColor }}>
          Contratos
        </h1>
      </Divider>
      <div className="flex justify-end w-full h-20">
        <Button type="primary" className="mb-4" onClick={showModal}>
          Adicionar Contrato
        </Button>
      </div>
      <div className="flex flex-row justify-start mb-5">
        <Select
          defaultValue="contract_code"
          className="mr-2 w-1/5 bg-slate-100 font-bold"
          style={{ background: palette.secondary }}
          onChange={(value) => setSearchParam(value)}
        >
          <Option value="contract_code">ID</Option>
          <Option value="constract_description">Descrição</Option>
          <Option value="contract_time_type">Tempo</Option>
        </Select>
        <Input
          placeholder="Pesquisar"
          className={
            theme === "light"
              ? `w-full mr-2 font-bold placeholder:text-gray-500`
              : "mr-2 font-bold placeholder:text-gray-300 border-transparent"
          }
          value={searchValue}
          style={{ background: palette.secondary, color: palette.fontColor, textTransform: 'uppercase' }}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e, searchParam, searchValue.toUpperCase())}
        />
        <Button
          type="primary"
          className=" mr-2"
          icon={<SearchOutlined />}
          onClick={() => updateDataTable(searchParam, searchValue.toUpperCase())}
        >
          Pesquisar
        </Button>
        {isSearching ? (
          <Button
            type="primary"
            className="mr-2"
            danger
            onClick={() => {
              handleClearSearch();
            }}
          >
            Limpar
          </Button>
        ) : null}
      </div>
      <Modal
        open={modalVisible}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        width={1200}
        maskClosable={false}
        className="overflow-hidden"
      >
        <Divider orientation="left">
          <h1 className="text-2xl">
            {editMode ? "Editar Contratos" : "Cadastrar Contratos"}
          </h1>
        </Divider>
        <div className="p-4">
          <FormContract
            updateTable={updateContracts}
            editMode={editMode}
            initialValues={selectedContract}
            modalVisible={modalVisible}
            shouldResetForm={shouldResetForm}
            setShouldResetForm={setShouldResetForm}
            setEditMode={setEditMode}
            companies={companies}
            user={user || undefined}
          />
        </div>
      </Modal>
      <Layout style={{ background: palette.secondary }}>
        <Content className="overflow-auto">
          <Tabled columns={tableColumns} data={contracts} />
        </Content>
      </Layout>
    </div>
  );
};

export default Contract;
