import axios, { AxiosError } from 'axios';
import { api } from '../api/api';
import { Asset } from '../intefaces/Asset';


export const createAsset = async (asset: Asset, tId?: number): Promise<Asset> => {
    try {
        const response = await api.post(`/new/asset/${tId}`, asset);
        console.log(response);
        return response.data;
    } catch (error) {
       throw error; 
    }
};

export const getAsset = async (param: string, value: string, tId?: number): Promise<Asset[]> => {
    try {
        const response = await api.get(`/find/one/asset/${param}/${value}/${tId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Ativo não encontrado');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar ativo: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
};

export const getAllAssets = async (tId?: number, companyId?: number[], role?: string) => {
    if(tId === undefined) return [];
    try {
        const response = await api.get(`/find/all/assets/${tId}/${companyId!.length > 0 ? companyId : [0]}/${role}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Ativos não encontrados');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar ativos: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
};

export const updateAsset = async (asset_id: number, asset: Asset, tId?: number): Promise<Asset> => {
    try {
        const response = await api.put(`/update/asset/${tId}/${asset_id}`, asset);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Ativo não encontrado');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao atualizar ativo: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
};

export const deleteAsset = async (asset_id: number, tId?: number) => {
    try {
        const response = await api.delete(`/delete/asset/${tId}/${asset_id}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Ativo não encontrado');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao deletar ativo: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
};