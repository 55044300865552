import axios, { AxiosError } from 'axios';
import { api } from '../api/api';
import { SituationInterface } from '../intefaces/SituationInterface';

export const createSituation = async (situation: SituationInterface, tId?: number): Promise<SituationInterface> => {
    try {
        const response = await api.post(`/new/situation/${tId}`, situation);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getSituation = async(param: string, value: string, tId?: number): Promise<SituationInterface[]> => {
    try {
      const response = await api.get(`/find/one/situation/${param}/${value}/${tId}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao buscar situação');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao buscar situação '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};

export const getAllSituation = async(tId?: number, companyId?: number[], role?: string) => {
    if(tId === undefined) return null;
    try {
      const response = await api.get(`/find/all/situations/${tId}/${companyId!.length > 0 ? companyId : [0]}/${role}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao buscar situações!');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao buscar situações '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};

export const updateSituation = async(situation_id: number, situation: SituationInterface, tId?: number): Promise<SituationInterface> => {
    try {
      const response = await api.put(`/update/situation/${tId}/${situation_id}`, situation);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao atualizar situação');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao atualizar situação '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};

export const deleteSituation = async(situation_id: number, tId?: number) => {
    try {
      const response = await api.delete(`/delete/situation/${tId}/${situation_id}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao deletar situação');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao deletar situação '+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};
