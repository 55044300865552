/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, Result } from 'antd';
import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHookUser } from "../hooks/useHookUser";
import { UserInterface } from "../intefaces/UserInterface";

interface FormSituationProps {
    user?: UserInterface
}

const FormChangePassword: React.FC<FormSituationProps> = ({ user }) => {
    
    const {
        form,
        onFinish,
        success
    } = useHookUser( user?.id, user?.tenant.id );
    const { logout } = useAuth();

    return (
        <div className="flex items-center flex-row justify-between w-full h-1/2">
            {success ? (
                <Result
                    status="success"
                    title="Sua senha foi atualizada com sucesso!"
                    className="flex items-center flex-col justify-between w-full h-1/2"
                    extra={<Button onClick={() => logout()}>Reafaça o login novamente.</Button>}
                />
            ) : (
                <div className="flex flex-row items-center justify-center w-full h-1/2 mb-5">
                    <div className="justify-start w-80">
                        <h1 className="text-2xl flex flex-col">
                            Alteração de senha
                            <span className="text-xs text-gray-400">Informe a senha antiga e a nova senha</span>
                        </h1>
                    </div>
                    <Form
                        form={form}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        className="w-4/5 border-l border-gray-300 h-full flex-col p-3"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <div className="flex flex-col w-full mt-3">
                            <Form.Item name="old_pass" label="Senha Antiga" className="w-full" rules={[{ required: true, message: 'Por favor insira a senha antiga!' }]}>
                                <Input.Password className="w-full" />
                            </Form.Item>
                            <Form.Item name="new_pass" label="Nova Senha" className="w-full" rules={[{ required: true, message: 'Por favor insira uma nova senha!' }]}>
                                <Input.Password className="w-full" />
                            </Form.Item>
                        </div>
                        <div className="flex flex-row justify-end">
                            <Button
                                type="primary"
                                className="w-full"
                                htmlType="submit"
                            >
                                Salvar
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </div>
    ); 
}

export default FormChangePassword;