/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useState } from "react";
import { Asset } from "../intefaces/Asset";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { TypeInterface } from "../intefaces/TypeInterface";

export const useHookGeneratePDF = () => {
  const [generatePDF, setGeneratePDF] = useState(false);
  const styles = StyleSheet.create({
    document: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100vw",
      height: "100vh",
      margin: 0,
      overflow: "hidden",
    },
    page: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      padding: 25,
      backgroundColor: "white",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      width: "100%",
      height: "13%",
      borderBottom: "3px solid #0f172a",
    },
    header_logo: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "column",
      width: "50%",
      height: "100%",
    },
    header_h1: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Poppins",
      fontWeight: "bold",
      fontSize: "28px",
      color: "#0f172a",
      borderBottom: "1px solid #0f172a",
      marginBottom: "10px",
    },
    header_h2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Poppins",
      fontWeight: "bold",
      fontSize: "20px",
      color: "#0f172a",
    },
    header_span: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Poppins",
      fontWeight: "bold",
      fontSize: "10px",
      color: "#334155",
      marginBottom: "10px",
    },
    custom_header_span: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Poppins",
      fontWeight: "bold",
      fontSize: "10px",
      color: "#334155",
      marginBottom: "2px",
    },
    header_infos: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
      flexDirection: "column",
      width: "50%",
      height: "100%",
    },
    custom_header: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "row",
      width: "100%",
      height: "10%",
      marginTop: "5px",
    },
    custom_header_infos: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "column",
      width: "35%",
      height: "100%",
      marginRight: "3px",
    },
    main: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      height: "82%",
    },
    table: {
      display: "flex",
      width: "100%",
      //border: "1px solid #000",
      marginTop: "6px",
    },
    tableRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "row",
      height: "20px",
    },
    tableCellHeader: {
      backgroundColor: "#1f2937",
      color: "white",
      textAlign: "center",
      fontFamily: "Poppins",
      fontWeight: "bold",
      padding: "2px",
      fontSize: "8px",
    },
    tableCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Poppins",
      textAlign: "center",
      padding: "2px",
      fontSize: "8px",
    },
    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "100%",
      height: "5%",
    },
    footer_span: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      fontFamily: "Poppins",
      fontWeight: "bold",
      fontSize: "10px",
      color: "#334155",
    },
  });

  /** Relatorio geral de ativos */
  const AssetReport = async (
    assets: Asset[],
    username?: string,
    company?: CompanyInterface,
    group?: TypeInterface
  ) => {
    Font.register({
      family: "Poppins",
      src: "https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
      fontWeight: 650,
    });
    setGeneratePDF(true!);

    const AssetDOC = () => (
      <Document
        title="Listagem do Bem para Controler dos Seguros"
        style={styles.document}
      >
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <View style={styles.header_logo}>
              <Text style={styles.header_h1}>IPATRIMÔNIO</Text>
              <Text style={styles.header_span}>
                Listagem do Bem para Controler dos Seguros
              </Text>
            </View>
            <View style={styles.header_infos}>
              <Text style={styles.header_h2}>
                {company ? company.company_name : ""}
              </Text>
              <Text style={styles.header_span}>
                Data.: {new Date().toLocaleDateString("pt-BR")}
              </Text>
              <Text style={styles.header_span}>Responsável.: {username}</Text>
            </View>
          </View>
          <View style={styles.main}>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellHeader, { width: "5%" }]}>ID</Text>
              <Text
                style={[
                  styles.tableCellHeader,
                  { textAlign: "left", width: "30%" },
                ]}
              >
                Descrição
              </Text>
              <Text
                style={[
                  styles.tableCellHeader,
                  { textAlign: "left", width: "20%" },
                ]}
              >
                Empresa
              </Text>
              <Text style={[styles.tableCellHeader, { width: "15%" }]}>
                Data Compra
              </Text>
              <Text style={[styles.tableCellHeader, { width: "15%" }]}>
                Estado
              </Text>
              <Text style={[styles.tableCellHeader, { width: "15%" }]}>
                Valor Comp. R$
              </Text>
            </View>
            {company || group
              ? assets
                  .filter((asset) => {
                    // Se ambos empresa e grupo são selecionados
                    if (company && group) {
                      return (
                        asset.asset_company.company_id === company.company_id &&
                        asset.asset_type.type_id === group.type_id
                      );
                    }
                    // Se apenas a empresa é selecionada
                    if (company && !group) {
                      return (
                        asset.asset_company.company_id === company.company_id
                      );
                    }
                    // Se apenas o grupo é selecionado
                    if (!company && group) {
                      return asset.asset_type.type_id === group.type_id;
                    }
                    // Se nenhum critério é selecionado, retorna todos os ativos
                    return true;
                  })
                  .map((asset) => (
                    <View style={styles.table} key={asset.asset_code}>
                      <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, { width: "5%" }]}>
                          {asset.asset_code}
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            { textAlign: "left", width: "30%" },
                          ]}
                        >
                          {asset.asset_description}
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            { textAlign: "left", width: "20%" },
                          ]}
                        >
                          {asset.asset_company.company_name}
                        </Text>
                        <Text style={[styles.tableCell, { width: "15%" }]}>
                          {new Date(
                            asset.asset_purchase_date
                          ).toLocaleDateString("pt-BR")}
                        </Text>
                        <Text style={[styles.tableCell, { width: "15%" }]}>
                          {asset.asset_conservation_state === "NO"
                            ? "NOVO"
                            : asset.asset_conservation_state === "OT"
                            ? "ÓTIMO"
                            : asset.asset_conservation_state === "BO"
                            ? "BOM"
                            : asset.asset_conservation_state === "RE"
                            ? "REGULAR"
                            : asset.asset_conservation_state === "RU"
                            ? "RUIM"
                            : asset.asset_conservation_state === "IN"
                            ? "INSERVÍVEL"
                            : "DESCONHECIDO"}
                        </Text>
                        <Text style={[styles.tableCell, { width: "15%" }]}>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(asset.asset_purchase_value)}
                        </Text>
                      </View>
                    </View>
                  ))
              : assets.map((asset) => (
                  <View style={styles.table} key={asset.asset_code}>
                    <View style={styles.tableRow}>
                      <Text style={[styles.tableCell, { width: "5%" }]}>
                        {asset.asset_code}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          { textAlign: "left", width: "30%" },
                        ]}
                      >
                        {asset.asset_description}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          { textAlign: "left", width: "20%" },
                        ]}
                      >
                        {asset.asset_company.company_name}
                      </Text>
                      <Text style={[styles.tableCell, { width: "15%" }]}>
                        {new Date(asset.asset_purchase_date).toLocaleDateString(
                          "pt-BR"
                        )}
                      </Text>
                      <Text style={[styles.tableCell, { width: "15%" }]}>
                        {asset.asset_conservation_state === "NO"
                          ? "NOVO"
                          : asset.asset_conservation_state === "OT"
                          ? "ÓTIMO"
                          : asset.asset_conservation_state === "BO"
                          ? "BOM"
                          : asset.asset_conservation_state === "RE"
                          ? "REGULAR"
                          : asset.asset_conservation_state === "RU"
                          ? "RUIM"
                          : asset.asset_conservation_state === "IN"
                          ? "INSERVÍVEL"
                          : "DESCONHECIDO"}
                      </Text>
                      <Text style={[styles.tableCell, { width: "15%" }]}>
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(asset.asset_purchase_value)}
                      </Text>
                    </View>
                  </View>
                ))}
          </View>
          <View style={styles.footer}>
            <Text style={styles.footer_span}>
              @ {new Date().getFullYear()} Desenvolvido por Ideia Sistemas
            </Text>
          </View>
        </Page>
      </Document>
    );

    const blob = await pdf(<AssetDOC />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    setGeneratePDF(false);

    /*const credentials = "lahorrytakashi@gmail.com:Utkz1998"; // Substitua com seu e-mail e senha
    const encodedCredentials = btoa(credentials);

    const asset = assets.map((asset) => ({
      ...asset,
      username: username,
    }));

    try {
      const response = await axios.post(
        "https://takashi.jsreportonline.net/api/report",
        {
          template: {"name":"invoice-main"},
          data:{ asset }
        },
        {
          headers: {
            Authorization: `Basic ${encodedCredentials}`,
            "Content-Type": "application/json", // Adapte o tipo de conteúdo se necessário
          },
          responseType: "arraybuffer", // Define o tipo de resposta para arraybuffer para lidar com PDFs
        }
      );

      
    } catch (error) {
      console.error("Erro ao gerar o PDF:", error);
    }*/
  };

  /** Relatorio de depreciação de ativos */
  const AssetDepreciationReport = async (asset: Asset, username?: string) => {
    Font.register({
      family: "Poppins",
      src: "https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
      fontWeight: 700,
    });

    const calculateDepreciation = (
      purchaseValue: number,
      depreciationRate: number,
      lifeCicle: number
    ) => {
      const totalMonths = lifeCicle;
      const monthlyDepreciationRate = depreciationRate / 100;
      let initialValue = purchaseValue;

      const depreciationTable = [];

      if (totalMonths <= 12) {
        // Caso de menos de 12 meses, consideramos como 1 ano
        const monthlyDepreciationValue = initialValue * monthlyDepreciationRate;
        const annualDepreciationValue = monthlyDepreciationValue * totalMonths;
        const finalValue = initialValue - annualDepreciationValue;

        const yearData = {
          year: 1,
          initial_value: initialValue,
          annual_depreciation_value: annualDepreciationValue,
          monthly_Provision: monthlyDepreciationValue,
          final_value: finalValue,
          months: Array.from({ length: totalMonths }).map(
            () => monthlyDepreciationValue
          ),
        };

        depreciationTable.push(yearData);
      } else {
        // Caso de mais de 12 meses, dividimos em anos completos e meses restantes
        const fullYears = Math.floor(totalMonths / 12);
        const remainingMonths = totalMonths % 12;

        for (let year = 0; year < fullYears; year++) {
          const monthlyDepreciationValue =
            initialValue * monthlyDepreciationRate;
          const annualDepreciationValue = monthlyDepreciationValue * 12;
          const finalValue = initialValue - annualDepreciationValue;

          const yearData = {
            year: year + 1,
            initial_value: initialValue,
            annual_depreciation_value: annualDepreciationValue,
            monthly_Provision: monthlyDepreciationValue,
            final_value: finalValue,
            months: Array.from({ length: 12 }).map(
              () => monthlyDepreciationValue
            ),
          };

          depreciationTable.push(yearData);
          initialValue = finalValue; // Atualiza para o próximo ano
        }

        // Se houver meses restantes, calcula e salva para o período final
        if (remainingMonths > 0) {
          const monthlyDepreciationValue =
            initialValue * monthlyDepreciationRate;
          const finalMonthsDepreciationValue =
            monthlyDepreciationValue * remainingMonths;
          const finalValue = initialValue - finalMonthsDepreciationValue;

          const yearData = {
            year: fullYears + 1,
            initial_value: initialValue,
            annual_depreciation_value: finalMonthsDepreciationValue,
            monthly_Provision: monthlyDepreciationValue,
            final_value: finalValue,
            months: Array.from({ length: remainingMonths }).map(
              () => monthlyDepreciationValue
            ),
          };

          depreciationTable.push(yearData);
        }
      }

      return depreciationTable;
    };

    const depreciationTable = calculateDepreciation(
      asset.asset_purchase_value,
      asset.asset_depreciation_rate,
      asset.asset_life_cycle
    );

    const recordsPerPage = 15;
    const pages = Math.ceil(depreciationTable.length / recordsPerPage);

    const AssetDOC = () => (
      <Document title="Listagem de Depreciação do Bem" style={styles.document}>
        {Array.from({ length: pages }).map((_, pageIndex) => (
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.header}>
              <View style={styles.header_logo}>
                <Text style={styles.header_h1}>IPATRIMÔNIO</Text>
                <Text style={styles.header_span}>
                  Listagem de Depreciação do Bem
                </Text>
              </View>
              <View style={styles.header_infos}>
                <Text style={styles.header_h2}>
                  {asset.asset_company.company_name.toLocaleUpperCase()}
                </Text>
                <Text style={styles.header_span}>
                  Data .: {new Date().toLocaleDateString()}
                </Text>
                <Text style={styles.header_span}>
                  Responsavél .: {username}
                </Text>
              </View>
            </View>
            <View style={styles.main}>
              <View style={styles.custom_header}>
                <View style={styles.custom_header_infos}>
                  <Text style={styles.custom_header_span}>
                    Grupo .: {"            "}{" "}
                    {asset.asset_type.type_description}
                  </Text>
                  <Text style={styles.custom_header_span}>
                    Localização .: {"  "} {asset.asset_localization?.name}
                  </Text>
                  <Text style={styles.custom_header_span}>
                    Patrimônio .: {"    "} {asset.asset_code} {"  -  "}{" "}
                    {asset.asset_description}
                  </Text>
                </View>
                <View style={styles.custom_header_infos}>
                  <Text style={styles.custom_header_span}>
                    Aquisição .: {"                "}
                    {new Date(asset.asset_purchase_date).toLocaleDateString(
                      "pt-BR"
                    )}
                  </Text>
                  <Text style={styles.custom_header_span}>
                    Vlr. Bem R$ .: {"             "}{" "}
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(asset.asset_purchase_value)}
                  </Text>
                  <Text style={styles.custom_header_span}>
                    Tx. Depreciação .: {"    "} {asset.asset_depreciation_rate}
                    {"%"}
                  </Text>
                </View>
                <View style={styles.custom_header_infos}>
                  <Text style={styles.custom_header_span}>
                    Vida útil .: {"    "} {asset.asset_life_cycle}
                    {" Mês(es)"}
                  </Text>
                </View>
              </View>
              <View style={styles.table}>
                {/* Cabeçalho da tabela */}
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCellHeader, { width: "5%" }]}>
                    Ano
                  </Text>
                  {/* Renderizar o cabeçalho para todos os 12 meses */}
                  {[
                    "Janeiro",
                    "Fevereiro",
                    "Março",
                    "Abril",
                    "Maio",
                    "Junho",
                    "Julho",
                    "Agosto",
                    "Setembro",
                    "Outubro",
                    "Novembro",
                    "Dezembro",
                  ].map((month, monthIndex) => (
                    <Text
                      key={monthIndex}
                      style={[styles.tableCellHeader, { width: "7.5%" }]}
                    >
                      {month}
                    </Text>
                  ))}
                  <Text style={[styles.tableCellHeader, { width: "10%" }]}>
                    Total
                  </Text>
                </View>

                {/* Renderizar os dados de depreciação para cada ano */}
                {depreciationTable
                  .slice(
                    pageIndex * recordsPerPage,
                    (pageIndex + 1) * recordsPerPage
                  )
                  .map((yearData, yearIndex) => (
                    <View key={yearData.year} style={styles.tableRow}>
                      <Text
                        style={[styles.tableCell, { width: "5%" }]}
                      >{`Ano ${yearData.year}`}</Text>
                      {Array.from({ length: 12 }).map((_, monthIndex) => (
                        <Text
                          key={monthIndex}
                          style={[styles.tableCell, { width: "7.5%" }]}
                        >
                          {monthIndex < yearData.months.length
                            ? new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(yearData.months[monthIndex])
                            : ""}
                        </Text>
                      ))}
                      <Text style={[styles.tableCell, { width: "10%" }]}>
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(
                          yearData.months.reduce((acc, val) => acc + val, 0)
                        )}
                      </Text>
                    </View>
                  ))}
              </View>
            </View>
            <View style={styles.footer}>
              <Text style={styles.footer_span}>
                @ {new Date().getFullYear()} Desenvolvido por Ideia Sistemas
              </Text>
            </View>
          </Page>
        ))}
      </Document>
    );

    const blob = await pdf(<AssetDOC />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  /** Relatorio de depreciação acumulada de ativos */
  const AssetAcumulateDepreciationReport = async (
    assets: Asset[],
    start: dayjs.Dayjs,
    end: dayjs.Dayjs,
    username?: string
  ) => {
    Font.register({
      family: "Poppins",
      src: "https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
      fontWeight: 700,
    });

    const calculateDailyDepreciation = (
      value: number,
      lifeCycle: number,
      depreciationRate: number
    ) => {
      const monthlyDepreciation = value * (depreciationRate / 100);
      const lifecycleDays = monthlyDepreciation / 30;
      //const lifecycleDays = monthlyDepreciation / 30;

      const daysInRange = end.diff(start, "day") + 1;
      const totalDepreciation = lifecycleDays * daysInRange;

      return totalDepreciation;
    };

    const AssetDOC = () => (
      <Document
        title="Demonstrativo da Depreciação Acumulada"
        style={styles.document}
      >
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <View style={styles.header_logo}>
              <Text style={styles.header_h1}>IPATRIMÔNIO</Text>
              <Text style={styles.header_span}>
                Demonstrativo da Depreciação Acumulada
              </Text>
              <Text style={[styles.header_span, { fontSize: "8px" }]}>
                Periodo de .:{" "}
                {start.format("DD/MM/YYYY") + " a " + end.format("DD/MM/YYYY")}
              </Text>
            </View>
            <View style={styles.header_infos}>
              <Text style={styles.header_h2}>
                {assets[0].asset_company.company_name}
              </Text>
              <Text style={styles.header_span}>
                Data.: {new Date().toLocaleDateString("pt-BR")}
              </Text>
              <Text style={styles.header_span}>Responsável.: {username}</Text>
            </View>
          </View>
          <View style={styles.main}>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellHeader, { width: "5%" }]}>ID</Text>
              <Text
                style={[
                  styles.tableCellHeader,
                  { textAlign: "left", width: "30%" },
                ]}
              >
                Descrição
              </Text>
              <Text
                style={[
                  styles.tableCellHeader,
                  { textAlign: "left", width: "20%" },
                ]}
              >
                Empresa
              </Text>
              <Text
                style={[
                  styles.tableCellHeader,
                  { textAlign: "left", width: "15%" },
                ]}
              >
                Grupo
              </Text>
              <Text style={[styles.tableCellHeader, { width: "15%" }]}>
                Situação
              </Text>
              <Text style={[styles.tableCellHeader, { width: "15%" }]}>
                Depr. Acum. R$
              </Text>
            </View>
            {assets.map((asset) => (
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, { width: "5%" }]}>
                    {asset.asset_code}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { textAlign: "left", width: "30%" },
                    ]}
                  >
                    {asset.asset_description}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { textAlign: "left", width: "20%" },
                    ]}
                  >
                    {asset.asset_company.company_name}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { textAlign: "left", width: "15%" },
                    ]}
                  >
                    {asset.asset_type.type_description}
                  </Text>
                  <Text style={[styles.tableCell, { width: "15%" }]}>
                    {asset.asset_status.situation_description}
                  </Text>
                  <Text style={[styles.tableCell, { width: "15%" }]}>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(
                      calculateDailyDepreciation(
                        asset.asset_purchase_value,
                        asset.asset_life_cycle,
                        asset.asset_depreciation_rate
                      )
                    )}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.footer}>
            <Text style={styles.footer_span}>
              @ {new Date().getFullYear()} Desenvolvido por Ideia Sistemas
            </Text>
          </View>
        </Page>
      </Document>
    );

    const blob = await pdf(<AssetDOC />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  return {
    generatePDF,
    AssetReport,
    AssetDepreciationReport,
    AssetAcumulateDepreciationReport,
  };
};
