/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, DatePicker, Form, Input, Select, Spin } from "antd";
import React from "react";
import { useHookCities } from "../hooks/useHookCities";
import { useHookFormResponsible } from "../hooksForm/useHookFormResponsible";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { ResponsibleInterface } from "../intefaces/ResponsibleInterface";
import { UserInterface } from "../intefaces/UserInterface";

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

interface FormResponsibleProps {
  updateTable: () => void;
  editMode: boolean;
  initialValues?: ResponsibleInterface;
  modalVisible: boolean;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  setEditMode: (visible: boolean) => void;
  companies?: CompanyInterface[] | null;
  user?: UserInterface;
}

const FormResponsible: React.FC<FormResponsibleProps> = ({
  updateTable,
  editMode,
  initialValues,
  shouldResetForm,
  setShouldResetForm,
  setEditMode,
  companies,
  user,
}) => {
  const { cities } = useHookCities();
  const {
    form,
    onFinish,
    handleCpfChange,
    handlePhoneChange,
    handleSearchPostalCode,
    handleInputChange,
    spinning,
    spinningText,
  } = useHookFormResponsible(
    updateTable,
    editMode,
    shouldResetForm,
    setShouldResetForm,
    setEditMode,
    companies,
    user,
    initialValues,
    cities
  );

  return (
    <div className="flex flex-row justify-between w-full h-1/2 mb-5">
      <Spin spinning={spinning} tip={spinningText} fullscreen />
      <div className="justify-start w-80">
        <h1 className="text-2xl flex flex-col">
          Detalhes do Responsável
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <div className="flex flex-row w-full">
          <Form.Item name="responsible_code" label="ID" className="w-1/1 mr-3">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="responsible_name"
            label="Nome"
            className="w-3/4 mr-2"
            rules={[{ required: true, message: "Insira um nome!" }]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }}/>
          </Form.Item>
        </div>
        <div className="flex flex-row w-full">
          <Form.Item
            name="responsible_status"
            label="Status"
            className="w-1/4 mr-3"
          >
            <Select defaultValue={"A"}>
              <Option value="A">Ativo</Option>
              <Option value="I">Inativo</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="responsible_birthday"
            label="Nascimento"
            className="w-1/5 mr-3"
          >
            <DatePicker format={dateFormat} />
          </Form.Item>
          <Form.Item
            name="responsible_cpf"
            label="CPF/CNPJ"
            className="w-1/3 mr-3"
          >
            <Input onChange={handleCpfChange} maxLength={18} />
          </Form.Item>
          <Form.Item name="responsible_rg" label="RG" className="w-1/4 mr-2">
            <Input />
          </Form.Item>
        </div>
        <div className="flex flex-row w-full">
          <Form.Item
            name="responsible_email"
            label="Email"
            className="w-1/2 mr-3"
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="responsible_phone"
            label="Celular"
            className="w-1/3 mr-2"
          >
            <Input onChange={handlePhoneChange} maxLength={16} />
          </Form.Item>
          <Form.Item
            name="responsible_company"
            label="Empresa"
            className="w-1/3 mr-2"
            rules={[{ required: true, message: "Selecione a empresa" }]}
          >
            <Select
              showSearch
              filterOption={(input, option) => {
                if (!option) return true;
                if (!option.label) return false;
                return option.label
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              style={{ textTransform: "uppercase" }}
            >
              {companies &&
                companies.map((company) => (
                  <Option value={company.company_id} label={company.company_name} style={{ textTransform: "uppercase" }}>
                    {company.company_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item name="responsible_cep" label="CEP" className="w-1/4 mr-2">
            <Input onBlur={(e) => handleSearchPostalCode(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="responsible_number"
            label="Nº Endereço"
            className="w-28 mr-2"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="responsible_neighborhood"
            label="Bairro"
            className="w-3/4 mr-2"
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            name="responsible_city"
            label="Cidade"
            className="w-1/4 mr-2"
          >
            <Select
              showSearch
              filterOption={(input, option) => {
                if (!option) return true;
                if (!option.label) return false;
                return option.label
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              style={{ textTransform: "uppercase" }}
            >
              {cities &&
                cities.map((city) => (
                  <Option
                    label={city.city_description}
                    value={city.city_id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {city.city_description}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="responsible_address"
            label="Endereço"
            className="w-3/4 mr-3"
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input  style={{ textTransform: "uppercase" }} />
          </Form.Item>
        </div>
        <div className="flex flex-row justify-end">
          <Button
            type="default"
            className="w-1/5"
            htmlType="submit"
            style={{ backgroundColor: "green", color: "white" }}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormResponsible;
