/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Empty,
  Form,
  Input,
  Layout,
  Popconfirm,
  Select,
  Spin,
  Table,
  Tabs,
} from "antd";

import { useHookFormUserCompany } from "../hooksForm/useHookFormUserCompany";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { UserCompany } from "../intefaces/UserCompany";
import { UserInterface } from "../intefaces/UserInterface";

interface FormUserCompanyProps {
  updateTable: () => void;
  editMode: boolean;
  initialValues?: UserCompany;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  companies?: CompanyInterface[];
  user?: UserInterface;
  linkedUser?: UserInterface;
}

const { Option } = Select;
const { TabPane } = Tabs;
const { Content } = Layout;

export const FormUserCompany: React.FC<FormUserCompanyProps> = ({
  updateTable,
  companies,
  user,
  linkedUser,
}) => {
  const { form, onFinish, spinning, handleCompanySelect, uc, handleDelete } =
    useHookFormUserCompany(updateTable, companies, linkedUser, user);
  //const { palette } = useTheme();

  const columns = [
    {
      title: "ID",
      dataIndex: "user_code",
      key: "user_code",
    },
    {
      title: "Usuário",
      dataIndex: ["user", "username"], // ajuste conforme necessário
      key: "username",
    },
    {
      title: "Empresa",
      dataIndex: ["company", "company_name"], // ajuste conforme necessário
      key: "company_name",
    },
    {
      title: "Ações",
      key: "actions",
      render: (_: UserCompany, record: UserCompany) => (
        <Popconfirm
          title="Tem certeza que deseja deletar?"
          onConfirm={() => handleDelete(record.id)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="primary" danger>
            Deletar
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className={"flex flex-row justify-between w-full h-1/2 mb-5"}>
      <Spin spinning={spinning} tip="Criando..." fullscreen />
      <div className="justify-start w-80">
        <h1 className="text-2xl flex flex-col">
          Vincular Empresas
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>

      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <Tabs defaultActiveKey="1" className="p-1">
          <TabPane tab="Vincular" key="1">
            <div className="flex flex-row w-full">
              <Form.Item name="code" label="ID" className="w-1/1 mr-3">
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="company"
                label="Empresa"
                className="w-full mr-3"
                rules={[
                  { required: true, message: "Selecione a(as) empresa(s)!" },
                ]}
              >
                <Select
                  mode="multiple"
                  onChange={handleCompanySelect}
                  filterOption={(input, option) => {
                    if (!option) return true;
                    if (!option.label) return false;
                    return option.label
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {companies &&
                    companies.map((company) => (
                      <Option
                        key={company.company_id}
                        value={company.company_id}
                        label={company.company_name}
                        style={{ textTransform: "uppercase" }}
                      >
                        {company.company_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="flex flex-row justify-end">
              <Button
                type="default"
                className="w-1/5"
                htmlType="submit"
                style={{ backgroundColor: "green", color: "white" }}
              >
                Salvar
              </Button>
            </div>
          </TabPane>
          <TabPane tab="Minhas Empresas" key="2">
            <Layout className="bg-white">
              <Content className="overflow-auto rounded-none bg-white">
                <Table
                  dataSource={uc}
                  columns={columns}
                  rowKey="id"
                  locale={{ emptyText: <Empty description="Sem dados" /> }}
                  className="w-full fixed-header-table overflow-y-scroll rounded-none bg-white"
                  style={{
                    scrollbarWidth: "thin",
                  }}
                />
              </Content>
            </Layout>
          </TabPane>
        </Tabs>
      </Form>
    </div>
  );
};
