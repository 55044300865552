/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { PaymentMethodInterface } from "../intefaces/PaymentMethodInterface";
import { UserInterface } from "../intefaces/UserInterface";
import {
  createPaymentMethod,
  updatePaymentMethod,
} from "../service/paymentMethod";

export const useHookFormPaymentMethods = (
  updateTable: () => void,
  editMode: boolean,
  shouldResetForm: boolean,
  setShouldResetForm: (visible: boolean) => void,
  companies?: CompanyInterface[],
  initialValues?: PaymentMethodInterface,
  user?: UserInterface
) => {
  const [form] = Form.useForm<PaymentMethodInterface>();
  const [searchValue, setSearchValue] = useState("");
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [spinningText, setSpinningText] = React.useState<string>("");

  const onFinish = async (values: PaymentMethodInterface) => {
    try {
      if (editMode && initialValues) {
        setSpinning(true);
        setSpinningText("Atualizando...");
        if (typeof values.payment_method_company === "string" && companies) {
          let company = companies.filter(
            (company) =>
              company.company_name ===
              form.getFieldValue("payment_method_company")
          );
          if (company && company.length > 0) {
            values.payment_method_company = company[0];
          }
        } else if (
          typeof values.payment_method_company === "number" &&
          companies
        ) {
          let company = companies.filter(
            (company) =>
              company.company_id ===
              form.getFieldValue("payment_method_company")
          );
          if (company && company.length > 0) {
            values.payment_method_company = company[0];
          }
        }

        await updatePaymentMethod(
          initialValues.payment_method_id,
          values,
          user?.tenant.id
        );
        message.success("Metodo de pagamento atualizado com sucesso");
        form.resetFields();
        updateTable();
        setSpinning(false);
        setSpinningText("");
      } else {
        setSpinning(true);
        setSpinningText("Criando...");
        if (values.payment_method_company && companies) {
          let company = companies.filter(
            (company) =>
              company.company_id ===
              form.getFieldValue("payment_method_company")
          );
          if (company && company.length > 0) {
            values.payment_method_company = company[0];
          }
        }

        values.payment_method_code = 0;

        await createPaymentMethod(values, user?.tenant.id);
        message.success("Metodo de pagamento criado com sucesso");
        form.resetFields();
        updateTable();
        setSpinning(false);
        setSpinningText("");
      }
    } catch (error) {
      console.error("Erro ao criar metodo de pagamento: ", error);
      message.error("Erro ao criar metodo de pagamento");
      setSpinning(false);
      setSpinningText("");
    }
  };

  useEffect(() => {
    if (editMode && initialValues) {
      form.setFieldsValue(initialValues);
      form.setFieldValue(
        "payment_method_company",
        initialValues.payment_method_company.company_name
      );
    } else {
      form.resetFields();
    }
  }, [editMode, initialValues]);

  useEffect(() => {
    if (shouldResetForm) {
      form.resetFields();
      setShouldResetForm(false);
      setSearchValue("");
    }
  }, [shouldResetForm]);

  return {
    form,
    onFinish,
    spinning,
    spinningText,
  };
};
