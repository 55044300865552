/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useTheme } from "../contexts/ThemeContext";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { deleteCompany, getAllCompanies, getCompany } from "../service/companyService";

export const useHooksCompanies = (uid?: number, companyId?: number[], role?: string) => {
    const [companies, setCompanies] = useState<CompanyInterface[]>([]);
    const [selectedCompanies, setSelectedCompanies] = useState<CompanyInterface>();
    const [searchValue, setSearchValue] = useState('');
    const [searchParam, setSearchParam] = useState<string>('company_code');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);
    const { palette } = useTheme();

    const customStyles = {
        dropdownStyle: {
            backgroundColor: palette.secondary,
        },
        optionStyle: {
            backgroundColor: palette.secondary,
            color: palette.fontColor,
        },
    };

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const companiesData = await getAllCompanies(uid, companyId, role);
                setCompanies(companiesData);
            } catch (error) {
                console.error('Erro ao buscar empresas:', error);
            }
        };

        fetchCompanies();

    }, []);


    const updateCompanies = async () => {
        try {
            const companiesData = await getAllCompanies(uid, companyId, role);
            setCompanies(companiesData);
        } catch (error) {
            console.error('Erro ao buscar empresas:', error);
        }
    };

    const handleDelete = async (record: number) => {
        try {
            await deleteCompany(record, uid);
            updateCompanies();
            message.success('Empresa excluida com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar empresa:', error);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, param: string, value: string) => {
        if (event.key === 'Enter') {
            updateDataTable(param, value);
        } else if (event.key === 'Backspace') {
            handleClearSearch();
        }
    }

    const updateDataTable = async (param: string, value: string) => {
        try {

            let findCompany: React.SetStateAction<CompanyInterface[]>;

            if (param === 'company_code') {
                findCompany = await getCompany(param, value, uid);
            } else if (param === 'company_cpfOrCnpj') {
                value = value.replace("/", "");
                findCompany = await getCompany(param, value, uid);
            } else {
                value = '*' + value;
                findCompany = await getCompany(param, value, uid);
            }

            setSpinning(true);

            setTimeout(() => {
                if (findCompany) {
                    setCompanies(findCompany);
                    //setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                }

                message.error('Nenhuma empresa encontrada!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar empresa:', error);
            message.error('Erro ao buscar empresa');
        }
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleEdit = (record: CompanyInterface) => {
        setSelectedCompanies(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
    };

    const handleClearSearch = () => {
        updateCompanies();
        setSearchValue('');
        setIsSearching(false);
    };

    return {
        companies,
        updateCompanies,
        handleDelete,
        updateDataTable,
        searchValue,
        isSearching,
        setSearchValue,
        setIsSearching,
        spinning,
        showModal,
        modalVisible,
        handleEdit,
        editMode,
        selectedCompanies,
        handleCancel,
        handleClearSearch,
        shouldResetForm,
        setEditMode,
        setShouldResetForm,
        setModalVisible,
        customStyles,
        searchParam,
        setSearchParam,
        handleKeyPress
    };

};