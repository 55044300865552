/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, Select, Spin } from "antd";
import React from "react";
import { useHookFormTenant } from "../hooksForm/useHookFormTenant";
import { CityInterface } from "../intefaces/CityInterface";
import { TenantInterface } from "../intefaces/TenantInterface";
import { CpfOrCnpjMask } from "../utils/Masks";
import { validateCpfCnpj } from "../utils/UseCases";

const { Option } = Select;

interface FormTenantProps {
  updateTable: () => void;
  editMode: boolean;
  initialValues?: TenantInterface;
  modalVisible: boolean;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  setEditMode: (visible: boolean) => void;
  cities?: CityInterface[] | null;
  setModalVisible: (visible: boolean) => void;
}

const FormTenant: React.FC<FormTenantProps> = ({
  updateTable,
  editMode,
  initialValues,
  shouldResetForm,
  setShouldResetForm,
  cities,
}) => {
  const {
    form,
    onFinish,
    spinning,
    handleCpfChange,
    handleSearchPostalCode,
    spinningText,
    handleChange,
    selectedValue,
  } = useHookFormTenant(
    updateTable,
    editMode,
    shouldResetForm,
    setShouldResetForm,
    initialValues,
    cities || undefined
  );

  return (
    <div className="flex flex-row justify-between w-full h-1/2 mb-5">
      <Spin spinning={spinning} tip={spinningText} fullscreen />
      <div className="justify-start w-80">
        <h1 className="text-2xl flex flex-col">
          Detalhes do Tenant
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <div className="flex flex-row w-full">
          <Form.Item name="id" label="ID" className="w-1/1 mr-3">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="name"
            label="Nome"
            className="w-1/2 mr-3"
            rules={[{ required: true, message: "Insira um nome!" }]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="cpf"
            label="CPF/CNPJ"
            className="w-1/4"
            rules={[
              { required: true, message: "Insira um CPF/CNPJ!" },
              () => ({
                validator(_, value) {
                  const validation = validateCpfCnpj(CpfOrCnpjMask(value));
                  if (validation.error) {
                    return Promise.reject(validation.error.message);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input onChange={handleCpfChange} maxLength={18} />
          </Form.Item>
        </div>
        <div className="flex flex-row w-full">
          <Form.Item
            name="email"
            label="Email"
            className="w-3/4 mr-3"
            rules={[{ required: true, message: "Insira um email valido!" }]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="type"
            label="Regra"
            className="w-1/4"
            rules={[{ required: true, message: "Selecione a regra!" }]}
          >
            <Select
              style={{ textTransform: "uppercase" }}
              onChange={handleChange}
              defaultValue={selectedValue}
            >
              <Option value="admin" style={{ textTransform: "uppercase" }}>
                Administrador
              </Option>
              <Option value="super" style={{ textTransform: "uppercase" }}>
                Super
              </Option>
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row w-full">
          <Form.Item name="cep" label="CEP" className="w-3/12 mr-3">
            <Input onBlur={(e) => handleSearchPostalCode(e.target.value)} />
          </Form.Item>
          <Form.Item name="city" label="Cidade" className="w-1/4 mr-2">
            <Select
              showSearch
              filterOption={(input, option) => {
                if (!option) return true; // se as opções forem nulas é renderizdo todos os clientes
                if (!option.label) return false; // se não existir não é retornado nada
                return option.label
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase()); // caso exista e retornado o cliente
              }}
              style={{ textTransform: "uppercase" }}
            >
              {cities &&
                cities.map((city) => (
                  <Option
                    value={city.city_id}
                    label={city.city_description}
                    style={{ textTransform: "uppercase" }}
                  >
                    {city.city_description}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="adress_number"
            label="Nº Endereço"
            className="w-1/6 mr-3"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="neighborhood"
            label="Bairro"
            className="w-3/5 "
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
        </div>
        <div className="flex flex-row w-full">
          <Form.Item
            name="adress"
            label="Endereço"
            className="w-full"
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
        </div>
        <div className="flex flex-row justify-end">
          <Button
            type="default"
            className="w-1/5"
            htmlType="submit"
            style={{ backgroundColor: "green", color: "white" }}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormTenant;
