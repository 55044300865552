import { useState } from "react";

export const useHookBackground = () => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    
    const handleClick = (index: number) => {
        setSelectedIndex(index);
    }

    return { selectedIndex, handleClick };
}