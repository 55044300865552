/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message, UploadFile } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import React, { useEffect, useState } from "react";
import { CityInterface } from "../intefaces/CityInterface";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { UserInterface } from "../intefaces/UserInterface";
import { createCompany, updateCompany } from "../service/companyService";
import { CpfOrCnpjMask, PhoneMask } from "../utils/Masks";
import { resizeImage, searchPostalCode } from "../utils/UseCases";

export const useHookFormCompany = (
  updateTable: () => void,
  setModalVisible: (visible: boolean) => void,
  editMode: boolean,
  setShouldResetForm: (visible: boolean) => void,
  setEditMode: (visible: boolean) => void,
  initialValues?: CompanyInterface,
  shouldResetForm?: boolean,
  cities?: CityInterface[] | null,
  user?: UserInterface
) => {
  const [form] = Form.useForm<CompanyInterface>();
  const [logo, setLogo] = useState<UploadFile | any>(null);
  const [searchValue, setSearchValue] = useState("");
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [spinningText, setSpinningText] = React.useState<string>("");

  const onFinish = async (values: CompanyInterface) => {
    try {
      if (editMode && initialValues) {
        setSpinning(true);
        setSpinningText("Atualizando...");
        if (typeof values.company_city === "string" && cities) {
          let CityInterface = cities.filter(
            (city) =>
              city.city_description.toLowerCase() ===
              values.company_city.toString().toLowerCase()
          );
          if (CityInterface && CityInterface.length > 0) {
            values.company_city = CityInterface[0];
          }
        } else if (typeof values.company_city === "number" && cities) {
          let CityInterface = cities.filter(
            (city) => city.city_id === form.getFieldValue("company_city")
          );
          if (CityInterface && CityInterface.length > 0) {
            values.company_city = CityInterface[0];
          }
        }

        if (logo) {
          values.company_logo = logo;
        } else {
          values.company_logo = "";
        }

        await updateCompany(user?.tenant.id, initialValues.company_id, values);
        message.success("Empresa atualizada com sucesso");
        updateTable();
        form.resetFields();
        setLogo(null);
        setModalVisible(false);
        setSpinning(false);
        setSpinningText("");
      } else {
        setSpinning(true);
        setSpinningText("Criando...");
        if (values.company_city && cities) {
          if (typeof values.company_city === "string" && cities) {
            let city = cities.filter(
              (city) =>
                city.city_description.toLowerCase() ===
                values.company_city.toString().toLowerCase()
            );
            if (city && city.length > 0) {
              values.company_city = city[0];
            }
          }
        }

        if (logo) {
          values.company_logo = logo;
        } else {
          values.company_logo = "";
        }

        values.company_code = 0;

        await createCompany(values, user?.tenant.id);

        form.resetFields();
        setLogo(null);
        message.success("Empresa criada com sucesso");
        updateTable();
        setSpinning(false);
        setSpinningText("");
      }
    } catch (error) {
      console.error("Erro ao criar empresa:", error);
      message.error("Erro ao criar empresa");
      setSpinning(false);
      setSpinningText("Atualizando...");
    }
  };

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info && info.fileList[0]) {
      const file = info.fileList[0].originFileObj;
      resizeImage(file as File, 100, 100, (resizedDataUrl) => {
        setLogo(resizedDataUrl);
      });
    } else {
      setLogo(null);
    }
  };

  const handleRemove = () => {
    setLogo(null);
  };

  useEffect(() => {
    if (editMode && initialValues) {
      form.setFieldsValue(initialValues);
      form.setFieldValue(
        "company_city",
        initialValues.company_city?.city_description
      );

      if (initialValues?.company_logo) {
        setLogo(initialValues.company_logo);
      }
    } else {
      form.resetFields();
    }
  }, [editMode, initialValues, form]);

  useEffect(() => {
    if (shouldResetForm) {
      form.resetFields();
      setShouldResetForm(false);
    }
  }, [shouldResetForm, form, setShouldResetForm]);

  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const maskedValue = CpfOrCnpjMask(value);
    form.setFieldValue("company_cpfOrCnpj", maskedValue);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const maskedValue = PhoneMask(value);
    form.setFieldValue("company_phone", maskedValue);
  };

  const handleSearchPostalCode = async (postalCode: string) => {
    try {
      if (postalCode.trim() === "") {
        form.setFieldValue("company_cep", "");
        form.setFieldValue("company_city", "");
        form.setFieldValue("company_neighborhood", "");
        form.setFieldValue("adress", "");
        return;
      }

      const addressData = await searchPostalCode(postalCode);

      form.setFieldValue("company_cep", addressData.cep);
      form.setFieldValue("company_city", addressData.localidade.toUpperCase());
      form.setFieldValue(
        "company_neighborhood",
        addressData.bairro.toUpperCase()
      );
      form.setFieldValue(
        "company_address",
        addressData.logradouro.toUpperCase()
      );
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    handleChange,
    searchValue,
    setSearchValue,
    onFinish,
    logo,
    setLogo,
    handleRemove,
    handleCpfChange,
    handlePhoneChange,
    handleSearchPostalCode,
    spinning,
    spinningText,
  };
};
