import axios, { AxiosError } from 'axios';
import { api } from '../api/api';
import { UserCompany } from '../intefaces/UserCompany';

export const createUserCompany = async (userCompany: UserCompany) => {
  try {
    alert(userCompany.user.username)
    const response = await api.post(`/new/linked/user`, userCompany);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserCompany = async (param?: string, value?: string, tId?: number): Promise<UserCompany> => {
  try {
    const response = await api.get(`/find/one/user/company/${param}/${value}/${tId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao buscar usuário vinculado');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao buscar usuário vinculado.' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
};

export const getAllUserCompany = async (userId?: number, tId?: number,) => {
  if (tId === undefined) return null;
  try {
    const response = await api.get(`/find/all/user/company/${tId}/${userId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao buscar usuário vinculado.');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao buscar usuário vinculado ' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
};

export const updateUserCompany = async (userCompany: UserCompany): Promise<UserCompany> => {
  try {
    const response = await api.put(`/update/user/company`, userCompany);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao atualizar a usuário vinculado');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao atualizar a usuário vinculado ' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
};

export const deleteUserCompany = async (id: number, tId?: number) => {
  try {
    const response = await api.delete(`/delete/user/company/${id}/${tId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao deletar usuário vinculado');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Erro ao deletar usuário vinculado ' + (error as AxiosError).message);
    } else {
      throw error;
    }
  }
};
