/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { UserInterface } from "../intefaces/UserInterface";
import { deleteUser, getAllUsers, getUser, loginUser } from "../service/userService";

export const useHookLogin = (uid?: number) => {
    const [ form ] = Form.useForm<UserInterface>();
    const { login } = useAuth();
    const [logins, setLogins] = useState<UserInterface[]>([]);
    const [selectedLogin, setSelectedLogin] = useState<UserInterface>();
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchParam, setSearchParam] = useState<string>('id');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);

    const onFinish = async (values: UserInterface) => {
        try {
            const loginData = await loginUser(values.email, values.password);
            login(loginData)
        } catch (error) {
            message.error('Erro ao efetuar o login!');
            console.error('Erro ao buscar usuário:', error);
        }
    } 

    useEffect(() => {
        const fetchLogin = async () => {
            try {
                const usersData = await getAllUsers(uid);
                setLogins(usersData);
            } catch (error) {
                console.error('Erro ao buscar usuários: ', error);
            }
        };

        fetchLogin();
    }, [uid]);


    const updateUsers = async () => {
        try {
            const usersData = await getAllUsers(uid);
            setLogins(usersData);
        } catch (error) {
            console.error('Erro ao buscar usuários: ', error);
        }
    }


    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, param: string, value: string) => {
        if (event.key === 'Enter') {
            updateDataTable(param, value);
        } else if (event.key === 'Backspace') {
            handleClearSearch();
        }
    }

    const updateDataTable = async (param: string, value: string) => {
        try {       
            
            if(value === "") {
                message.error("Campo de busca não pode ser vázio!")
                return;
            }

            let findUser: React.SetStateAction<UserInterface[]>;

            if( param === 'id') {
                findUser = await getUser(param, value, uid);
            } else {
                value = '*' + value;
                findUser = await getUser(param, value, uid);
            }
            
            setSpinning(true);
            setTimeout(() => {
                if (findUser) {
                    setLogins(findUser);
                    //setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                } 

                message.error('Nenhum usuário encontrada com esse ID!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar usuário: ', error);
            message.error('Erro ao buscar usuário');
        }
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleEdit = (record: UserInterface) => {
        setSelectedLogin(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
    };

    const handleDelete = async (record: number) => {
        try {
            await deleteUser(record, uid);
            updateUsers();
            message.success('Usuário deletado com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar usuário: ', error);
            message.error('Erro ao deletar usuário!');
        }
    };

    /*const handleSearch = async (searchValue: number) => {
        try {
            const user = await getUser(searchValue, uid);
            return user;
        } catch (error) {
            console.error('Erro ao buscar usuário: ', error);
            message.error('Erro ao buscar usuário');
        }
    };*/

    const handleClearSearch = () => {
        updateUsers();
        setSearchValue('');
        setIsSearching(false);
    };

    return {
        form,
        onFinish,
        logins,
        updateUsers,
        selectedLogin,
        setSelectedLogin,
        showModal,
        modalVisible,
        setModalVisible,
        handleEdit,
        editMode,
        handleCancel,
        handleDelete,
        shouldResetForm,
        setShouldResetForm,
        setEditMode,
        searchValue,
        setSearchValue,
        isSearching, 
        setIsSearching,
        handleClearSearch,
        updateDataTable,
        spinning,
        //handleSearch,
        searchParam,
        setSearchParam,
        handleKeyPress
    };

}