/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { MovementInterface } from "../intefaces/MovementInterface";
import { deleteMovement, getAllMovements, getMovement } from "../service/movementService";
import { getPerson } from "../service/personService";

export const useHookMovement = (uid?: number, companyId?: number[], role?: string) => {
    const [form] = Form.useForm<MovementInterface>();
    const [movements, setMovements] = useState<MovementInterface[]>([]);
    const [selectedMovement, setSelectedMovement] = useState<MovementInterface>();
    const [searchValue, setSearchValue] = useState('');
    const [searchParam, setSearchParam] = useState<string>('code');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);

    useEffect(() => {
        const fetchMovements = async () => {
            try {
                const movementData = await getAllMovements(uid, companyId, role);
                setMovements(movementData);
            } catch (error) {
                console.error('Erro ao buscar movimentações:', error);
            }
        };

        fetchMovements();

    }, []);

    const updateMovements = async () => {
        try {
            const movementData = await getAllMovements(uid, companyId, role);
            setMovements(movementData);
        } catch (error) {
            console.error('Erro ao buscar movimentações:', error);
        }
    };

    const handleDelete = async (record?: number) => {
        try {
            await deleteMovement(record, uid);
            updateMovements();
            message.success('Movimentação excluido com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar movimentação: ', error);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, param: string, value: string) => {
        if (event.key === 'Enter') {
            updateDataTable(param, value);
        } else if (event.key === 'Backspace') {
            handleClearSearch();
        }
    };

    const updateDataTable = async (param: string, value: string) => {
        try {
            if (value === "") {
                message.error("Campo de busca não pode ser vázio!")
                return;
            }

            let findMovement: React.SetStateAction<MovementInterface[]>

            if(param === 'code') {
                findMovement = await getMovement(param, value, uid);
            } else {
                if (param === "status") {
                    value = value.substring(0, 2).toUpperCase();
                    value = '*' + value;
                } else if(param === "person_id") {
                    value = '*' + value;
                    const findPerson = await getPerson("person_name", value, uid);
                    if(findPerson.length <= 0) {
                        message.error("Nenhuma movimentação encontrada deste cliente!")
                        return;
                    }
    
                    value = findPerson[0].person_id.toString();
                }

                findMovement = await getMovement(param, value, uid);
            }

            setSpinning(true);

            setTimeout(() => {
                if (findMovement) {
                    setMovements(findMovement);
                    //setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                } 

                message.error('Nenhuma movimentação encontrada com esse ID!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar movimentação: ', error);
            message.error('Erro ao buscar movimentação');
        }
    };

    const showModal = () => {
        setModalVisible(true);
        setShouldResetForm(true);
    };

    const handleEdit = (record: MovementInterface) => {
        setSelectedMovement(record);
        setEditMode(true);
        setModalVisible(true);
        setShouldResetForm(false);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false);
        setShouldResetForm(true);
        setSearchValue('');
    };

    const handleClearSearch = () => {
        updateMovements();
        setSearchValue('');
        setIsSearching(false);
    };

    return { 
        movements, 
        updateMovements, 
        handleDelete, 
        updateDataTable, 
        searchValue, 
        isSearching, 
        setSearchValue,
        setIsSearching,
        spinning,
        showModal,
        modalVisible,
        handleEdit,
        editMode,
        selectedMovement,
        handleCancel,
        handleClearSearch,
        shouldResetForm,
        setEditMode,
        setShouldResetForm,
        setSearchParam,
        searchParam,
        handleKeyPress
    };
}