/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Form, Input, Button, Select, Spin } from "antd";
import { PersonInterface } from "../intefaces/PersonInterface";
import { useHookFormPerson } from "../hooksForm/useHookFormPerson";
import { UserInterface } from "../intefaces/UserInterface";

const { Option } = Select;

interface FormPersonProps {
  updateTable: () => void;
  editMode: boolean;
  initialValues?: PersonInterface;
  modalVisible: boolean;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  setEditMode: (visible: boolean) => void;
  cities?: any[] | null;
  user?: UserInterface;
  orientation?: string;
}
const FormPerson: React.FC<FormPersonProps> = ({
  updateTable,
  editMode,
  initialValues,
  shouldResetForm,
  setShouldResetForm,
  cities,
  user,
  orientation,
}) => {
  const {
    form,
    onFinish,
    handleCpfChange,
    handlePhoneChange,
    handleSearchPostalCode,
    spinning,
    spinningText,
  } = useHookFormPerson(
    updateTable,
    editMode,
    shouldResetForm,
    setShouldResetForm,
    cities,
    initialValues,
    user
  );

  return (
    <div
      className={
        orientation === "vertical"
          ? "flex flex-col justify-between w-full h-full mb-5"
          : "flex flex-row justify-between w-full h-1/2 mb-5"
      }
    >
      <Spin spinning={spinning} tip={spinningText} fullscreen />
      <div
        className={
          orientation === "vertical"
            ? "justify-start w-80 mb-10"
            : "justify-start w-80"
        }
      >
        <h1 className="text-2xl flex flex-col">
          Detalhes do Cliente
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="person_code"
            label="ID"
            className={orientation === "vertical" ? "w-40 mr-3" : "w-1/1 mr-3"}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="person_name"
            label="Nome"
            className={orientation === "vertical" ? "w-full" : "w-10/12 mr-2"}
            rules={[{ required: true, message: "Insira um nome!" }]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="person_type"
            label="Tipo"
            className={orientation === "vertical" ? "w-52" : "w-1/4 mr-3"}
            rules={[{ required: true, message: "Selecione um tipo!" }]}
          >
            <Select>
              <Option value="client">Cliente</Option>
              <Option value="supplier">Fornecedor</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="person_status"
            label="Status"
            className={orientation === "vertical" ? "w-52" : "w-1/4 mr-3"}
          >
            <Select defaultValue={"A"} style={{ textTransform: "uppercase" }}>
              <Option value="A" style={{ textTransform: "uppercase" }}>Ativo</Option>
              <Option value="I" style={{ textTransform: "uppercase" }}>Inativo</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="person_cpfOrCnpj"
            label="CPF/CNPJ"
            className={orientation === "vertical" ? "w-52" : "w-1/3 mr-3"}
          >
            <Input onChange={handleCpfChange} maxLength={18} />
          </Form.Item>
          <Form.Item
            name="person_phone"
            label="Celular"
            className={orientation === "vertical" ? "w-52" : "w-1/3 mr-2"}
          >
            <Input onChange={handlePhoneChange} maxLength={16} />
          </Form.Item>
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="person_email"
            label="Email"
            className={orientation === "vertical" ? "w-full" : "w-1/2 mr-3"}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }}/>
          </Form.Item>
          <Form.Item
            name="person_cep"
            label="CEP"
            className={orientation === "vertical" ? "w-52" : "w-1/4 mr-3"}
          >
            <Input onBlur={(e) => handleSearchPostalCode(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="person_city"
            label="Cidade"
            className={orientation === "vertical" ? "w-52" : "w-1/4 mr-2"}
          >
            <Select
              showSearch
              filterOption={(input, option) => {
                if (!option) return true;
                if (!option.label) return false;
                return option.label
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              style={{ textTransform: "uppercase" }}
            >
              {cities &&
                cities.map((city) => (
                  <Option
                    label={city.city_description}
                    value={city.city_id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {city.city_description}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="person_neighborhood"
            label="Bairro"
            className={orientation === "vertical" ? "w-full" : "w-1/3 mr-3"}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="person_number"
            label="Nº Endereço"
            className="w-28 mr-3"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="person_address"
            label="Endereço"
            className={orientation === "vertical" ? "w-full" : "w-1/2"}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
        </div>
        <div className="flex flex-row justify-end">
          <Button
            type="default"
            className="w-1/5"
            htmlType="submit"
            style={{ backgroundColor: "green", color: "white" }}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormPerson;
