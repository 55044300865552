/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message } from "antd";
import { useEffect, useState } from "react";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { UserCompany } from "../intefaces/UserCompany";
import { UserInterface } from "../intefaces/UserInterface";
import { createUserCompany, deleteUserCompany, getAllUserCompany } from "../service/userCompanyService";

export const useHookFormUserCompany = (
    updateTable: () => void,
    companies?: CompanyInterface[],
    linkedUser?: UserInterface,
    user?: UserInterface
) => {
    const [form] = Form.useForm<UserCompany>();
    const [spinning, setSpinning] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<CompanyInterface[]>();
    const [uc, setUc] = useState<UserCompany[]>([]);

    useEffect(() => {
        updateUC(user?.tenant.id, linkedUser?.id);
    }, []);

    const onFinish = async (values: UserCompany) => {
        try {
            setSpinning(true);

            alert(values)

            values.companies = selectedCompany;
            values.user_code = 0;
            values.user = linkedUser!;

            await createUserCompany(values);
            message.success('Usuário vinculado com sucesso');
            form.resetFields();
            setSelectedCompany([]);
            updateUC(user?.tenant.id, linkedUser?.id);
            setSpinning(false);
        } catch (error) {
            console.error('Erro ao criar usuário vinculado:', error);
            message.error('Erro ao criar usuário vinculado');
        }
    };

    const handleCompanySelect = (value: string[]) => {
        const selectedCompanies = value.map(companyId =>
            companies!.find(company => company.company_id === parseInt(companyId, 10))
        ).filter(company => company !== undefined) as CompanyInterface[];

        setSelectedCompany(selectedCompanies);
    };

    const updateUC = async (uidDef?: number, userIdDef?: number) => {
        try {
            const userCompanyData = await getAllUserCompany(uidDef, userIdDef);
            setUc(userCompanyData);
        } catch (error) {
            console.error('Erro ao buscar usuários vinculados: ', error);
        }
    }

    const handleDelete = async (record: number) => {
        try {
            await deleteUserCompany(record, user?.tenant.id);
            updateUC(user?.tenant.id, linkedUser?.id);
            message.success('Usuário vinculado deletado com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar usuário vinculado: ', error);
            message.error('Erro ao deletar usuário vinculado!');
        }
    };

    return {
        form,
        onFinish,
        spinning,
        handleCompanySelect,
        linkedUser,
        uc,
        updateUC,
        handleDelete
    }

}