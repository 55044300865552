/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserInterface } from "../intefaces/UserInterface";

interface AuthContextType {
  user: UserInterface | null;
  login: (tenant: UserInterface) => void;
  logout: () => void;
  logado: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

const SESSION_DURATION = 1 * 60 * 60 * 1000;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserInterface | null>(null);
  const [logado, setLogado] = useState(false);

  const login = (user: UserInterface) => {
    const expirationTime = new Date().getTime() + SESSION_DURATION;
    setUser(user);
    setLogado(true);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("expirationTime", expirationTime.toString());
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("expirationTime");

    document.cookie.split(";").forEach((cookie) => {
      const cookieName = cookie.split("=")[0].trim();
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });

    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }

    setTimeout(() => {
      window.location.href = "/";
      window.location.reload();
    }, 200);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedExpirationTime = localStorage.getItem("expirationTime");

    if (storedUser && storedExpirationTime) {
      const currentTime = new Date().getTime();
      const expirationTime = parseInt(storedExpirationTime, 10);

      if (currentTime < expirationTime) {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        setLogado(true);
      } else {
        logout();
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, logado }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth deve ser usado dentro de um AuthProvider");
  }
  return context;
};

export { AuthContext };

