/* eslint-disable @typescript-eslint/no-unused-vars */
import validator from 'cpf-cnpj-validator';
import { api } from '../api/api';
export const validateCpfCnpj = (value: string) => {

  const Joi = require('@hapi/joi').extend(validator);
  const cpfSchema = Joi.document().cpf();
  const cnpjSchema = Joi.document().cnpj();

  if (value.length === 14) {
    const numericValue = value.replace(/\D/g, '');
    const { error } = cpfSchema.validate(numericValue);
    if (error) {
      return { error: { message: 'CPF inválido' } };
    }
  } else if (value.length === 18) {
    const numericValue = value.replace(/\D/g, '');
    const { error } = cnpjSchema.validate(numericValue);
    if (error) {
      return { error: { message: 'CNPJ inválido' } };
    }
  } else {
    return { error: { message: 'Formato de CPF/CNPJ inválido' } };
  }

  return { value };
};

export const searchPostalCode = async (cep: string) => {
  try {
    const response = await api.get(`https://viacep.com.br/ws/${cep}/json`);
    return response.data;
  } catch (error) {
    console.log(error)
  }
}

export const resizeImage = (file: File, maxWidth: number, maxHeight: number, callback: (dataUrl: string) => void) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (event) => {
    const img = new Image();
    img.src = event.target?.result as string;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      canvas.width = width;
      canvas.height = height;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, width, height);

      const dataUrl = canvas.toDataURL('image/png');
      callback(dataUrl);
    };
  };
};


export function convertToFullDate(dateString: string) {
  const currentYear = new Date().getFullYear();
  const fullDateString = `${currentYear}-${dateString.slice(3, 5)}-${dateString.slice(0, 2)}`;

  const date = new Date(fullDateString);
  const fullDateTime = new Date(date.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds()));

  const formattedDate = fullDateTime.toISOString().replace('T', ' ').slice(0, -1);

  return formattedDate;
}