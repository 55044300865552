import axios, { AxiosError } from 'axios';
import { api } from '../api/api';
import { LocalizationInterface } from '../intefaces/LocalizationInterface';


export const createLocalization = async (localization: LocalizationInterface, tId?: number): Promise<LocalizationInterface> => {
    try {
        const response = await api.post(`/new/localization/${tId}`, localization);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getLocalization = async (param: string, value: string, tId?: number): Promise<LocalizationInterface[]> => {
    try {
        const response = await api.get(`/find/one/localization/${param}/${value}/${tId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Localização não encontrada');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar localização:' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
}

export const getAllLocalizations = async (tId?: number, companyId?: number[], role?: string) => {
    if (tId === undefined) return [];
    try {
        const response = await api.get(`/find/all/localizations/${tId}/${companyId!.length > 0 ? companyId : [0]}/${role}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Localizações não encontradas');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao buscar localizações: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
};

export const updateLocalization = async (id: number, localization: LocalizationInterface, tId?: number): Promise<LocalizationInterface> => {
    try {
        const response = await api.put(`/update/localization/${id}/${tId}`, localization);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao atualizar a localização!');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao atualizar o localização: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
}

export const deleteLocalization = async (id: number, tId?: number) => {
    try {
        const response = await api.delete(`/delete/localization/${id}/${tId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao deletar a localização!');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error('Erro ao deletar o localização: ' + (error as AxiosError).message);
        } else {
            throw error;
        }
    }
}

