/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message } from "antd";
import { useState } from "react";
import { updateUserPassword } from "../service/userService";

export const useHookUser = (id?: number, tId?: number) => {
    const [ form ] = Form.useForm<any>();
    const [success, setSuccess] = useState<boolean>(false);

    const onFinish = async (values: any) => {
        try {   
            await updateUserPassword(values.old_pass, values.new_pass, id, tId);
            message.success('Senha atualziada com sucesso!');
            setSuccess(true);
            form.resetFields();
        } catch (error) {
            console.error('Erro ao atualizar senha:', error);
            message.error('Erro ao atualizar senha');
        }
    };

    return {
        form,
        onFinish,
        success
    }

}