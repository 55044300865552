import {
  AuditOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CustomerServiceOutlined,
  DollarCircleOutlined,
  FileSearchOutlined,
  SmileOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import React from "react";
import BackOne from "../assets/images/background-one.jpg";
import BackThree from "../assets/images/background-three.jpg";
import BackTwo from "../assets/images/background-two.jpg";

interface UseContentProps {
  selectedIndex: number;
}

export const Content = ({
  selectedIndex,
}: UseContentProps): React.ReactElement[] => {
  return [
    <div
      className={
        selectedIndex === 0
          ? "flex flex-col items-center justify-center h-full w-full absolute transition-all duration-700 opacity-100 translate-x-0"
          : "flex flex-col items-center justify-center h-full w-full absolute transition-all duration-700 opacity-0 translate-x-full"
      }
      key="tab0"
    >
      <div
        className="flex flex-col items-center justify-center"
        style={{ width: "90%" }}
      >
        <img
          src={BackOne}
          className={
            selectedIndex === 0
              ? "absolute w-full h-full transition-all duration-500 opacity-100"
              : "absolute w-full h-full transition-all duration-500 opacity-0"
          }
          alt="Ícone"
        />

        <div className="w-full h-full absolute z-20 bg-black opacity-75"></div>

        <div
          className={
            selectedIndex === 0
              ? "flex flex-col items-center justify-center w-1/2 h-3/5 absolute left-16 transition-all duration-1000 delay-500 opacity-100 z-40"
              : "flex flex-col items-center justify-center w-1/2 h-3/5 absolute left-16 transition-all duration-500 opacity-0 z-30"
          }
        >
          <div
            className="flex flex-col p-4 h-full overflow-y-scroll"
            style={{
              width: "100%",
              scrollbarWidth: "thin",
              scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
            }}
          >
            <h2 className="text-2xl font-bold mb-4 text-orange-500">
              Gestão Patrimonial Eficiente
            </h2>
            <p className="text-gray-200 mb-4">
              Nossa plataforma de gestão patrimonial oferece uma solução
              completa para o controle e monitoramento de todos os seus ativos,
              garantindo eficiência e segurança.
            </p>
            <p className="text-gray-200 mb-6">
              Nossos membros contam com suporte incomparável, proporcionando
              acesso direto 24 horas por dia, 7 dias por semana à nossa equipe
              de especialistas através de um portal amigável que combina o
              melhor das ferramentas modernas de comunicação.
            </p>
            <p className="text-gray-200 mb-6">
              Nossa plataforma é escolhida por muitos devido aos seguintes
              benefícios:
            </p>
            <div className="mb-6">
              <CheckCircleOutlined className="text-2xl text-blue-500 mr-2" />
              <span className="text-xl font-semibold text-yellow-500">
                Gestão de Ativos
              </span>
              <p className="text-gray-500 mt-2">
                Nossa plataforma oferece uma gestão eficiente de todos os ativos
                patrimoniais, permitindo o controle total sobre inventário,
                manutenção e depreciação.
              </p>
            </div>
            <div className="mb-6">
              <CustomerServiceOutlined className="text-2xl text-blue-500 mr-2" />
              <span className="text-xl font-semibold text-blue-500">
                Suporte Personalizado
              </span>
              <p className="text-gray-500 mt-2">
                Oferecemos suporte personalizado para ajudá-lo a tirar o máximo
                proveito da nossa plataforma, com atendimento disponível 24/7.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>,

    <div
      className={
        selectedIndex === 1
          ? "flex flex-col items-center justify-center h-full w-full absolute transition-all duration-700 opacity-100 translate-x-0"
          : "flex flex-col items-center justify-center h-full w-full absolute transition-all duration-700 opacity-0 translate-x-full"
      }
      key="tab1"
    >
      <img
        src={BackTwo}
        className={
          selectedIndex === 1
            ? "absolute w-full h-full transition-all duration-500 opacity-100"
            : "absolute w-full h-full transition-all duration-500 opacity-0"
        }
        alt="Imagem dois"
      />

      <div className="w-full h-full absolute z-20 bg-black opacity-75"></div>

      <div
        className={
          selectedIndex === 1
            ? "flex flex-col items-center justify-center w-1/2 h-3/5 absolute left-16 transition-all duration-1000 delay-500 opacity-100 z-40"
            : "flex flex-col items-center justify-center w-1/2 h-3/5 absolute left-16 transition-all duration-500 opacity-0 z-30"
        }
      >
        <div
          className="flex flex-col p-4 h-full overflow-y-scroll"
          style={{
            width: "100%",
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
          }}
        >
          <h2 className="text-2xl font-bold mb-4 text-orange-500">
            Relatórios e Integração
          </h2>
          <p className="text-gray-200 mb-4">
            Facilite a tomada de decisões com relatórios detalhados e integre
            nossa plataforma com outros sistemas empresariais para uma gestão
            mais coesa.
          </p>
          <p className="text-gray-200 mb-6">
            Integre nossa plataforma com seus sistemas empresariais existentes
            para uma gestão mais eficiente e coesa.
          </p>
          <p className="text-gray-200 mb-6">
            Os usuários valorizam nossa plataforma pelos seguintes aspectos:
          </p>
          <div className="mb-6">
            <FileSearchOutlined className="text-2xl text-blue-500 mr-2" />
            <span className="text-xl font-semibold text-yellow-500">
              Relatórios Detalhados
            </span>
            <p className="text-gray-500 mt-2">
              Gere relatórios detalhados sobre a condição e o desempenho dos
              seus ativos, facilitando a tomada de decisões informadas.
            </p>
          </div>
          <div className="mb-6">
            <SyncOutlined className="text-2xl text-blue-500 mr-2" />
            <span className="text-xl font-semibold text-green-600">
              Integração de Sistemas
            </span>
            <p className="text-gray-500 mt-2">
              Integre nossa plataforma com outros sistemas empresariais para uma
              visão unificada e uma gestão mais coesa dos seus recursos.
            </p>
          </div>
          <div className="mb-6">
            <AuditOutlined className="text-2xl text-blue-500 mr-2" />
            <span className="text-xl font-semibold text-blue-500">
              Auditorias Facilitadas
            </span>
            <p className="text-gray-500 mt-2">
              Simplifique o processo de auditoria com nossas ferramentas que
              ajudam a rastrear e verificar todos os ativos de forma precisa e
              eficiente.
            </p>
          </div>
        </div>
      </div>
    </div>,

    <div
      className={
        selectedIndex === 2
          ? "flex flex-col items-center justify-center h-full w-full absolute transition-all duration-700 opacity-100 translate-x-0"
          : "flex flex-col items-center justify-center h-full w-full absolute transition-all duration-700 opacity-0 translate-x-full"
      }
      key="tab2"
    >
      <img
        src={BackThree}
        className={
          selectedIndex === 2
            ? "absolute w-full h-full transition-all duration-500 opacity-100"
            : "absolute w-full h-full transition-all duration-500 opacity-0"
        }
        alt="Imagem três"
      />

      <div className="w-full h-full absolute z-20 bg-black opacity-75"></div>

      <div
        className={
          selectedIndex === 2
            ? "flex flex-col items-center justify-center w-1/2 h-3/5 absolute left-16 transition-all duration-1000 delay-500 opacity-100 z-40"
            : "flex flex-col items-center justify-center w-1/2 h-3/5 absolute left-16 transition-all duration-500 opacity-0 z-30"
        }
      >
        <div
          className="flex flex-col p-4 h-full overflow-y-scroll"
          style={{
            width: "100%",
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
          }}
        >
          <h2 className="text-2xl font-bold mb-4 text-orange-500">
            Otimização e Planejamento
          </h2>
          <p className="text-gray-200 mb-4">
            Otimize seus custos e planeje a manutenção dos seus ativos para
            aumentar a eficiência operacional e prolongar a vida útil dos seus
            recursos.
          </p>
          <p className="text-gray-200 mb-6">
            Planeje a manutenção dos seus ativos para prolongar a vida útil e
            evitar interrupções inesperadas.
          </p>
          <p className="text-gray-200 mb-6">
            Escolha nossa plataforma para aproveitar os seguintes recursos:
          </p>
          <div className="mb-6">
            <DollarCircleOutlined className="text-2xl text-blue-500 mr-2" />
            <span className="text-xl font-semibold text-yellow-500">
              Otimização de Custos
            </span>
            <p className="text-gray-500 mt-2">
              Identifique oportunidades para reduzir custos operacionais através
              da gestão eficiente dos ativos e do prolongamento da vida útil dos
              mesmos.
            </p>
          </div>
          <div className="mb-6">
            <CalendarOutlined className="text-2xl text-blue-500 mr-2" />
            <span className="text-xl font-semibold text-green-600">
              Planejamento de Manutenção
            </span>
            <p className="text-gray-500 mt-2">
              Programe e acompanhe manutenções preventivas e corretivas,
              minimizando o tempo de inatividade e aumentando a longevidade dos
              ativos.
            </p>
          </div>
          <div className="mb-6">
            <SmileOutlined className="text-2xl text-blue-500 mr-2" />
            <span className="text-xl font-semibold text-blue-500">
              Interface Intuitiva
            </span>
            <p className="text-gray-500 mt-2">
              Nossa interface intuitiva facilita a navegação e o uso da
              plataforma, proporcionando uma experiência de usuário agradável e
              eficiente.
            </p>
          </div>
        </div>
      </div>
    </div>,
  ];
};
