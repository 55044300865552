/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { SituationInterface } from "../intefaces/SituationInterface";
import { deleteSituation, getAllSituation, getSituation } from "../service/situationService";

export const useHookSituation = (uid?: number, companyId?: number[], role?: string) => {
    const [situations, setSituations] = useState<SituationInterface[]>([]);
    const [selectedSituation, setSelectedSituation] = useState<SituationInterface>();
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchParam, setSearchParam] = useState<string>('situation_code');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);

    useEffect(() => {
        const fetchSituation = async () => {
            try {
                const situationData = await getAllSituation(uid, companyId, role);
                setSituations(situationData);
            } catch (error) {
                console.error('Erro ao buscar situações: ', error);
            }
        };

        fetchSituation();
    }, []);


    const updateSituations = async () => {
        try {
            const situationData = await getAllSituation(uid, companyId, role);
            setSituations(situationData);
        } catch (error) {
            console.error('Erro ao buscar situações: ', error);
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, param: string, value: string) => {
        if (event.key === 'Enter') {
            updateDataTable(param, value);
        } else if (event.key === 'Backspace') {
            handleClearSearch();
        }
    }

    const updateDataTable = async (param: string, value: string) => {
        try {

            if(value === "") {
                message.error("Campo de busca não pode ser vázio!")
                return;
            }

            let findSituation: React.SetStateAction<SituationInterface[]>;

            if(param === 'situation_code') {
                findSituation = await getSituation(param, value, uid);
            } else {
                value = '*' + value;
                findSituation = await getSituation(param, value, uid);
            }

            setSpinning(true);

            setTimeout(() => {
                if (findSituation) {
                    setSituations(findSituation);
                    //setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                } 

                message.error('Nenhuma situação encontrada!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar situação: ', error);
            message.error('Erro ao buscar situação');
        }
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleEdit = (record: SituationInterface) => {
        setSelectedSituation(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
        updateSituations();
    };

    const handleDelete = async (record: number) => {
        try {
            await deleteSituation(record, uid);
            updateSituations();
            message.success('Situação deletado com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar situação: ', error);
            message.error('Erro ao deletar situação!');
        }
    };

    const handleClearSearch = () => {
        updateSituations();
        setSearchValue('');
        setIsSearching(false);
    };

    return {
        situations,
        updateSituations,
        selectedSituation,
        setSelectedSituation,
        showModal,
        modalVisible,
        setModalVisible,
        handleEdit,
        editMode,
        handleCancel,
        handleDelete,
        shouldResetForm,
        setShouldResetForm,
        setEditMode,
        searchValue,
        setSearchValue,
        isSearching, 
        setIsSearching,
        handleClearSearch,
        updateDataTable,
        spinning,
        searchParam,
        setSearchParam,
        handleKeyPress
    };

}