/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { UploadOutlined } from "@ant-design/icons";
import { Avatar, Button, Form, Input, Select, Spin, Upload } from "antd";
import React from "react";
import { useHookFormCompany } from "../hooksForm/useHookFormCompany";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { UserInterface } from "../intefaces/UserInterface";
import { CpfOrCnpjMask } from "../utils/Masks";
import { validateCpfCnpj } from "../utils/UseCases";

const { Option } = Select;

interface FormCompanyProps {
  updateTable: () => void;
  editMode: boolean;
  initialValues?: CompanyInterface;
  modalVisible: boolean;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  setEditMode: (visible: boolean) => void;
  cities?: any[] | null;
  user?: UserInterface;
  orientation?: string;
  setModalVisible: (visible: boolean) => void;
}

const FormCompany: React.FC<FormCompanyProps> = ({
  updateTable,
  editMode,
  initialValues,
  setEditMode,
  cities,
  shouldResetForm,
  setShouldResetForm,
  user,
  setModalVisible,
  orientation,
}) => {
  const {
    form,
    onFinish,
    logo,
    handleChange,
    handleRemove,
    handleCpfChange,
    handlePhoneChange,
    handleSearchPostalCode,
    spinning,
    spinningText,
  } = useHookFormCompany(
    updateTable,
    setModalVisible,
    editMode,
    setShouldResetForm,
    setEditMode,
    initialValues,
    shouldResetForm,
    cities,
    user
  );

  return (
    <div
      className={
        orientation === "vertical"
          ? "flex flex-col justify-between w-full h-full mb-5"
          : "flex flex-row justify-between w-full h-1/2 mb-5"
      }
    >
      <Spin spinning={spinning} tip={spinningText} fullscreen />
      <div
        className={
          orientation === "vertical"
            ? "justify-start w-80 mb-10"
            : "justify-start w-80"
        }
      >
        <h1 className="text-2xl flex flex-col">
          Detalhes da empresa
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          {logo ? (
            <Avatar
              src={logo}
              size={90}
              className="mb-6 shadow-md"
              onClick={handleRemove}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Form.Item name="company_logo" label="Logo" className="w-1/1 mr-3">
              <Upload
                className="w-full flex-row "
                accept="image/*"
                listType="picture"
                maxCount={1}
                onChange={handleChange}
                beforeUpload={() => false}
              >
                <Button icon={<UploadOutlined />}>Logo da Empresa</Button>
              </Upload>
            </Form.Item>
          )}
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="company_code"
            label="ID"
            className={orientation === "vertical" ? "w-40 mr-3" : "w-1/1 mr-3"}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="company_name"
            label="Nome"
            className={orientation === "vertical" ? "w-full" : "w-10/12"}
            rules={[{ required: true, message: "Insira um nome!" }]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="company_fantasy"
            label="Fantasia"
            className={orientation === "vertical" ? "w-full" : "w-1/2 mr-3"}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="company_cpfOrCnpj"
            label="CNPJ"
            className={orientation === "vertical" ? "w-60" : "w-1/3 mr-3"}
            rules={[
              { required: true, message: "Insira um CNPJ!" },
              () => ({
                validator(_, value) {
                  const validation = validateCpfCnpj(CpfOrCnpjMask(value));
                  if (validation.error) {
                    return Promise.reject(validation.error.message);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input onChange={handleCpfChange} maxLength={18} />
          </Form.Item>
          <Form.Item
            name="company_phone"
            label="Celular"
            className={orientation === "vertical" ? "w-60" : "w-1/4"}
          >
            <Input onChange={handlePhoneChange} maxLength={16} />
          </Form.Item>
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="company_email"
            label="Email"
            className={orientation === "vertical" ? "w-full" : "w-full"}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} className="w-full" />
          </Form.Item>
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="company_cep"
            label="CEP"
            className={orientation === "vertical" ? "w-60" : "w-1/5 mr-3"}
          >
            <Input onBlur={(e) => handleSearchPostalCode(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="company_number"
            label="Nº Endereço"
            className={orientation === "vertical" ? "w-60" : "w-28 mr-3"}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="company_city"
            label="Cidade"
            className={orientation === "vertical" ? "w-72" : "w-9/12"}
          >
            <Select
              showSearch
              filterOption={(input, option) => {
                if (!option) return true;
                if (!option.label) return false;
                return option.label
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {cities &&
                cities.map((city) => (
                  <Option
                    style={{ textTransform: "uppercase" }}
                    label={city.city_description}
                    value={city.city_id}
                  >
                    {city.city_description}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div
          className={
            orientation === "vertical"
              ? "flex flex-col w-full"
              : "flex flex-row w-full"
          }
        >
          <Form.Item
            name="company_neighborhood"
            label="Bairro"
            className={orientation === "vertical" ? "w-60" : "w-1/2 mr-3"}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="company_address"
            label="Endereço"
            className={orientation === "vertical" ? "w-full" : "w-1/2"}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
        </div>

        <div
          className={
            orientation === "vertical"
              ? "flex flex-row justify-start mb-4"
              : "flex flex-row justify-end mb-4"
          }
        >
          <Button
            type="primary"
            className="w-1/5"
            color="black"
            htmlType="submit"
            style={{ backgroundColor: "green" }}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormCompany;
