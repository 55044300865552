/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { UserCompany } from "../intefaces/UserCompany";
import { UserInterface } from "../intefaces/UserInterface";
import { getAllUserCompany, getUserCompany } from "../service/userCompanyService";

export const useHookUserCompany = (uid?: number, userId?: number, companyId?: number[]) => {
    const [userCompany, setUserCompany] = useState<UserCompany[]>([]);
    const [selectUserCompany, setSelectedUserCompany] = useState<UserCompany>();
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);
    const [user, setUser] = useState<UserInterface>();

    useEffect(() => {
        const fetchUserCompany = async () => {
            try {
                const userCompanyData = await getAllUserCompany(uid, userId);
                setUserCompany(userCompanyData);
            } catch (error) {
                console.error('Erro ao buscar usuários vinculados: ', error);
            }
        };

        fetchUserCompany();
    }, [uid, userId, companyId]);

    const updateUserCompany = async () => {
        try {
            const userCompanyData = await getAllUserCompany(uid, userId);
            setUserCompany(userCompanyData);
        } catch (error) {
            console.error('Erro ao buscar usuários vinculados: ', error);
        }
    }
    const updateDataTable = async (param: string, value: string) => {
        try {
            const findUserCompany = await getUserCompany(param, value, uid);
            setSpinning(true);

            setTimeout(() => {
                if (findUserCompany) {
                    setUserCompany([findUserCompany as UserCompany]);
                    setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                } 

                message.error('Nenhum usuário vinculado encontrado com esse ID!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar usuário vinculado: ', error);
            message.error('Erro ao buscar usuário vinculado');
        }
    };

    const showModal = (record: UserInterface) => {
        setModalVisible(true);
        setUser(record)
    };

    const handleEdit = (record: UserCompany) => {
        setSelectedUserCompany(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
    };

    const handleClearSearch = () => {
        updateUserCompany();
        setIsSearching(false);
    };

    return {
        userCompany,
        updateUserCompany,
        selectUserCompany,
        setSelectedUserCompany,
        showModal,
        modalVisible,
        setModalVisible,
        handleEdit,
        editMode,
        handleCancel,
        shouldResetForm,
        setShouldResetForm,
        setEditMode,
        searchValue,
        setSearchValue,
        isSearching, 
        setIsSearching,
        handleClearSearch,
        updateDataTable,
        spinning,
        user
    };

}