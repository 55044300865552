/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Form, Input, Button, Select, InputNumber, Spin } from "antd";
import { ContractInterface } from "../intefaces/ContractInterface";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { useHookFormContract } from "../hooksForm/useHookFormContract";
import { UserInterface } from "../intefaces/UserInterface";

const { Option } = Select;

interface FormContractProps {
  updateTable: () => void;
  editMode: boolean;
  initialValues?: ContractInterface;
  modalVisible: boolean;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  setEditMode: (visible: boolean) => void;
  companies?: CompanyInterface[];
  user?: UserInterface;
}

const FormContract: React.FC<FormContractProps> = ({
  updateTable,
  editMode,
  initialValues,
  shouldResetForm,
  setShouldResetForm,
  companies,
  user,
}) => {
  const { form, onFinish, spinning, spinningText } = useHookFormContract(
    updateTable,
    editMode,
    shouldResetForm,
    setShouldResetForm,
    companies,
    initialValues,
    user
  );

  return (
    <div className="flex flex-row justify-between w-full h-1/2 mb-5">
      <Spin spinning={spinning} tip={spinningText} fullscreen />
      <div className="justify-start w-80">
        <h1 className="text-2xl flex flex-col">
          Detalhes do Contrato
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <div className="flex flex-row w-full">
          <Form.Item name="contract_code" label="ID" className="w-1/1 mr-3">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="constract_description"
            label="Descrição"
            className="w-1/2 mr-3"
            rules={[
              { required: true, message: "Por favor insira uma descrição!" },
            ]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="contract_company"
            label="Empresa"
            className="w-1/4"
            rules={[{ required: true, message: "Selecione a empresa!" }]}
          >
            <Select style={{ textTransform: "uppercase" }}>
              {companies &&
                companies.map((company) => (
                  <Option
                    key={company.company_id}
                    value={company.company_id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {company.company_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row w-full">
          <Form.Item
            name="contract_time_type"
            label="Tempo"
            className="w-1/5 mr-3"
          >
            <Select>
              <Option value="HO">Hora</Option>
              <Option value="DI">Dia</Option>
              <Option value="SE">Semana</Option>
              <Option value="ME">Mês</Option>
              <Option value="AN">Ano</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="contract_value"
            label="Valor R$"
            className="w-1/5 mr-3"
          >
            <InputNumber className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row justify-end">
          <Button
            type="default"
            className="w-1/5"
            htmlType="submit"
            style={{ backgroundColor: "green", color: "white" }}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormContract;
