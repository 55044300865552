import { MovementInterface } from "../intefaces/MovementInterface";
import { Asset } from "../intefaces/Asset";

export const useHookMain = (movements: MovementInterface[], assets: Asset[]) => {
    const calculateTotal: any = (status: string) => {
        return movements.reduce((accMovement, movement) => {
          if (movement.status === status) {
            return (
              accMovement +
              movement.data.reduce(
                (accItem, item) => accItem + Number(item.total),
                0
              )
            );
          }
          return accMovement;
        }, 0);
      };
      
    
      const totalSumAberto = calculateTotal("AB");
      const totalSumPago = calculateTotal("PG");
    
      const formattedTotalSumAberto = () => {
        return new Intl.NumberFormat('pt-BR', { 
          style: 'currency', 
          currency: 'BRL', 
          minimumFractionDigits: 2, 
          maximumFractionDigits: 2 
        }).format(totalSumAberto);
      }

      const formattedTotalSumPago = () => {
        return new Intl.NumberFormat('pt-BR', { 
          style: 'currency', 
          currency: 'BRL', 
          minimumFractionDigits: 2, 
          maximumFractionDigits: 2 
        }).format(totalSumPago);
      }
      
      const assetCount = assets.length;

      return {
        formattedTotalSumAberto,
        formattedTotalSumPago,
        assetCount
      }
}