/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { PaymentMethodInterface } from "../intefaces/PaymentMethodInterface";
import { deletePaymentMethod, getAllPaymentMethods, getPaymentMethod } from "../service/paymentMethod";

export const useHookPaymentMethod = (uid?: number, companyId?: number[], role?: string) => {
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodInterface[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodInterface>();
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchParam, setSearchParam] = useState<string>('payment_method_code');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);

    useEffect(() => {
        const fetchPaymentMethod = async () => {
            try {
                const paymentMethodData = await getAllPaymentMethods(uid, companyId, role);
                setPaymentMethods(paymentMethodData);
            } catch (error) {
                console.error('Erro ao buscar metodos de pagamento: ', error);
            }
        };

        fetchPaymentMethod();
    }, []);


    const updatePaymentMethod = async () => {
        try {
            const paymentMethodData = await getAllPaymentMethods(uid, companyId, role);
            setPaymentMethods(paymentMethodData);
        } catch (error) {
            console.error('Erro ao buscar metodo de pagamento: ', error);
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, param: string, value: string) => {
        if (event.key === 'Enter') {
            updateDataTable(param, value);
        } else if (event.key === 'Backspace') {
            handleClearSearch();
        }
    }

    const updateDataTable = async (param: string, value: string) => {
        try {

            if (value === "") {
                message.error("Campo de busca não pode ser vázio!")
                return;
            }

            let findPaymentMethod: React.SetStateAction<PaymentMethodInterface[]>;

            if(param === 'payment_method_code') {
                findPaymentMethod = await getPaymentMethod(param, value, uid);
            } else {
                value = '*' + value;
                findPaymentMethod = await getPaymentMethod(param, value, uid);
            }

            setSpinning(true);

            setTimeout(() => {
                if (findPaymentMethod) {
                    setPaymentMethods(findPaymentMethod);
                    //setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                }

                message.error('Nenhum metodo de pagamento encontrado!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar metodo de pagamento: ', error);
            message.error('Erro ao buscar metodo de pagamento');
        }
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleEdit = (record: PaymentMethodInterface) => {
        setSelectedPaymentMethod(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
    };

    const handleDelete = async (record: number) => {
        try {
            await deletePaymentMethod(record, uid);
            updatePaymentMethod();
            message.success('Metodo de pagamento deletado com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar metodo de pagamento: ', error);
            message.error('Erro ao deletar metodo de pagamento!');
        }
    };

    const handleClearSearch = () => {
        updatePaymentMethod();
        setSearchValue('');
        setIsSearching(false);
    };

    return {
        paymentMethods,
        updatePaymentMethod,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        showModal,
        modalVisible,
        setModalVisible,
        handleEdit,
        editMode,
        handleCancel,
        handleDelete,
        shouldResetForm,
        setShouldResetForm,
        setEditMode,
        searchValue,
        setSearchValue,
        isSearching,
        setIsSearching,
        handleClearSearch,
        updateDataTable,
        spinning,
        searchParam,
        setSearchParam,
        handleKeyPress
    };

}