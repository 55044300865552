/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  AimOutlined,
  ApartmentOutlined,
  AppstoreAddOutlined,
  AppstoreOutlined,
  CreditCardOutlined,
  DollarOutlined,
  FileProtectOutlined,
  HomeOutlined,
  InboxOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  SafetyOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SwapOutlined,
  TeamOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import { Button, Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import "../assets/styles/home.css";
import AvatarMenu from "../components/AvatarMenu";
import ThemeToggleButton from "../components/ThemeToggleButton";
import { useAuth } from "../contexts/AuthContext";
import { useTheme } from "../contexts/ThemeContext";
import { darkTheme, lightTheme } from "../styles";

interface MenuItem {
  label: string;
  path: string;
  icon: JSX.Element;
  children?: MenuItem[];
}

const items: MenuItem[] = [
  { label: "Menu Principal", path: "/home/main", icon: <HomeOutlined /> },
  { label: "Empresas", path: "/home/company", icon: <ShopOutlined /> },
  {
    label: "Ativos/Patrimônios",
    path: "/home/assets",
    icon: <InboxOutlined />,
    children: [
      {
        label: "Meus Patrimônios",
        path: "/home/assets",
        icon: <AppstoreOutlined />,
      },
      {
        label: "Localizações",
        path: "/home/localizations",
        icon: <AimOutlined />,
      },
      {
        label: "Tipos/Grupos",
        path: "/home/groups",
        icon: <AppstoreAddOutlined />,
      },
      {
        label: "Situações",
        path: "/home/situations",
        icon: <ApartmentOutlined />,
      },
      {
        label: "Contratos",
        path: "/home/contracts",
        icon: <FileProtectOutlined />,
      },
    ],
  },
  {
    label: "Pessoas",
    path: "/home/persons",
    icon: <UsergroupAddOutlined />,
    children: [
      { label: "Cliente", path: "/home/persons", icon: <TeamOutlined /> },
      {
        label: "Responsável",
        path: "/home/responsible",
        icon: <SafetyOutlined />,
      },
    ],
  },
  {
    label: "Movimentações",
    path: "/home/movements",
    icon: <SwapOutlined />,
    children: [
      { label: "Pedido", path: "/home/order", icon: <ShoppingCartOutlined /> },
      //{ label: 'Transferência', path: '/home/transfer', icon: <VerticalAlignTopOutlined /> }
    ],
  },
  {
    label: "Financeiro",
    path: "/home/financials",
    icon: <DollarOutlined />,
    children: [
      //{ label: 'Faturamento', path: '/home/invoicing', icon: <FileDoneOutlined />, },
      {
        label: "Formas de Pagamento",
        path: "/home/paymentMethods",
        icon: <CreditCardOutlined />,
      },
      //{ label: 'Despesas', path: '/home/expenses', icon: <FallOutlined /> }
    ],
  },
  { label: "Dashboard", path: "/home/dashboard", icon: <PieChartOutlined /> },
  /*{ label: 'Jsreport Studio', path: '/home/jsreport-studio', icon: <AppstoreAddOutlined /> },*/
  {
    label: "Configurações",
    path: "/home/settings",
    icon: <SettingOutlined />,
    children: [
      {
        label: "Usuários",
        path: "/home/settings/users",
        icon: <TeamOutlined />,
      },
    ],
  },
  // Adicione os outrs items do menu aqui
];

const { Header, Sider, Content } = Layout;

const renderSubMenu = (
  parentPath: string,
  items: MenuItem[],
  label: string,
  icon: JSX.Element
) => (
  <Menu.SubMenu key={parentPath} icon={icon} title={label}>
    {items.map((item) =>
      item.children ? (
        renderSubMenu(item.path, item.children, item.label, item.icon)
      ) : (
        <Menu.Item key={item.path} icon={item.icon}>
          <Link to={item.path}>{item.label}</Link>
        </Menu.Item>
      )
    )}
  </Menu.SubMenu>
);

const Home: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { theme, palette } = useTheme();
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    navigate("/home/main");
  }, []);

  const currentTheme = theme === "light" ? lightTheme : darkTheme;
  const { colorBgContainer, borderRadiusLG } = currentTheme;
  const fontColor = theme === "light" ? "#001529" : "#cfd5e1";

  const filteredItems = items.filter((item) => {
    if (user?.role === "user" && item.path === "/home/settings") {
      return false;
    }
    return true;
  });

  return (
    <Layout className="home">
      <Sider
        width={300}
        trigger={null}
        collapsible
        collapsed={!collapsed}
        style={{ background: colorBgContainer }}
      >
        <div className="flex items-center justify-start p-5 w-full h-20 transition-all ease-out duration-200 mb-7 mt-3">
          <img src={logo} style={{ width: collapsed ? 50 : 50 }} alt="Logo" />
          {collapsed && (
            <h1 className="text-2xl mt-2 ml-4 pt-2.5 pb-3 pl-4 w-full bg-slate-700 text-white rounded-md shadow-md font-semibold">
              iPatrimônio
            </h1>
          )}
        </div>
        <Menu
          theme={theme === "light" ? "light" : "dark"}
          defaultSelectedKeys={["/home/main"]}
          mode="inline"
          style={{ background: colorBgContainer }}
        >
          {filteredItems.map((item) =>
            item.children ? (
              renderSubMenu(item.path, item.children, item.label, item.icon)
            ) : (
              <Menu.Item key={item.path} icon={item.icon}>
                <Link to={item.path}>{item.label}</Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </Sider>
      <Layout style={{ background: palette.secondary }}>
        <Header
          style={{
            padding: 0,
            position: "relative",
            background: colorBgContainer,
            color: fontColor,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
              color: fontColor,
            }}
          />
          <h1 className="absolute right-20 top-0">{user?.username}</h1>
          <ThemeToggleButton />
          <AvatarMenu
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "23%",
              right: "2rem",
            }}
          />
        </Header>
        <Content
          style={{
            margin: location.pathname === "/home/main" ? 0 : "24px 16px",
            padding: location.pathname === "/home/main" ? 0 : 24, // Verificação da rota atual
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: location.pathname === "/home/main" ? 0 : borderRadiusLG,
          }}
          className="overflow-hidden"
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
