import axios, { AxiosError } from 'axios';
import { api } from '../api/api';
import { CompanyInterface } from '../intefaces/CompanyInterface';

export const createCompany = async (company: CompanyInterface, tId?: number): Promise<CompanyInterface> => {
    try {
      const response = await api.post<CompanyInterface>(`/new/company/${tId}`, company);
      return response.data;
    } catch (error) {
      throw error;
    }
};
  
export const getCompany = async (param: string, value: string, tId?: number): Promise<CompanyInterface[]> => {
    try {
      const response = await api.get(`/find/one/company/${param}/${value}/${tId}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao buscar a empresa');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao buscar a empresa ' + (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};
  
export const getAllCompanies = async (tId?: number, companyId?: number[], role?: string) => {
    if(tId === undefined) return [];
    try {
    const response = await api.get(`/find/all/companies/${tId}/${companyId!.length > 0 ? companyId : [0]}/${role}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao buscar os empresas');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao buscar empresas ' + (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};
  
export const updateCompany = async (tId?: number, company_id?: number, company?: CompanyInterface) => {
    try {
      const response = await api.put(`/update/company/${tId}/${company_id}`, company);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao atualizar a empresa');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao atualizar a empresa'+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};
  
export const deleteCompany = async (company_id: number, tId?: number) => {
    try {
      const response = await api.delete(`/delete/company/${tId}/${company_id}`);
      if(response.status === 200) {
        return response.data;
      } else {
        throw new Error('Erro ao deletar a empresa');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error('Erro ao deletar a empresa'+ (error as AxiosError).message);
      } else {
        throw error;
      }
    }
};
  