/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { ResponsibleInterface } from "../intefaces/ResponsibleInterface";
import { deleteResponsible, getAllResponsibles, getResponsible } from "../service/responsibleService";

export const useHookResponsible = (uid?: number, companyId?: number[], role?: string) => {
    const [responsible, setResponsible] = useState<ResponsibleInterface[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedResponsible, setSelectedResponsible] = useState<ResponsibleInterface>();
    const [shouldResetForm, setShouldResetForm] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchParam, setSearchParam] = useState<string>('responsible_code');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);

    useEffect(() => {
        const fetchResponsible = async () => {
            try {
                const responsibleData = await getAllResponsibles(uid, companyId, role);
                setResponsible(responsibleData);
            } catch (error) {
                console.error('Erro ao buscar responsaveis:', error);
            }
        };

        fetchResponsible();
    }, []);

    const updateResponsible = async () => {
        try {
            const responsibleData = await getAllResponsibles(uid, companyId, role);
            setResponsible(responsibleData);
        } catch (error) {
            console.error('Erro ao buscar responsavel:', error);
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, param: string, value: string) => {
        if (event.key === 'Enter') {
            updateDataTable(param, value);
        } else if (event.key === 'Backspace') {
            handleClearSearch();
        }
    }

    const updateDataTable = async (param: string, value: string) => {
        try {

            if (value === "") {
                message.error("Campo de busca não pode ser vázio!")
                return;
            }

            let findResponsible: React.SetStateAction<ResponsibleInterface[]>;

            if(param === 'responsible_code') {
                findResponsible = await getResponsible(param, value, uid);
            } else if (param === 'responsible_cpf') {
                value = value.replace("/", "");
                findResponsible = await getResponsible(param, value, uid);
            } else {
                value = '*' + value;
                findResponsible = await getResponsible(param, value, uid);
            }

            setSpinning(true);

            setTimeout(() => {
                if (findResponsible) {
                    setResponsible(findResponsible);
                    //setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                } 

                message.error('Nenhum responsavel encontrado!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar responsavel:', error);
            message.error('Erro ao buscar responsavel');
        }
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleEdit = (record: ResponsibleInterface) => {
        setSelectedResponsible(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
    };

    const handleDelete = async (record: number) => {
        try {
            await deleteResponsible(record, uid);
            updateResponsible();
            message.success('Cliente deletado com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar cliente:', error);
            message.error('Erro ao deletar cliente!');
        }
    };

    const handleClearSearch = () => {
        updateResponsible();
        setSearchValue('');
        setIsSearching(false);
    };

    return {
        responsible,
        updateResponsible,
        selectedResponsible,
        setSelectedResponsible,
        showModal,
        modalVisible,
        setModalVisible,
        handleEdit,
        editMode,
        handleCancel,
        handleDelete,
        shouldResetForm,
        setShouldResetForm,
        setEditMode,
        searchValue,
        setSearchValue,
        isSearching, 
        setIsSearching,
        handleClearSearch,
        updateDataTable,
        spinning,
        searchParam, 
        setSearchParam,
        handleKeyPress
    }

}