/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { LocalizationInterface } from "../intefaces/LocalizationInterface";
import { deleteLocalization, getAllLocalizations, getLocalization } from "../service/localizationService";

export const useHookLocalization = (uid?: number, companiesIds?: number[], role?: string) => {
    const [localizations, setLocalizations] = useState<LocalizationInterface[]>([]);
    const [selectedLocalization, setSelectedLocalization] = useState<LocalizationInterface>();
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchParam, setSearchParam] = useState<string>('code');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);

    useEffect(() => {
        const fetchLocalization = async () => {
            try {
                const localizationData = await getAllLocalizations(uid, companiesIds, role);
                setLocalizations(localizationData);
            } catch (error) {
                console.error('Erro ao buscar localizações: ', error);
            }
        };

        fetchLocalization();
    }, []);


    const updateLocalizations = async () => {
        try {
            const localizationData = await getAllLocalizations(uid, companiesIds, role);
            setLocalizations(localizationData);
        } catch (error) {
            console.error('Erro ao buscar localizações: ', error);
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, param: string, value: string) => {
        if (event.key === 'Enter') {
            updateDataTable(param, value);
        } else if (event.key === 'Backspace') {
            handleClearSearch();
        }
    }

    const updateDataTable = async (param: string, value: string) => {
        try {

            if(value === "") {
                message.error("Campo de busca não pode ser vázio!")
                return;
            }

            let findLocalization: React.SetStateAction<LocalizationInterface[]>;

            if(param === 'code') {
                findLocalization = await getLocalization(param, `${value}`, uid);
            } else {
                value = '*' + value;
                findLocalization = await getLocalization(param, `${value}`, uid);
            }

            setSpinning(true);

            setTimeout(() => {
                if (findLocalization) {
                    setLocalizations(findLocalization);
                    //setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                } 

                message.error('Nenhuma localização encontrada!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar localização: ', error);
            message.error('Erro ao buscar localização');
        }
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleEdit = (record: LocalizationInterface) => {
        setSelectedLocalization(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
    };

    const handleDelete = async (record: number) => {
        try {
            await deleteLocalization(record, uid);
            updateLocalizations();
            message.success('Localização deletada com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar localização: ', error);
            message.error('Erro ao deletar localização!');
        }
    };

    const handleClearSearch = () => {
        updateLocalizations();
        setSearchValue('');
        setIsSearching(false);
    };

    return {
        localizations,
        updateLocalizations,
        selectedLocalization,
        setSelectedLocalization,
        showModal,
        modalVisible,
        setModalVisible,
        handleEdit,
        editMode,
        handleCancel,
        handleDelete,
        shouldResetForm,
        setShouldResetForm,
        setEditMode,
        searchValue,
        setSearchValue,
        isSearching, 
        setIsSearching,
        handleClearSearch,
        updateDataTable,
        spinning,
        searchParam, 
        setSearchParam,
        handleKeyPress
    };

}