/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Checkbox, Form, Input } from "antd";
import { Footer } from "antd/es/layout/layout";
import React from "react";
import LogoMain from "../assets/images/Logo-main.png";
import Logo from "../assets/images/logo.png";
import { Content } from "../components/Content";
import { useHookLogin } from "../hooks/useHookLogin";
import { useHookBackground } from "../hooksForm/useHookBackground";

const Login: React.FC = () => {
  const { selectedIndex, handleClick } = useHookBackground();
  const contents = Content({ selectedIndex });
  const { form, onFinish } = useHookLogin();

  return (
    <div className="flex items-center justify-center relative w-full h-full overflow-hidden">
      <div className="flex flex-col items-start justify-center w-3/5 absolute top-11 left-16 z-50">
        <div className="flex">
          <img src={Logo} style={{ width: 58, height: 58 }} className="p-2" />
          <h1 className="text-2xl flex items-center justify-center mt-2.5 font-semibold text-white">
            iPatrimônio
          </h1>
        </div>
      </div>

      <div className="flex items-center justify-center flex-col w-1/4 h-3/5 absolute right-48 bg-white z-50 rounded-md">
        <h2 className="text-blue-500 text-3xl w-1/2 font-bold mb-2 text-center">
          <img
            src={LogoMain}
            style={{ width: 240, height: 58 }}
            className="p-2"
          />
        </h2>
        <span className="text-gray-600 text-center text-sm mb-6">
          Faça o login com sua conta.
        </span>

        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          className="h-3/5 items-center justify-center overflow-hidden"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Insira seu email!" }]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>

          <Form.Item
            label="Senha"
            name="password"
            rules={[{ required: true, message: "Insira sua senha!" }]}
            //normalize={(value: string) => value?.toUpperCase()}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Manter conectado</Checkbox>
            </Form.Item>

            <a
              className="text-blue-500 text-sm"
              href="/forgot/pass"
              target="_blank"
              rel="norefer"
            >
              Esqueceu a senha?
            </a>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-full">
              Entrar
            </Button>
          </Form.Item>

          <div className="text-center">
            Ainda não possui uma conta?{" "}
            <a className="text-blue-500 text-sm" href="#">
              Solicite o cadastro
            </a>
          </div>
        </Form>
      </div>

      <div className="flex w-full h-full absolute overflow-hidden z-10">
        {contents.map((content, index) => (
          <React.Fragment key={index}>{content}</React.Fragment>
        ))}
      </div>

      <div className="flex items-center justify-around w-24 h-14 absolute bottom-20 left-16 z-50">
        <div
          onClick={() => handleClick(0)}
          className="shadow-md cursor-pointer rounded-full w-3 h-3 bg-white opacity-65 hover:scale-150 transition-all ease-out duration-150"
        ></div>
        <div
          onClick={() => handleClick(1)}
          className="shadow-md cursor-pointer rounded-full w-3 h-3 bg-white opacity-65 hover:scale-150 transition-all ease-out duration-150"
        ></div>
        <div
          onClick={() => handleClick(2)}
          className="shadow-md cursor-pointer rounded-full w-3 h-3 bg-white opacity-65 hover:scale-150 transition-all ease-out duration-150"
        ></div>
      </div>

      <Footer className="w-full text-center bg-transparent p-4 text-gray-50 absolute bottom-0 z-50">
        © {new Date().getFullYear()} Desenvolvido por{" "}
        <span className="font-bold text-sm">Ideia Sistemas</span>
      </Footer>
    </div>
  );
};

export default Login;
