/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CityInterface } from "../intefaces/CityInterface";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { ResponsibleInterface } from "../intefaces/ResponsibleInterface";
import { UserInterface } from "../intefaces/UserInterface";
import {
  createResponsible,
  updateResponsible,
} from "../service/responsibleService";
import { CpfOrCnpjMask, PhoneMask } from "../utils/Masks";
import { searchPostalCode } from "../utils/UseCases";

export const useHookFormResponsible = (
  updateTable: () => void,
  editMode: boolean,
  shouldResetForm: boolean,
  setShouldResetForm: (visible: boolean) => void,
  setEditMode: (visible: boolean) => void,
  companies?: CompanyInterface[] | null,
  user?: UserInterface,
  initialValues?: ResponsibleInterface,
  cities?: CityInterface[] | null
) => {
  const [form] = Form.useForm<ResponsibleInterface>();
  const [value, setValue] = useState<string>("");
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [spinningText, setSpinningText] = React.useState<string>("");

  const onFinish = async (values: ResponsibleInterface) => {
    try {
      if (editMode && initialValues) {
        setSpinning(true);
        setSpinningText("Atualizando...");
        if (values.responsible_company && companies) {
          let companyInterface = companies.filter((company) =>
            typeof values.responsible_company === "string"
              ? company.company_name.toLowerCase() ===
                form.getFieldValue("responsible_company").toLowerCase()
              : company.company_id === form.getFieldValue("responsible_company")
          );
          if (companyInterface && companyInterface.length > 0) {
            values.responsible_company = companyInterface[0];
          }
        }

        if (values.responsible_city && cities) {
          let citYInterface = cities.filter((city) =>
            typeof values.responsible_city === "string"
              ? city.city_description.toLowerCase() ===
                form.getFieldValue("responsible_city").toLowerCase()
              : city.city_id === form.getFieldValue("responsible_city")
          );
          if (citYInterface && citYInterface.length > 0) {
            values.responsible_city = citYInterface[0];
          }
        }

        await updateResponsible(
          initialValues.responsible_id,
          values,
          user?.tenant.id
        );
        message.success("Responsavel atualizado com sucesso");
        form.resetFields();
        updateTable();
        setSpinning(false);
        setSpinningText("");
      } else {
        setSpinning(true);
        setSpinningText("Criando...");
        if (values.responsible_company && companies) {
          if (typeof values.responsible_city === "string" && cities) {
            let city = cities.filter(
              (city) =>
                city.city_description.toLowerCase() ===
                values.responsible_city.toString().toLowerCase()
            );
            if (city && city.length > 0) {
              values.responsible_city = city[0];
            }
          }
        }

        if (values.responsible_city && cities) {
          let citYInterface = cities.filter((city) =>
            typeof values.responsible_city === "string"
              ? city.city_description.toLowerCase() ===
                form.getFieldValue("responsible_city").toLowerCase()
              : city.city_id === form.getFieldValue("responsible_city")
          );
          if (citYInterface && citYInterface.length > 0) {
            values.responsible_city = citYInterface[0];
          }
        }

        values.responsible_code = 0;

        await createResponsible(values, user?.tenant.id);
        message.success("Responsavel criado com sucesso!");
        form.resetFields();
        updateTable();
        setSpinning(false);
        setSpinningText("");
      }
    } catch (error) {
      console.error("Erro ao criar responsavel:", error);
      message.error("Erro ao criar responsavel");
      setSpinning(false);
      setSpinningText("");
    }
  };

  useEffect(() => {
    if (editMode && initialValues) {
      const initialValuesWithDate = {
        ...initialValues,
        responsible_birthday: moment(initialValues.responsible_birthday),
      };
      form.setFieldsValue(initialValuesWithDate);
      form.setFieldValue(
        "responsible_city",
        initialValues.responsible_city?.city_description
      );
      form.setFieldValue(
        "responsible_company",
        initialValues.responsible_company?.company_name
      );
    } else {
      form.resetFields();
    }
  }, [editMode, initialValues]);

  useEffect(() => {
    if (shouldResetForm) {
      form.resetFields();
      setShouldResetForm(false);
    }
  }, [shouldResetForm]);

  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const maskedValue = CpfOrCnpjMask(value);
    form.setFieldValue("responsible_cpf", maskedValue);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const maskedValue = PhoneMask(value);
    form.setFieldValue("responsible_phone", maskedValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.toUpperCase();
  };

  const handleSearchPostalCode = async (postalCode: string) => {
    try {
      if (postalCode.trim() === "") {
        form.setFieldValue("responsible_cep", "");
        form.setFieldValue("responsible_city", "");
        form.setFieldValue("responsible_neighborhood", "");
        form.setFieldValue("responsible_address", "");
        return;
      }

      const addressData = await searchPostalCode(postalCode);

      form.setFieldValue("responsible_cep", addressData.cep);
      form.setFieldValue(
        "responsible_city",
        addressData.localidade.toUpperCase()
      );
      form.setFieldValue(
        "responsible_neighborhood",
        addressData.bairro.toUpperCase()
      );
      form.setFieldValue(
        "responsible_address",
        addressData.logradouro.toUpperCase()
      );
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    onFinish,
    handleCpfChange,
    handlePhoneChange,
    handleSearchPostalCode,
    handleInputChange,
    spinning,
    spinningText,
  };
};
