/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DownOutlined,
  SearchOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Layout,
  List,
  Modal,
  Select,
  Spin,
} from "antd";
import { Footer } from "antd/es/layout/layout";
import React from "react";
import FormAsset from "../components/FormAsset";
import { MenuReport, MenuReportButtonProps } from "../components/MenuReport";
import Tabled from "../components/Table";
import { useAuth } from "../contexts/AuthContext";
import { useTheme } from "../contexts/ThemeContext";
import { useHookAssets } from "../hooks/useHookAssets";
import { useHookContracts } from "../hooks/useHookContracts";
import { useHookLocalization } from "../hooks/useHookLocalization";
import { useHookPersons } from "../hooks/useHookPersons";
import { useHooksCompanies } from "../hooks/useHooksCompanies";
import { useHookSituation } from "../hooks/useHookSituation";
import { useHookTypes } from "../hooks/useHookTypes";
import { columnsAsset } from "../utils/ColumnsUtils";

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const Asset: React.FC = () => {
  const { user } = useAuth();

  const {
    assets,
    updateAssets,
    showModal,
    modalVisible,
    handleEdit,
    editMode,
    handleCancel,
    handleDelete,
    selectedAsset,
    shouldResetForm,
    setShouldResetForm,
    setEditMode,
    searchValue,
    setSearchValue,
    isSearching,
    handleClearSearch,
    updateDataTable,
    spinning,
    searchParam,
    setSearchParam,
    handleKeyPress,
  } = useHookAssets(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );
  const { types } = useHookTypes(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );
  const { persons } = useHookPersons(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );
  const { contracts } = useHookContracts(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );
  const { companies } = useHooksCompanies(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );
  const { situations } = useHookSituation(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );
  const { localizations } = useHookLocalization(
    user?.tenant.id,
    user?.userCompanies.map((companyId) => companyId.company!.company_id),
    user?.role
  );
  const { palette, theme } = useTheme();
  const props: MenuReportButtonProps = {
    assets: assets,
    username: user?.username,
  };
  const {
    menuAsset,
    open,
    setOpen,
    handlePrintDepreciation,
    generatePDF,
    handleDateChange,
    startDate,
    endDate,
    handlePrintAcumulateDepreciation,
    handlePrintAssetReport,
    setSelectedCompany,
    setSelectedType,
    selectedCompany,
    selectedType
  } = MenuReport(props);

  const tableColumns = columnsAsset(handleEdit, handleDelete);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      <Spin spinning={spinning} tip="Buscando..." fullscreen />
      <Spin spinning={generatePDF} tip="Gerando PDF..." fullscreen />
      <Divider orientation="left" style={{ borderColor: palette.fontColor }}>
        <h1 className="text-3xl mb-1" style={{ color: palette.fontColor }}>
          Patrimônios
        </h1>
      </Divider>
      <div className="flex justify-end w-full h-20">
        {assets.length > 0 && (
          <Dropdown overlay={menuAsset} trigger={["click"]}>
            <Button type="primary" className="mb-4 mr-2 bg-green-600">
              Relatórios PDF <DownOutlined />
            </Button>
          </Dropdown>
        )}
        <Button type="primary" className="mb-4" onClick={showModal}>
          Adicionar Ativo
        </Button>
        <Modal
          open={open === 1}
          width={800}
          centered
          onCancel={() => setOpen(0)}
          footer={false}
          title={"Adicionar Filtros:"}
          maskClosable={false}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            className="mt-6"
          >
            <Select
              style={{ textTransform: "uppercase" }}
              allowClear
              placeholder="Seleciona a empresa"
              onChange={(value) => setSelectedCompany(companies.find(company => company.company_id === value))}
            >
              {companies &&
                companies.map((company) => (
                  <Option
                    key={company.company_id}
                    value={company.company_id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {company.company_name}
                  </Option>
                ))}
            </Select>
            <Select
              style={{ textTransform: "uppercase" }}
              allowClear
              placeholder="Seleciona o grupo"
              onChange={(value) => setSelectedType(types.find(type => type.type_id === value))}
            >
              {types &&
                types.map((type) => (
                  <Option
                    key={type.type_id}
                    value={type.type_id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {type.type_description}
                  </Option>
                ))}
            </Select>
            <Footer className="flex items-center justify-end h-10 p-0">
              <Button
                type="primary"
                icon={<SelectOutlined />}
                onClick={() => handlePrintAssetReport(selectedCompany, selectedType)}
              >
                Imprimir
              </Button>
            </Footer>
          </div>
        </Modal>
        <Modal
          open={open === 2}
          width={800}
          centered
          onCancel={() => setOpen(0)}
          footer={false}
          title={"Selecione o Bem"}
          maskClosable={false}
        >
          <List
            dataSource={assets}
            renderItem={(asset) => (
              <List.Item
                actions={[
                  <Button
                    icon={<SelectOutlined />}
                    onClick={() => handlePrintDepreciation(asset)}
                  >
                    Selecionar
                  </Button>,
                ]}
              >
                [{asset.asset_code}]{" - "}
                {asset.asset_description}
              </List.Item>
            )}
          />
        </Modal>
        <Modal
          open={open === 3}
          width={800}
          centered
          onCancel={() => setOpen(0)}
          footer={false}
          title={"Informe o Periódo.: "}
          maskClosable={false}
        >
          <div className="flex items-center justify-center w-full h-6">
            <RangePicker
              placeholder={["Data Inicial", "Data Final"]}
              format={dateFormat}
              onChange={handleDateChange}
            />
            <Button
              icon={<SelectOutlined />}
              onClick={() =>
                handlePrintAcumulateDepreciation(assets, startDate, endDate)
              }
              className="ml-5"
            >
              Imprimir
            </Button>
          </div>
        </Modal>
      </div>
      <div className="flex flex-row justify-start mb-5">
        <Select
          defaultValue="asset_code"
          className="mr-2 w-1/5 bg-slate-100 font-bold"
          style={{ background: palette.secondary }}
          onChange={(value) => setSearchParam(value)}
        >
          <Option value="asset_code">ID</Option>
          <Option value="asset_description">Descrição</Option>
          <Option value="asset_serial">Serial</Option>
          <Option value="asset_identification">Identificação</Option>
          <Option value="asset_invoice_number">Nº Nota Fiscal</Option>
          <Option value="asset_company">Empresa</Option>
        </Select>
        <Input
          placeholder="Pesquisar"
          className={
            theme === "light"
              ? `w-full mr-2 font-bold placeholder:text-gray-500`
              : "mr-2 font-bold placeholder:text-gray-300 border-transparent"
          }
          value={searchValue}
          style={{
            background: palette.secondary,
            color: palette.fontColor,
            textTransform: "uppercase",
          }}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={(e) =>
            handleKeyPress(e, searchParam, searchValue.toUpperCase())
          }
        />
        <Button
          type="primary"
          className=" mr-2"
          icon={<SearchOutlined />}
          onClick={() =>
            updateDataTable(searchParam, searchValue.toUpperCase())
          }
        >
          Pesquisar
        </Button>
        {isSearching ? (
          <Button
            type="primary"
            className="mr-2"
            danger
            onClick={() => {
              handleClearSearch();
            }}
          >
            Limpar
          </Button>
        ) : null}
      </div>
      <Modal
        open={modalVisible}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        width={1400}
        height={800}
        maskClosable={false}
        style={{
          height: "90%",
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
        }}
        className="overflow-y-auto"
      >
        <Divider orientation="left">
          <h1 className="text-2xl">
            {editMode ? "Editar Ativo" : "Cadastrar Ativo"}
          </h1>
        </Divider>
        <div className="p-4 h-auto overflow-y-auto">
          <FormAsset
            updateTable={updateAssets}
            editMode={editMode}
            initialValues={selectedAsset}
            modalVisible={modalVisible}
            shouldResetForm={shouldResetForm}
            setShouldResetForm={setShouldResetForm}
            setEditMode={setEditMode}
            suppliers={persons}
            contracts={contracts}
            companies={companies}
            types={types}
            situations={situations}
            user={user || undefined}
            localizations={localizations}
          />
        </div>
      </Modal>
      <Layout style={{ background: palette.secondary }}>
        <Content className="overflow-auto">
          <Tabled columns={tableColumns} data={assets} />
        </Content>
      </Layout>
    </div>
  );
};

export default Asset;
