/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { ContractInterface } from "../intefaces/ContractInterface";
import { deleteContract, getAllContracts, getContract } from "../service/contractService";

export const useHookContracts = (uid?: number, companyId?: number[], role?: string) => {
    const [contracts, setContracts] = useState<ContractInterface[]>([]);
    const [selectedContract, setSelectedContract] = useState<ContractInterface>();
    const [searchValue, setSearchValue] = useState('');
    const [searchParam, setSearchParam] = useState<string>('contract_code');
    const [isSearching, setIsSearching] = useState(false);
    const [spinning, setSpinning] = React.useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(false);

    useEffect(() => {
        const fetchContracts = async () => {
            try {
                const contractData = await getAllContracts(uid, companyId, role);
                setContracts(contractData);
            } catch (error) {
                console.error('Erro ao buscar contratos:', error);
            }
        };

        fetchContracts();

    }, []);


    const updateContracts = async () => {
        try {
            const contractData = await getAllContracts(uid, companyId, role);
            setContracts(contractData);
        } catch (error) {
            console.error('Erro ao buscar contratos:', error);
        }
    };

    const handleDelete = async (record: number) => {
        try {
            await deleteContract(record, uid);
            updateContracts();
            message.success('Contrato excluido com sucesso!');
        } catch (error) {
            console.error('Erro ao deletar contrato: ', error);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, param: string, value: string) => {
        if (event.key === 'Enter') {
            updateDataTable(param, value);
        } else if (event.key === 'Backspace') {
            handleClearSearch();
        }
    }

    const updateDataTable = async (param: string, value: string) => {
        try {

            if (value === "") {
                message.error("Campo de busca não pode ser vázio!")
                return;
            }

            let findContract: React.SetStateAction<ContractInterface[]>;

            if (param === 'contract_code') {
                findContract = await getContract(param, value, uid);
            } else if(param === 'contract_time_type') {
                value = value.substring(0, 2);
                value = value.toUpperCase();
                value = "*" + value;
                findContract = await getContract(param, value, uid);
            } else {
                value = "*" + value;
                findContract = await getContract(param, value, uid);
            }

            setSpinning(true);

            setTimeout(() => {
                if (findContract) {
                    setContracts(findContract);
                    //setSearchValue('');
                    setIsSearching(true);
                    setSpinning(false);
                    return;
                }

                message.error('Nenhum contrato encontrado!');
            }, 500);

        } catch (error) {
            console.error('Erro ao buscar contrato: ', error);
            message.error('Erro ao buscar contrato');
        }
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const handleEdit = (record: ContractInterface) => {
        setSelectedContract(record);
        setEditMode(true);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditMode(false)
        setSearchValue('');
    };

    const handleClearSearch = () => {
        updateContracts();
        setSearchValue('');
        setIsSearching(false);
    };

    return {
        contracts,
        updateContracts,
        handleDelete,
        updateDataTable,
        searchValue,
        isSearching,
        setSearchValue,
        setIsSearching,
        spinning,
        showModal,
        modalVisible,
        handleEdit,
        editMode,
        selectedContract,
        handleCancel,
        handleClearSearch,
        shouldResetForm,
        setEditMode,
        setShouldResetForm,
        searchParam,
        setSearchParam,
        handleKeyPress
    };
}