/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, message, UploadFile } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { AxiosError } from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Asset } from "../intefaces/Asset";
import { CompanyInterface } from "../intefaces/CompanyInterface";
import { LocalizationInterface } from "../intefaces/LocalizationInterface";
import { PersonInterface } from "../intefaces/PersonInterface";
import { SituationInterface } from "../intefaces/SituationInterface";
import { TypeInterface } from "../intefaces/TypeInterface";
import { UserInterface } from "../intefaces/UserInterface";
import { createAsset, updateAsset } from "../service/assetService";
import { resizeImage } from "../utils/UseCases";

export const useHookFormAsset = (
  updateTable: () => void,
  editMode: boolean,
  shouldResetForm: boolean,
  setShouldResetForm: (visible: boolean) => void,
  suppliers?: PersonInterface[],
  companies?: CompanyInterface[],
  types?: TypeInterface[],
  situations?: SituationInterface[],
  initialValues?: Asset,
  user?: UserInterface,
  localizations?: LocalizationInterface[] | null
) => {
  const [form] = Form.useForm<Asset>();
  const [logo, setLogo] = useState<UploadFile | any>(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedColor, setSelectedColor] = useState("#1677ff");
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentForm, setCurrentForm] = useState<React.ReactNode | null>(null);
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [spinningText, setSpinningText] = React.useState<string>("");

  const onFinish = async (values: Asset) => {
    try {
      if (editMode && initialValues) {
        setSpinning(true);
        setSpinningText("Atualizando...");
        if (values.asset_status && situations) {
          let situationInterface = situations.filter((situation) =>
            typeof values.asset_status === "string"
              ? situation.situation_description ===
                form.getFieldValue("asset_status")
              : situation.situation_id === form.getFieldValue("asset_status")
          );
          if (situationInterface && situationInterface.length > 0) {
            values.asset_status = situationInterface[0];
          }
        }

        if (values.asset_supplier && suppliers) {
          let suppliersInterface = suppliers.filter((supplier) =>
            typeof values.asset_supplier === "string"
              ? supplier.person_name === form.getFieldValue("asset_supplier")
              : supplier.person_id === form.getFieldValue("asset_supplier")
          );
          if (suppliersInterface && suppliersInterface.length > 0) {
            values.asset_supplier = suppliersInterface[0];
          }
        }

        if (values.asset_type && types) {
          let typeinterface = types.filter((type) =>
            typeof values.asset_type === "string"
              ? type.type_description === form.getFieldValue("asset_type")
              : type.type_id === form.getFieldValue("asset_type")
          );
          if (typeinterface && typeinterface.length > 0) {
            values.asset_type = typeinterface[0];
          }
        }

        if (values.asset_company && companies) {
          let companyinterface = companies.filter((company) =>
            typeof values.asset_company === "string"
              ? company.company_name === form.getFieldValue("asset_company")
              : company.company_id === form.getFieldValue("asset_company")
          );
          if (companyinterface && companyinterface.length > 0) {
            values.asset_company = companyinterface[0];
          }
        }

        if (values.asset_localization && localizations) {
          let localizationInterface = localizations.filter((localization) =>
            typeof values.asset_localization === "string"
              ? localization.name === form.getFieldValue("asset_localization")
              : localization.id === form.getFieldValue("asset_localization")
          );
          if (localizationInterface && localizationInterface.length > 0) {
            values.asset_localization = localizationInterface[0];
          }
        }

        if (selectedColor) {
          values.asset_color = selectedColor;
        }

        if (logo) {
          values.asset_image = logo;
        } else {
          values.asset_image = "";
        }

        await updateAsset(initialValues.asset_id, values, user?.tenant.id);
        form.resetFields();
        message.success("Ativo atualizado com sucesso");
        updateTable();
        setLogo(null);
        setSpinning(false);
        setSpinningText("");
      } else {
        setSpinning(true);
        setSpinningText("Criando...");
        if (values.asset_status && situations) {
          let situationInterface = situations.filter(
            (situation) =>
              situation.situation_id === form.getFieldValue("asset_status")
          );
          if (situationInterface && situationInterface.length > 0) {
            values.asset_status = situationInterface[0];
          }
        }

        if (values.asset_supplier && suppliers) {
          let suppliersInterface = suppliers.filter(
            (supplier) =>
              supplier.person_id === form.getFieldValue("asset_supplier")
          );
          if (suppliersInterface && suppliersInterface.length > 0) {
            values.asset_supplier = suppliersInterface[0];
          }
        }

        if (values.asset_type && types) {
          let typeinterface = types.filter(
            (type) => type.type_id === form.getFieldValue("asset_type")
          );
          if (typeinterface && typeinterface.length > 0) {
            values.asset_type = typeinterface[0];
          }
        }

        if (values.asset_company && companies) {
          let companyinterface = companies.filter(
            (company) =>
              company.company_id === form.getFieldValue("asset_company")
          );
          if (companyinterface && companyinterface.length > 0) {
            values.asset_company = companyinterface[0];
          }
        }

        if (values.asset_localization && localizations) {
          let localizationInterface = localizations.filter(
            (localization) =>
              localization.id === form.getFieldValue("asset_localization")
          );
          if (localizationInterface && localizationInterface.length > 0) {
            values.asset_localization = localizationInterface[0];
          }
        }

        if (selectedColor) {
          values.asset_color = selectedColor;
        }

        values.asset_code = 0;

        if (logo) {
          values.asset_image = logo;
        } else {
          values.asset_image = "";
        }

        await createAsset(values, user?.tenant.id);
        form.resetFields();
        message.success("Ativo criado com sucesso");
        updateTable();
        setLogo(null);
        setSpinning(false);
        setSpinningText("");
      }
    } catch (error) {
      const errorMessage = error as AxiosError;
      console.error(
        "Erro ao criar ativo:",
        (errorMessage.response?.data as { message: string }).message
      );
      message.error(
        (errorMessage.response?.data as { message: string }).message
      );
      setSpinning(false);
      setSpinningText("");
    }
  };

  const handleColorChange = (_: any, hex: any) => {
    setSelectedColor(hex);
  };

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info && info.fileList[0]) {
      const file = info.fileList[0].originFileObj;
      resizeImage(file as File, 100, 100, (resizedDataUrl) => {
        setLogo(resizedDataUrl);
      });
    } else {
      setLogo(null);
    }
  };

  const handleRemove = () => {
    setLogo(null);
  };

  const convertDateFields = (data: any) => {
    const newData = { ...data };
    const dateFields = [
      "asset_purchase_date",
      "asset_guarantee_date",
      "asset_invoice_date",
      "asset_downgrade_date",
      "asset_lasted_revision_date",
      "asset_next_revision_date",
      "asset_insurance_validity",
    ];

    dateFields.forEach((field) => {
      if (newData[field]) {
        newData[field] = moment(newData[field]);
      }
    });

    return newData;
  };

  const showDrawer = (formComponent: React.ReactNode) => {
    setCurrentForm(formComponent);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const calculateRate = (value: string) => {
    const lifeCycle = parseInt(value || "0");
    const purchaseValue = parseFloat(
      form.getFieldValue("asset_purchase_value") || "0"
    );
    const rate: number = (1 / lifeCycle) * 100;

    form.setFieldValue("asset_depreciation_rate", rate.toFixed(2));
  };

  useEffect(() => {
    if (editMode && initialValues) {
      const initialValuesWithDate = convertDateFields(initialValues);
      form.setFieldsValue(initialValuesWithDate);

      form.setFieldValue(
        "asset_status",
        initialValues.asset_status?.situation_description
      );
      form.setFieldValue(
        "asset_type",
        initialValues.asset_type?.type_description
      );
      form.setFieldValue(
        "asset_company",
        initialValues.asset_company?.company_name
      );
      form.setFieldValue(
        "asset_supplier",
        initialValues.asset_supplier?.person_name
      );
      form.setFieldValue(
        "asset_localization",
        initialValues.asset_localization?.name
      );
      setLogo(initialValues?.asset_image);
    } else {
      form.resetFields();
    }
  }, [editMode, initialValues]);

  useEffect(() => {
    if (shouldResetForm) {
      form.resetFields();
      setShouldResetForm(false);
      setSearchValue("");
    }
  }, [shouldResetForm]);

  return {
    form,
    onFinish,
    handleColorChange,
    handleChange,
    searchValue,
    setSearchValue,
    selectedColor,
    setSelectedColor,
    modalVisible,
    setModalVisible,
    showDrawer,
    visible,
    onClose,
    currentForm,
    logo,
    handleRemove,
    calculateRate,
    spinning,
    spinningText,
  };
};
