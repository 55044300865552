/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, Select, Spin } from "antd";
import React from "react";
import { useHookFormUser } from "../hooksForm/useHookFormUser";
import { UserInterface } from "../intefaces/UserInterface";

const { Option } = Select;

interface FormUserProps {
  updateTable: () => void;
  editMode: boolean;
  modalVisible: boolean;
  shouldResetForm: boolean;
  setShouldResetForm: (visible: boolean) => void;
  initialValues?: UserInterface;
  user?: UserInterface;
}

const FormUser: React.FC<FormUserProps> = ({
  updateTable,
  editMode,
  initialValues,
  shouldResetForm,
  setShouldResetForm,
  user,
}) => {
  const { form, onFinish, spinning, spinningText } = useHookFormUser(
    updateTable,
    editMode,
    shouldResetForm,
    setShouldResetForm,
    initialValues,
    user
  );

  return (
    <div className="flex flex-row justify-between w-full h-1/2 mb-5 overflow-hidden">
      <Spin spinning={spinning} tip={spinningText} fullscreen />
      <div className="justify-start w-80">
        <h1 className="text-2xl flex flex-col">
          Detalhes do Usuário
          <span className="text-xs text-gray-400">
            Preencha todos os campos
          </span>
        </h1>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-4/5 border-l border-gray-300 h-full flex-col p-4"
        layout="vertical"
        autoComplete="off"
      >
        <div className="flex flex-row w-full">
          <Form.Item name="id" label="ID" className="w-1/1 mr-3">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="username"
            label="Nome"
            className="w-1/2 mr-3"
            rules={[{ required: true, message: "Insira um nome!" }]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: 'uppercase' }}/>
          </Form.Item>
          <Form.Item
            name="role"
            label="Regra"
            className="w-1/4"
            rules={[{ required: true, message: "Selecione a regra" }]}
          >
            <Select style={{ textTransform: 'uppercase' }}>
              <Option value="user" style={{ textTransform: 'uppercase' }}>Usuário</Option>
              <Option value="admin" style={{ textTransform: 'uppercase' }}>Administrador</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row w-full">
          <Form.Item
            name="email"
            label="Email"
            className="w-1/2 mr-3"
            rules={[
              { required: true, message: "Insira um email valido!" },
            ]}
            normalize={(value: string) => value?.toUpperCase()}
          >
            <Input style={{ textTransform: 'uppercase' }} />
          </Form.Item>
          {/*{editMode && 
                        <Form.Item name="password" label="Mudar senha" className="w-1/2 mr-3">
                            <Input />
                        </Form.Item>
                    }*/}
        </div>
        <div className="flex flex-row justify-end">
          <Button
            type="default"
            className="w-1/5"
            htmlType="submit"
            style={{ backgroundColor: "green", color: "white" }}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormUser;
